import React from 'react';
import CustomCreatedDate from '../components/CustomCreatedDate';
import CustomDescription from '../components/CustomDescription';
import CustomExpiredDate from '../components/CustomExpiredDate';
import CustomNote from '../components/CustomNote';
import Customtitle from '../components/CustomPlanTitle';
import CustomPrice from '../components/CustomPrice';
import CustomResource from '../components/CustomResource';
import CustomUser from '../components/CustomUser';

function CustomBasicSettingInfo({ detailAr, setDetailAr }) {
    return (
        <div className="tab-detail">
            <div className="content-body-scroll user-profile-area">
                <div className="product-info mb-6 user-profile-info-area">
                    <div>
                        <div className="line mb-5 user-personal-info-area">
                            <div className="cell cell-12 cell-md-12 cell-sm-12">
                                <CustomUser
                                    detailAr={detailAr}
                                    setDetailAr={setDetailAr}
                                    key="domain"
                                />
                                <Customtitle
                                    detailAr={detailAr}
                                    setDetailAr={setDetailAr}
                                    key="title"
                                />
                                <CustomDescription
                                    detailAr={detailAr}
                                    setDetailAr={setDetailAr}
                                    key="price"
                                />
                                <CustomResource
                                    detailAr={detailAr}
                                    setDetailAr={setDetailAr}
                                    key="resource"
                                />
                                <CustomPrice
                                    detailAr={detailAr}
                                    setDetailAr={setDetailAr}
                                    key="price"
                                />

                                <CustomCreatedDate
                                    detailAr={detailAr}
                                    setDetailAr={setDetailAr}
                                    key="date"
                                />
                                <CustomExpiredDate
                                    detailAr={detailAr}
                                    setDetailAr={setDetailAr}
                                    key="date"
                                />
                                <CustomNote
                                    detailAr={detailAr}
                                    setDetailAr={setDetailAr}
                                    key="note"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CustomBasicSettingInfo;
