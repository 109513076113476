/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import EditIcon from '../../../../../assets/images/editicon.svg';
import EditCorrect from '../../../../../assets/images/IconCorrect.svg';
import IconCross from '../../../../../assets/images/IconCross.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';
import DropdownMenu from '../../../../utils/DropdownMenu';
import ModalSettingDomainPrice from '../modals/ModalSettingDomainPrice';

function ManagesSettingDomainPricePage() {
    const [domainPriceAr, setDomainPriceAr] = useState([]);
    const [selectId, setSelectId] = useState();
    const [showNew, setShowNew] = useState(false);
    const [selectVendor, setSelectVendor] = useState('');
    const [tldList, setTldList] = useState([]);
    const [selectTld, setSelectTld] = useState('');
    const [selectTldAr, setSelectTldAr] = useState([]);
    const [isServicePrice, setIsServicePrice] = useState('');

    const handleUpdate = (e) => {
        setShowNew(true);
        setSelectId(e);
    };
    const handleClose = () => {
        setShowNew(false);
    };
    const handleTld = (e) => {
        setSelectTld(e);
    };
    const hanldeTldRemove = (e) => {
        setSelectTldAr((current) => current.filter((tld) => tld !== e));
        setSelectTld('');
        AxiosAuth.get(
            `${ConfigApi.DOMAIN_PRICE_LIST}?vendor=${selectVendor}&tld=${selectTldAr
                .filter((tld) => tld !== e)
                .join(',')}&isService=${isServicePrice}`
        ).then((response) => {
            setDomainPriceAr(response.data.dataAr);
        });
    };
    const handleCorrectPrice = (e) => {
        AxiosAuth.post(ConfigApi.DOMAIN_PRICE_TLD_UPDATE, {
            tldClassKey: e,
            selectVendor,
            tlds: selectTldAr,
            isService: isServicePrice,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setDomainPriceAr(response.data.dataAr);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSearch = () => {
        if (selectTld !== '' && !selectTldAr?.filter((exTld) => exTld === selectTld)[0]) {
            setSelectTldAr([selectTld, ...selectTldAr]);
        }

        AxiosAuth.get(
            `${ConfigApi.DOMAIN_PRICE_LIST}?vendor=${selectVendor}&tld=${[
                selectTld,
                ...selectTldAr,
            ].join(',')}&isService=${isServicePrice}`
        ).then((response) => {
            setDomainPriceAr(response.data.dataAr);
        });
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.DOMAIN_PRICE_LIST}`).then((response) => {
            setDomainPriceAr(response.data.dataAr);
            setTldList(response.data.tldList);
        });
    }, [showNew]);

    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <DropdownMenu
                                selectedOption={selectVendor}
                                setSelectedOption={setSelectVendor}
                                options={['btcl', 'resellbiz']}
                                width="100%"
                                defaultOption="Select Vendor"
                            />
                        </div>
                        <div className="sidebar-panel-search">
                            <DropdownMenu
                                selectedOption={selectTld}
                                setSelectedOption={handleTld}
                                options={Object.values(tldList)}
                                width="100%"
                                defaultOption="Select Domain Price"
                            />
                        </div>
                        <div className="sidebar-panel-search" style={{ width: '15%' }}>
                            <DropdownMenu
                                selectedOption={isServicePrice}
                                setSelectedOption={setIsServicePrice}
                                options={['yes', 'no']}
                                width="100%"
                                defaultOption="Select Server Price"
                                isInput={false}
                            />
                        </div>
                        <button type="button" onClick={handleSearch}>
                            Search
                        </button>
                    </form>
                </div>
                <div className="main-panel-body">
                    {selectTldAr?.length > 0 ? (
                        <div className="main-panel-search-tag">
                            {selectTldAr?.map((tld) => (
                                <span key={tld}>
                                    {tld}
                                    <button type="button" onClick={() => hanldeTldRemove(tld)}>
                                        <img src={IconCross} alt="" />
                                    </button>
                                </span>
                            ))}
                        </div>
                    ) : null}

                    <table className="main-panel-body-table mb-3">
                        <thead>
                            <tr className="text-center">
                                <th rowSpan={2}>SL</th>
                                <th rowSpan={2}>Vendor</th>
                                <th rowSpan={2}>Domain Ext</th>
                                <th rowSpan={2}>Promotion</th>
                                <th className="text-center" colSpan={4}>
                                    Price
                                </th>
                                <th rowSpan={2}>Staus</th>
                                <th rowSpan={2} className="text-center">
                                    Action
                                </th>
                            </tr>
                            <tr className="text-center">
                                <th style={{ borderRadius: 'unset' }}>Registration</th>
                                <th>Renew</th>
                                <th>Transfer</th>
                                <th style={{ borderRadius: 'unset' }}>Restore</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(domainPriceAr)?.map((row) => (
                                <tr key={row?.sl} className="text-center">
                                    <td>{row?.sl}</td>
                                    <td>{row?.vendor}</td>
                                    <td>{row?.domain_ext}</td>
                                    <td>{row?.price_promotion}</td>
                                    <td>
                                        {row?.price_registration}
                                        {parseFloat(row?.price_registration, 10) ===
                                        parseFloat(row?.price_registration_server, 10) ? null : (
                                            <>
                                                {' '}
                                                /
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: 10,
                                                        paddingLeft: 5,
                                                    }}
                                                >
                                                    {row?.price_registration_server}
                                                </span>
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {row?.price_renew}
                                        {parseFloat(row?.price_renew, 10) ===
                                        parseFloat(row?.price_renew_server, 10) ? null : (
                                            <>
                                                {' '}
                                                /
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: 10,
                                                        paddingLeft: 5,
                                                    }}
                                                >
                                                    {row?.price_renew_server}
                                                </span>
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {row?.price_transfer}
                                        {parseFloat(row?.price_transfer, 10) ===
                                        parseFloat(row?.price_transfer_server, 10) ? null : (
                                            <>
                                                {' '}
                                                /
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: 10,
                                                        paddingLeft: 5,
                                                    }}
                                                >
                                                    {row?.price_transfer_server}
                                                </span>
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        {row?.price_restore}
                                        {parseFloat(row?.price_restore, 10) ===
                                        parseFloat(row?.price_restore_server, 10) ? null : (
                                            <>
                                                {' '}
                                                /
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: 10,
                                                        paddingLeft: 5,
                                                    }}
                                                >
                                                    {row?.price_restore_server}
                                                </span>
                                            </>
                                        )}
                                    </td>
                                    <td>{row?.status}</td>
                                    <td className="action-area">
                                        {row?.isServerPrice > 0 ? (
                                            <button
                                                type="button"
                                                onClick={() => handleCorrectPrice(row?.class_key)}
                                            >
                                                <img src={EditCorrect} alt="Correct" />
                                            </button>
                                        ) : null}
                                        <button type="button" onClick={() => handleUpdate(row?.sl)}>
                                            <img src={EditIcon} alt="Edit" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {selectId ? (
                    <ModalSettingDomainPrice
                        show={showNew}
                        selectId={selectId}
                        setSelectId={setSelectId}
                        domainPriceAr={domainPriceAr?.filter((r) => r.sl === selectId)[0]}
                        handleClose={handleClose}
                    />
                ) : null}
            </div>
        </>
    );
}

export default ManagesSettingDomainPricePage;
