import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

function PrintHandler() {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('Secure-Access')) {
            navigate('/');
        }
    });
    return (
        <div className="main">
            <Outlet />
        </div>
    );
}

export default PrintHandler;
