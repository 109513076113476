import React from 'react';
import '../../../../../assets/css/print-style.css';
import Logo from '../../../../../assets/images/Logo.svg';

function ManagesInvoicePagesPrint() {
    return (
        <div className="main">
            <div className="borderimg3">
                <div className="header">
                    <div>
                        <div className="header-title flex-container">
                            <div className="flex-items">
                                <img style={{ width: '230px' }} src={Logo} alt="" />
                            </div>
                            <div className="flex-items" />
                            <div
                                className="flex-items"
                                style={{ textAlign: 'right', fontSize: '16px', lineHeight: '23px' }}
                            >
                                <h3>BILL</h3>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="customer-info">
                        <div className="biller-info">
                            <h6>To,</h6>
                            <p style={{ margin: '-8px 0' }}>Company</p>
                            <p style={{ margin: '10px 0 -8px 2px' }}>Clinet Name</p>
                            <p>ddddd</p>
                            <p style={{ margin: '-8px 0' }}>client Email</p>
                        </div>
                        <div className="billing-info">
                            <p style={{ margin: '-8px 0' }}>Date:</p>
                            <p style={{ margin: '10px 0 -8px 2px' }}>Bill ID#:</p>
                        </div>
                    </div>
                </div>
                <div className="stricture">
                    <table width="100%">
                        <thead style={{ fontSize: '10px', fontWeight: 'bold' }}>
                            <tr>
                                <td>SL</td>

                                <td>Gross Rate</td>
                                <td>Quantity</td>
                                <td style={{ textAlign: 'right' }}>Total Amount</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="6" />
                                <td colSpan="2" style={{ textAlign: 'center' }}>
                                    <b>Total</b>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <b>1000</b>
                                </td>
                            </tr>

                            <tr>
                                <td>dd</td>
                                <td colSpan="2" style={{ textAlign: 'center' }}>
                                    <b>Grand Total</b>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <b>ddd</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ marginBottom: '260px' }}>
                        <h5>In Word:...... Taka Only</h5>
                    </div>

                    <div className="footer-info">
                        <div className="footer-signature">
                            <img
                                src="/assets/images/signature-bevash-barai.png"
                                alt="Signature"
                                style={{ width: '129px', position: 'relative', bottom: '-24px' }}
                            />
                            <h4 style={{ marginBottom: '-12px' }}>Signature</h4>
                            <p>
                                Editor & Publisher
                                <br />
                                bahannonews.com
                            </p>
                        </div>
                        <div
                            className="footer-signature"
                            style={{ textAlign: 'center', marginTop: '60px' }}
                        />
                        <div className="footer-signature" style={{ textAlign: 'right' }}>
                            <h4 style={{ marginTop: '80px' }}>Receiver</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManagesInvoicePagesPrint;
