import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ModalSettingPackage from '../modals/ModalSettingPackage';

function ManagesSettingPackages() {
    const [packageAr, setPackageAr] = useState([]);
    const [serviceAr, setServiceAr] = useState([]);
    const [showNew, setShowNew] = useState(false);
    const [searchText, setSearchText] = useState('');
    const handleClose = () => {
        setShowNew(false);
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.PACKAGE_LIST}`).then((response) => {
            setPackageAr(response?.data?.dataAr?.packagesInfo_ar);
            setServiceAr(response?.data?.dataAr?.servicesInfo_ar);
        });
    }, []);

    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search..."
                                aria-label=""
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>

                        <button type="submit">Search</button>
                    </form>
                    <button type="button" onClick={() => setShowNew(true)}>
                        Add New Package
                    </button>
                </div>
                <div className="main-panel-body">
                    <table className="main-panel-body-table mb-3">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Title</th>
                                <th>Price (USD)</th>
                                <th>Type</th>
                                <th>Duration</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packageAr?.map((row) => (
                                <tr key={row?.sl}>
                                    <td>{row?.sl}</td>
                                    <td>{row?.title}</td>
                                    <td>{row?.price}</td>
                                    <td>{row?.type}</td>
                                    <td>{row?.selected_duration} Months</td>
                                    <td>{row?.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {showNew ? (
                <ModalSettingPackage
                    show={showNew}
                    handleClose={handleClose}
                    serviceAr={serviceAr}
                />
            ) : null}
        </>
    );
}

export default ManagesSettingPackages;
