import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ModalDomainAssignUser from '../modals/ModalDomainAssignUser';
import ModalServerContract from '../modals/ModalServerContract';

function ManagesServerPage() {
    const [serverAr, setServerAr] = useState([]);
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');

    const handleClose = () => {
        setShow(false);
        setShowNew(false);
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CONTRACT_SERVER_LIST}`).then((response) => {
            setServerAr(response.data.dataAr);
        });
    }, [showNew]);

    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search Server"
                                aria-label=""
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>

                        <button type="submit">Search</button>
                    </form>
                    <button type="button" onClick={() => setShowNew(true)}>
                        Add New Contact
                    </button>
                </div>
                <div className="main-panel-body">
                    <div className="scroll-table-container">
                        <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>User</th>
                                    <th>Server</th>
                                    <th>Date Created</th>
                                    <th>Expired</th>
                                    <th>Domain</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {serverAr?.map((row) => (
                                    <tr key={row?.sl}>
                                        <td>H{row?.sl}</td>
                                        <td>
                                            {row?.user_sl > 0 ? (
                                                row?.user_name
                                            ) : (
                                                <button
                                                    type="button"
                                                    style={{ background: 'unset', border: 'unset' }}
                                                >
                                                    Not Set
                                                </button>
                                            )}
                                        </td>
                                        <td>{row?.server}</td>
                                        <td>{row?.time_issue}</td>
                                        <td>{row?.time_expire}</td>
                                        <td>{row?.domain_name}</td>
                                        <td>${row?.price?.toFixed(2)}</td>
                                        <td>{row?.status}</td>
                                        <td />
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <ModalDomainAssignUser
                    show={show}
                    handleClose={handleClose}
                    selectId={selectId}
                    setSelectId={setSelectId}
                />
                <ModalServerContract show={showNew} handleClose={handleClose} />
            </div>
        </>
    );
}

export default ManagesServerPage;
