import React from 'react';

function ManagesFinancePage() {
    return (
        <>
            <div className="main-panel" />
        </>
    );
}

export default ManagesFinancePage;
