/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ModalSettingDomainPrice({ show, handleClose, selectId, setSelectId, domainPriceAr }) {
    const [vendor, setVendor] = useState(domainPriceAr?.vendor || '');
    const [domainExt, setDomainExt] = useState(domainPriceAr?.domain_ext || '');
    const [priceReg, setPriceReg] = useState(domainPriceAr?.price_registration || '');
    const [priceRenew, setPriceRenew] = useState(domainPriceAr?.price_renew || '');
    const [priceTr, setPriceTr] = useState(domainPriceAr?.price_transfer || '');
    const [priceRes, setPriceRes] = useState(domainPriceAr?.price_restore || '');
    const [minDur, setMinDur] = useState(domainPriceAr?.min_duration || '');
    const [pricePromo, setPricePromo] = useState(domainPriceAr?.price_promotion || '');

    const handleInput = (e) => {
        if (e.target.name === 'vendor') {
            setVendor(e.target.value);
        }
        if (e.target.name === 'domainExt') {
            setDomainExt(e.target.value);
        }
        if (e.target.name === 'priceReg') {
            setPriceReg(e.target.value);
        }
        if (e.target.name === 'priceRenew') {
            setPriceRenew(e.target.value);
        }
        if (e.target.name === 'priceTr') {
            setPriceTr(e.target.value);
        }
        if (e.target.name === 'priceRes') {
            setPriceRes(e.target.value);
        }
        if (e.target.name === 'minDur') {
            setMinDur(e.target.value);
        }
        if (e.target.name === 'pricePromo') {
            setPricePromo(e.target.value);
        }
    };
    const handleContractDomain = () => {
        AxiosAuth.post(ConfigApi.DOMAIN_PRICE_UPDATE, {
            vendor,
            domainExt,
            priceReg,
            priceRenew,
            priceTr,
            priceRes,
            minDur,
            priceId: selectId,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                    setSelectId('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">Update Domain Price:</div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Vendor:</label>
                    <input
                        type="text"
                        name="vendor"
                        value={vendor}
                        placeholder="Type Vendor"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Domain Ext:</label>

                    <input
                        type="text"
                        name="domainExt"
                        value={domainExt}
                        placeholder="Type Domain Ext"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Price Promotion (USD):</label>
                    <input
                        type="text"
                        name="pricePromo"
                        value={pricePromo}
                        placeholder="Type Price Promotion (USD)"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Price Registration (USD):</label>
                    <input
                        type="text"
                        name="priceReg"
                        value={priceReg}
                        placeholder="Type Price Registration (USD)"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Price Renew (USD):</label>
                    <input
                        type="text"
                        name="priceRenew"
                        value={priceRenew}
                        placeholder="Type Price Renew (USD)"
                        onChange={handleInput}
                    />
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Price Transfer (USD):</label>

                    <input
                        type="text"
                        name="priceTr"
                        value={priceTr}
                        placeholder="Type Price Transfer (USD)"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Price Restore (USD):</label>
                    <input
                        type="text"
                        name="priceRes"
                        value={priceRes}
                        placeholder="Type Price Restore (USD)"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Min Duration (Months):</label>
                    <input
                        type="text"
                        name="minDur"
                        value={minDur}
                        placeholder="Type Min Duration (Months)"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={handleContractDomain}
                    >
                        Update
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalSettingDomainPrice;
