const TypeData = [
    {
        value: 'hosting',
        title: 'Hosting',
    },
    {
        value: 'domain',
        title: 'Domain',
    },

    {
        value: 'service',
        title: 'Services',
    },
    {
        value: 'email',
        title: 'Email',
    },
    {
        value: 'sms',
        title: 'SMS',
    },
];
export default TypeData;
