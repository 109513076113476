/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import BtnReload from '../../../../utils/BtnReload';
import CustomPropertyInfo from '../components/CustomPropertyInfo';
import ModalCustomContract from '../modals/ModalCustomContract';
import CustomBasicSettingInfo from '../section/CustomBasicSettingInfo';
import CustomDetailTab from '../section/CustomDetailTab';
import CustomOverviewInfo from '../section/CustomOverviewInfo';
import CustomSubContractInfo from '../section/CustomSubContractInfo';

function CustomDetail({ selectId, setSelectId, customAr }) {
    const [activeTab, setActiveTab] = useState(1);
    const [detailAr, setDetailAr] = useState([]);

    useEffect(() => {
        AxiosAuth.get(
            `${ConfigApi.CONTRACT_CUSTOM_SINGLE_INFO.replace(':customSl', selectId)}`
        ).then((response) => {
            setDetailAr(response.data.dataAr);
        });
    }, [selectId]);
    console.log(detailAr);
    return (
        <div className="main-panel" style={{ width: '68%' }}>
            <div className="main-panel-header">
                <div className="main-panel-title" style={{ width: '100%' }}>
                    <span style={{ flex: '1 1 0%' }}>
                        Custom Contact Details <br />
                        <small style={{ fontSize: 13, color: '#AE00B9' }}>
                            {detailAr?.title}, {detailAr?.contract_description}
                        </small>
                    </span>
                </div>
                <button
                    type="button"
                    onClick={() => setSelectId('')}
                    style={{ color: 'white', backgroundColor: 'red' }}
                >
                    Close
                </button>
            </div>
            <div className="main-panel-body">
                <CustomDetailTab activeTab={activeTab} setActiveTab={setActiveTab} />
                {activeTab === 1 ? <CustomOverviewInfo detailAr={detailAr} /> : null}
                {activeTab === 2 ? (
                    <CustomBasicSettingInfo
                        selectId={selectId}
                        detailAr={detailAr}
                        setDetailAr={setDetailAr}
                    />
                ) : null}
                {activeTab === 4 ? (
                    <CustomSubContractInfo
                        selectId={selectId}
                        customAr={customAr?.filter((row) => row.sl === selectId)[0]}
                    />
                ) : null}
                {activeTab === 5 ? (
                    <CustomPropertyInfo
                        selectId={selectId}
                        customAr={customAr?.filter((row) => row.sl === selectId)[0]}
                    />
                ) : null}
            </div>
        </div>
    );
}
function ManagesCustomContractPage() {
    const [customAr, setCustomAr] = useState([]);
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');
    const { message } = useTemplate();
    const [searchText, setSearchText] = useState('');
    const [isReload, setIsReload] = useState('');

    const customSearchAr = customAr.filter(
        (item) =>
            item.user_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.domain_name?.toLowerCase().includes(searchText?.toLowerCase())
    );
    const handleClose = () => {
        setShow(false);
        setShowNew(false);
    };
    const handleClick = (e) => {
        setSelectId(e);
        setShow(true);
    };

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CONTRACT_CUSTOM_LIST}`).then((response) => {
            setCustomAr(response.data.dataAr);
        });
    }, [show, showNew, message, isReload]);

    return (
        <>
            <div className="main-panel" style={{ width: selectId ? '29%' : '' }}>
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div
                            className="sidebar-panel-search"
                            style={{ width: selectId ? '68%' : '' }}
                        >
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search..."
                                aria-label=""
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>
                    </form>
                    <BtnReload setIsReload={setIsReload} isReload={isReload} />

                    {!selectId ? (
                        <button type="button" onClick={() => setShowNew(true)}>
                            Add New Contact
                        </button>
                    ) : null}
                </div>
                <div className="main-panel-body">
                    <div className="scroll-table-container">
                        <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                            <thead>
                                <tr>
                                    <th width="5%">SL</th>
                                    <th width="15%">User</th>
                                    <th width="15%">Title</th>
                                    {!selectId ? (
                                        <>
                                            <th width="5%">Price</th>
                                            <th width="5%">Created</th>
                                            <th width="5%">Expired</th>
                                            <th width="5%">Status</th>
                                        </>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {customSearchAr?.map((row) => (
                                    <tr
                                        key={row?.sl}
                                        onClick={() => setSelectId(row?.sl)}
                                        className={selectId === row?.sl ? 'active-tr' : null}
                                    >
                                        <td>{row?.sl}</td>
                                        <td>{row?.user_name}</td>
                                        <td>{row?.title}</td>
                                        {!selectId ? (
                                            <>
                                                <td>${row?.price?.toFixed(2)}</td>
                                                <td>{row?.time_issue}</td>
                                                <td>
                                                    {row?.expireDate !== null
                                                        ? row?.expireDate?.dueDate <= 31
                                                            ? `${row?.expireDate?.dueDate} Days Left`
                                                            : row?.expireDate?.expireDate
                                                        : '--'}
                                                </td>

                                                <td>{row?.status}</td>
                                            </>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {showNew ? <ModalCustomContract show={showNew} handleClose={handleClose} /> : null}
            </div>
            {selectId ? (
                <CustomDetail selectId={selectId} setSelectId={setSelectId} customAr={customAr} />
            ) : null}
        </>
    );
}

export default ManagesCustomContractPage;
