import React, { useEffect, useState } from 'react';

import IconEdit from '../../../../../assets/icon/user-profile-edit-icon-gray.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

const EditView = ({
    detailAr,
    resDomain,
    resDomainQty,
    resHosting,
    resService,
    resHostingQty,
    resServiceNote,
    resHostingNote,
    resDomainNote,
    resPushNote,
    resPush,
    handleInput,
    handelExitEdit,
    handelSubmitForm,
    mode,
}) => (
    <div className={`edit-view-area ${mode === detailAr?.sl ? 'show' : ''}`}>
        <div>
            <div style={{ display: 'flex', gap: '1%', flexWrap: 'wrap' }}>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <div
                        className={`contract-create-input-chkbox ${resDomain > 0 ? 'ccicn' : ''}`}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            alignItems: 'center',
                        }}
                    >
                        <span>Domain</span>
                        <input
                            type="checkbox"
                            name="resDomain"
                            value={resDomain}
                            onChange={handleInput}
                        />
                        {resDomain > 0 ? (
                            <>
                                <input
                                    type="text"
                                    className="ccin"
                                    value={resDomainQty}
                                    name="resDomainQty"
                                    onChange={handleInput}
                                    placeholder="D Qty"
                                />
                                <input
                                    type="text"
                                    className="ccin"
                                    value={resDomainNote}
                                    name="resDomainNote"
                                    onChange={handleInput}
                                    placeholder="Note"
                                    style={{ width: '280px' }}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <div
                        className={`contract-create-input-chkbox ${resDomain > 0 ? 'ccicn' : ''}`}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            alignItems: 'center',
                        }}
                    >
                        <span>Hosting</span>
                        <input
                            type="checkbox"
                            name="resHosting"
                            value={resHosting}
                            onChange={handleInput}
                        />
                        {resHosting > 0 ? (
                            <>
                                <input
                                    type="text"
                                    className="ccin"
                                    value={resHostingQty}
                                    name="resHostingQty"
                                    onChange={handleInput}
                                    placeholder="H Qty"
                                />
                                <input
                                    type="text"
                                    className="ccin"
                                    value={resHostingNote}
                                    name="resHostingNote"
                                    onChange={handleInput}
                                    placeholder="Note"
                                    style={{ width: '280px' }}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <div
                        className={`contract-create-input-chkbox ${resDomain > 0 ? 'ccicn' : ''}`}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            alignItems: 'center',
                        }}
                    >
                        <span>Service & Support</span>
                        <input
                            type="checkbox"
                            name="resService"
                            value={resService}
                            onChange={handleInput}
                        />
                        {resService > 0 ? (
                            <input
                                type="text"
                                className="ccin"
                                value={resServiceNote}
                                name="resServiceNote"
                                onChange={handleInput}
                                placeholder="Note"
                                style={{ width: '365px' }}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <div
                        className={`contract-create-input-chkbox ${resDomain > 0 ? 'ccicn' : ''}`}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            alignItems: 'center',
                        }}
                    >
                        <span>Push</span>
                        <input
                            type="checkbox"
                            name="resPush"
                            value={resPush}
                            onChange={handleInput}
                        />
                        {resPush > 0 ? (
                            <input
                                type="text"
                                className="ccin"
                                value={resPushNote}
                                name="resPushNote"
                                onChange={handleInput}
                                placeholder="Note"
                                style={{ width: '365px' }}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="line justify-end ">
                <button className="cncl-btn" type="button" onClick={handelExitEdit}>
                    Cancel
                </button>
                <button
                    onClick={handelSubmitForm}
                    type="button"
                    className="default-none btn-gr btn-gr-web ml-2"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
);

function CustomResource({ detailAr, setDetailAr }) {
    const { setMessage } = useTemplate();
    const [mode, setMode] = useState('');
    const [resDomain, setResDomain] = useState(0);
    const [resDomainQty, setResDomainQty] = useState(1);

    const [resHosting, setResHosting] = useState(0);
    const [resService, setResService] = useState(0);

    const [resHostingQty, setResHostingQty] = useState(0);
    const [resServiceNote, setResServiceNote] = useState('');
    const [resHostingNote, setResHostingNote] = useState('');
    const [resDomainNote, setResDomainNote] = useState('');
    const [resPushNote, setResPushNote] = useState('');
    const [resPush, setResPush] = useState(0);

    const handleInput = (e) => {
        if (e.target.name === 'resDomain') {
            if (e.target.checked) {
                setResDomain(1);
            } else {
                setResDomain(0);
            }
        }
        if (e.target.name === 'resDomainQty') {
            setResDomainQty(e.target.value);
        }
        if (e.target.name === 'resHostingQty') {
            setResHostingQty(e.target.value);
        }
        if (e.target.name === 'resServiceNote') {
            setResServiceNote(e.target.value);
        }
        if (e.target.name === 'resHosting') {
            if (e.target.checked) {
                setResHosting(1);
            } else {
                setResHosting(0);
            }
        }
        if (e.target.name === 'resService') {
            if (e.target.checked) {
                setResService(1);
            } else {
                setResService(0);
            }
        }

        if (e.target.name === 'resPush') {
            if (e.target.checked) {
                setResPush(1);
            } else {
                setResPush(0);
            }
        }
        if (e.target.name === 'resHostingNote') {
            setResHostingNote(e.target.value);
        }
        if (e.target.name === 'resDomainNote') {
            setResDomainNote(e.target.value);
        }
        if (e.target.name === 'resPushNote') {
            setResPushNote(e.target.value);
        }
    };

    const handelClickEdit = (e) => {
        setMode(e);
    };

    const handelExitEdit = () => {
        setMode('');
    };

    const handelSubmitForm = () => {
        setMessage('Updating Price ...');
        AxiosAuth.post(ConfigApi.CONTRACT_CUSTOM_SETTING_UPDATE_RESOURCE, {
            hostingSl: detailAr?.sl,
            resDomain,
            resDomainQty,
            resHosting,
            resService,
            resHostingQty,
            resPush,
            resServiceNote,
            resHostingNote,
            resDomainNote,
            resPushNote,
        }).then((response) => {
            setMessage(response?.data.message);
            setMode('');
            setDetailAr(response?.data.dataAr);
        });
    };
    useEffect(() => {
        if (detailAr?.price) {
            setResDomain(detailAr?.res_domain);
            setResDomainQty(detailAr?.res_domain_qty);
            setResHosting(detailAr?.res_hosting);
            setResService(detailAr?.res_service);
            setResHostingQty(detailAr?.res_hosting_qty);
            setResServiceNote(detailAr?.res_service_note);
            setResPush(detailAr?.res_push);
        }
    }, [
        detailAr.duration,
        detailAr.price,
        detailAr.price_offer,
        detailAr.res_domain,
        detailAr.res_domain_qty,
        detailAr.res_email,
        detailAr.res_hosting,
        detailAr.res_hosting_qty,
        detailAr.res_push,
        detailAr.res_service,
        detailAr.res_service_note,
        detailAr.res_sms,
    ]);
    return (
        <>
            <div className="line default-view-area ">
                <div className="cell  cell-3 cell-md-4 od-opacity">
                    <span className="profile-subject">Resource:</span>
                </div>
                <div className="cell-fill od-opacity res-spam">
                    {detailAr?.res_domain > 0 ? (
                        <span className="profile-subject">Domian</span>
                    ) : null}
                    {detailAr?.res_hosting > 0 ? (
                        <span className="profile-subject">Hosting</span>
                    ) : null}
                    {detailAr?.res_service > 0 ? (
                        <span className="profile-subject">Service</span>
                    ) : null}
                    {detailAr?.res_push > 0 ? <span className="profile-subject">Push</span> : null}
                </div>
                <div className="cell  cell-3 cell-md-4 od-opacity" />

                <div className="cell  od-opacity">
                    <button
                        type="button"
                        className="btn-profile-edit"
                        onClick={() => handelClickEdit(detailAr?.sl)}
                    >
                        <img className="w-100 " src={IconEdit} alt="Edit Icon" />
                    </button>
                </div>
            </div>
            <EditView
                detailAr={detailAr}
                handleInput={handleInput}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
                resDomain={resDomain}
                resDomainQty={resDomainQty}
                resHosting={resHosting}
                resService={resService}
                resHostingQty={resHostingQty}
                resServiceNote={resServiceNote}
                resPush={resPush}
            />
        </>
    );
}

export default CustomResource;
