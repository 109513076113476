/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import AddEmailModal from '../modals/AddEmailModal';
import AddPhoneModal from '../modals/AddPhoneModal';

// email
function ButtonActionWeb({
    emailMenu,
    setEmailMenu,
    provider,
    handleMakePrimary,
    proType,
    setType,
}) {
    const ref = useRef(null);

    const handleClickOption = () => {
        setType(proType);
        setEmailMenu(provider?.sl);
    };
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setEmailMenu('');
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return (
        <div className="position-relative">
            <div className="line-align justify-end ">
                <span className="common-table-btn">
                    <button
                        className="icon-action-dot-menu default-none purple-variant"
                        type="button"
                        onClick={handleClickOption}
                    />
                </span>
            </div>
            {emailMenu === provider?.sl ? (
                <div className="option-popup" ref={ref}>
                    <ul>
                        <li>
                            <button type="button" onClick={handleMakePrimary}>
                                Make Primary
                            </button>
                        </li>
                        <li>
                            <button type="button">Delete</button>
                        </li>
                    </ul>
                </div>
            ) : null}
        </div>
    );
}

function UserContactInfo({ userAr, providerAr, setProviderAr }) {
    const { setMessage } = useTemplate();
    const [emailShow, setEmailShow] = useState(false);
    const [phoneShow, setPhoneShow] = useState(false);
    const [emailMenu, setEmailMenu] = useState(false);
    const [type, setType] = useState('');

    const handleClose = () => {
        setEmailShow(false);
        setPhoneShow(false);
    };
    const handleMakePrimary = () => {
        setMessage('Provider Primary Processing....');
        AxiosAuth.post(ConfigApi.USER_PROVIDER_PRIMARY_SET, {
            userSl: userAr?.sl,
            type,
            providerSl: emailMenu,
        })
            .then((response) => {
                setMessage(response?.data.message);
                setEmailMenu('');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <div className="tab-detail">
                <div>
                    <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                        <thead>
                            <tr>
                                <th width="10%">Email</th>
                                <th width="15%">Status</th>
                                <th width="15%" style={{ textAlign: 'right', paddingRight: 6 }}>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {providerAr?.email?.map((rowEmail) => (
                                <tr key={rowEmail?.sl}>
                                    <td>{rowEmail?.email}</td>
                                    <td>
                                        {userAr?.primary_email === rowEmail?.sl
                                            ? 'This Email Is Primary Email'
                                            : null}
                                    </td>
                                    <td>
                                        <ButtonActionWeb
                                            emailMenu={emailMenu}
                                            setEmailMenu={setEmailMenu}
                                            provider={rowEmail}
                                            handleMakePrimary={handleMakePrimary}
                                            setType={setType}
                                            proType="email"
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="line-align justify-end mt-4 mb-6">
                        <div className="cell-sm-fill">
                            <div
                                role="button"
                                tabIndex="-1"
                                className="line-align add-btn-common mb-2 add-mail"
                                onClick={() => setEmailShow(true)}
                            >
                                <span className="ml-4">Add</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                        <thead>
                            <tr>
                                <th width="10%">Phone</th>
                                <th width="15%">Status</th>
                                <th width="15%" style={{ textAlign: 'right', paddingRight: 6 }}>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {providerAr?.phone?.map((rowPhone) => (
                                <tr>
                                    <td>{rowPhone?.phone}</td>
                                    <td>
                                        {userAr?.primary_phone === rowPhone?.sl
                                            ? 'This Phone Is Primary Phone'
                                            : null}
                                    </td>
                                    <td>
                                        <ButtonActionWeb
                                            emailMenu={emailMenu}
                                            setEmailMenu={setEmailMenu}
                                            provider={rowPhone}
                                            handleMakePrimary={handleMakePrimary}
                                            setType={setType}
                                            proType="mobile"
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="line-align justify-end mt-4 mb-6">
                        <div className="cell-sm-fill">
                            <div
                                role="button"
                                tabIndex="-1"
                                className="line-align add-btn-common mb-2 add-phone"
                                onClick={() => setPhoneShow(true)}
                            >
                                <span className="ml-4">Add</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {emailShow ? (
                <AddEmailModal
                    userAr={userAr}
                    show={emailShow}
                    handleClose={handleClose}
                    setProviderAr={setProviderAr}
                />
            ) : null}
            {phoneShow ? (
                <AddPhoneModal
                    userAr={userAr}
                    show={phoneShow}
                    handleClose={handleClose}
                    setProviderAr={setProviderAr}
                />
            ) : null}
        </>
    );
}

export default UserContactInfo;
