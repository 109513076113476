/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import BtnReload from '../../../../utils/BtnReload';
import ModalDomainAssignUser from '../modals/ModalDomainAssignUser';
import ModalDomainContract from '../modals/ModalDomainContract';
import DomainBasicSettingInfo from '../section/DomainBasicSettingInfo';
import DomainDetailTabs from '../section/DomainDetailTabs';
import DomainOverviewInfo from '../section/DomainOverviewInfo';

function DomainDetail({ selectId, setSelectId }) {
    const [activeTab, setActiveTab] = useState(1);
    const [domainDetailAr, setdomainDetailAr] = useState([]);
    const { message, setMessage } = useTemplate();

    useEffect(() => {
        AxiosAuth.get(
            `${ConfigApi.CONTRACT_DOMAIN_SINGLE_INFO.replace(':domainSl', selectId)}`
        ).then((response) => {
            setdomainDetailAr(response.data.dataAr);
        });
    }, [message, selectId, setMessage]);

    return (
        <div className="main-panel" style={{ width: '68%' }}>
            <div className="main-panel-header">
                <div className="main-panel-title" style={{ width: '100%' }}>
                    <span style={{ flex: '1 1 0%' }}>Hosting Details</span>
                </div>
                <button
                    type="button"
                    onClick={() => setSelectId('')}
                    style={{ color: 'white', backgroundColor: 'red' }}
                >
                    Close
                </button>
            </div>
            <div className="main-panel-body">
                <DomainDetailTabs activeTab={activeTab} setActiveTab={setActiveTab} />
                {activeTab === 1 ? <DomainOverviewInfo domainDetailAr={domainDetailAr} /> : null}
                {activeTab === 2 ? (
                    <DomainBasicSettingInfo
                        selectId={selectId}
                        domainDetailAr={domainDetailAr}
                        setdomainDetailAr={setdomainDetailAr}
                    />
                ) : null}
            </div>
        </div>
    );
}
function ManagesDomainPage() {
    const { message, setMessage } = useTemplate();
    const [domainAr, setDomainAr] = useState([]);
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');
    const [isReload, setIsReload] = useState('');
    const [isCustom, setIsCustom] = useState('');
    const [isStar, setIsStar] = useState('');
    const [searchText, setSearchText] = useState('');

    const domainSearchAr2 = domainAr?.filter(
        (item) =>
            item.user_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.domain_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.vendor?.toLowerCase().includes(searchText?.toLowerCase())
    );
    const domainSearchAr3 = domainSearchAr2?.filter((item) =>
        item.is_starred?.toLowerCase().includes(isStar?.toLowerCase())
    );
    const domainSearchAr = domainSearchAr3?.filter((item) =>
        item.is_sub_contract?.toLowerCase().includes(isCustom?.toLowerCase())
    );

    const handleClose = () => {
        setShow(false);
        setShowNew(false);
    };
    const handleClick = (e) => {
        setSelectId(e);
        setShow(true);
    };
    const handleExecute = (e) => {
        console.log(e);
    };

    const handleRenew = (e) => {
        console.log(e);
    };
    const handleStarred = (e) => {
        AxiosAuth.post(`${ConfigApi.CONTRACT_DOMAIN_STARRED.replace(':domainSl', e)}`).then(
            (response) => {
                setMessage(response.data.message);
            }
        );
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CONTRACT_DOMAIN_LIST}`).then((response) => {
            setDomainAr(response.data.dataAr);
        });
    }, [show, showNew, isReload, message]);

    return (
        <>
            <div className="main-panel" style={{ width: selectId ? '29%' : '' }}>
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div
                            className="sidebar-panel-search"
                            style={{ width: selectId ? '50%' : '' }}
                        >
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search Domain"
                                aria-label=""
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>
                        <select
                            className="sidebar-panel-search-select"
                            style={{ width: selectId ? '25%' : '10%' }}
                            onChange={(e) => setIsCustom(e.target.value)}
                            value={isCustom}
                        >
                            <option value="">All</option>
                            <option value="1">Is Custom</option>
                            <option value="0">Not Custom</option>
                        </select>
                        <select
                            className="sidebar-panel-search-select"
                            style={{ width: selectId ? '25%' : '10%' }}
                            onChange={(e) => setIsStar(e.target.value)}
                            value={isStar}
                        >
                            <option value="">All</option>
                            <option value="1">Star</option>
                            <option value="0">Not Star</option>
                        </select>
                    </form>
                    <BtnReload setIsReload={setIsReload} isReload={isReload} />
                    {!selectId ? (
                        <button type="button" onClick={() => setShowNew(true)}>
                            Add New Contact
                        </button>
                    ) : null}
                </div>
                <div className="main-panel-body">
                    <div className="scroll-table-container">
                        <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                            <thead>
                                <tr>
                                    {!selectId ? <th width="2%" /> : null}

                                    <th width="5%">SL</th>
                                    {selectId ? (
                                        <th>Info</th>
                                    ) : (
                                        <>
                                            <th width="20%">User</th>
                                            <th width="10%">Vendor</th>
                                            <th width="25%">Domain</th>
                                            <th width="5%">Price</th>
                                            <td width="10%">Executed</td>
                                            <td width="5%">Renew</td>
                                            <th width="10%">Expired</th>
                                            <th width="5%">Status</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {domainSearchAr?.map((row) => (
                                    <tr
                                        key={row?.sl}
                                        className={selectId === row?.sl ? 'active-tr' : null}
                                    >
                                        {!selectId ? (
                                            <td>
                                                <button
                                                    type="button"
                                                    className={`item-starred ${
                                                        row?.is_starred === '1'
                                                            ? 'item-starred-active'
                                                            : null
                                                    }`}
                                                    onClick={() => handleStarred(row?.sl)}
                                                />
                                            </td>
                                        ) : null}
                                        <td onClick={() => setSelectId(row?.sl)}>{row?.sl}</td>
                                        {selectId ? (
                                            <>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.parent_sl > 0 ? (
                                                        <>
                                                            <span
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            >
                                                                {row?.domain_name}
                                                            </span>
                                                            <br />
                                                            <span
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            >
                                                                {row?.parentUser}
                                                            </span>
                                                            <br />
                                                            <span
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            >
                                                                <small> {row?.parentEmail}</small>
                                                            </span>
                                                        </>
                                                    ) : row?.user_sl > 0 ? (
                                                        <>
                                                            <span>{row?.user_name}</span>
                                                            <br />
                                                            <span>
                                                                <small> {row?.user_email}</small>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            style={{
                                                                background: 'unset',
                                                                border: 'unset',
                                                                paddingLeft: 0,
                                                                textAlign: 'left',
                                                            }}
                                                            onClick={() => handleClick(row?.sl)}
                                                        >
                                                            {row?.domain_name}
                                                            <br />
                                                            Not Set
                                                        </button>
                                                    )}

                                                    <span> {row?.title}</span>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.parent_sl > 0 ? (
                                                        <>
                                                            <span
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            >
                                                                {row?.domain_name}
                                                            </span>
                                                            <br />
                                                            <span
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            >
                                                                {row?.parentUser}
                                                            </span>
                                                            <br />
                                                            <span
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            >
                                                                <small> {row?.parentEmail}</small>
                                                            </span>
                                                        </>
                                                    ) : row?.user_sl > 0 ? (
                                                        <>
                                                            <span>{row?.user_name}</span>
                                                            <br />
                                                            <span>
                                                                <small> {row?.user_email}</small>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            style={{
                                                                background: 'unset',
                                                                border: 'unset',
                                                                paddingLeft: 0,
                                                                textAlign: 'left',
                                                            }}
                                                            onClick={() => handleClick(row?.sl)}
                                                        >
                                                            {row?.domain_name}
                                                            <br />
                                                            Not Set
                                                        </button>
                                                    )}
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.vendor}
                                                </td>

                                                <td>
                                                    <a
                                                        target="_blank"
                                                        href={`https://${row?.domain_name}`}
                                                        rel="noreferrer"
                                                    >
                                                        {row?.domain_name}
                                                    </a>
                                                    <br />
                                                    <div className="table-external-icon">
                                                        <a
                                                            target="_blank"
                                                            href={`https://www.whois.com/whois/${row?.domain_name}`}
                                                            rel="noreferrer"
                                                        >
                                                            Whois
                                                        </a>{' '}
                                                        ||{' '}
                                                        <a
                                                            target="_blank"
                                                            href={`https://www.whatsmydns.net/#A/${row?.domain_name}`}
                                                            rel="noreferrer"
                                                        >
                                                            DNS Zone
                                                        </a>{' '}
                                                        ||{' '}
                                                        <a
                                                            target="_blank"
                                                            href={`https://dnschecker.org/domain-health-checker.php?query=${row?.domain_name}`}
                                                            rel="noreferrer"
                                                        >
                                                            DNS Health
                                                        </a>{' '}
                                                        ||{' '}
                                                        <a
                                                            target="_blank"
                                                            href={`https://dnschecker.org/mx-lookup.php?query=${row?.domain_name}`}
                                                            rel="noreferrer"
                                                        >
                                                            MX Lookup
                                                        </a>
                                                    </div>
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    ${row?.price?.toFixed(2)}
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.time_executed ? (
                                                        row?.time_executed
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="execute-btn"
                                                            onClick={handleExecute}
                                                        >
                                                            Execute Now
                                                        </button>
                                                    )}
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    <button
                                                        type="button"
                                                        className="renew-btn"
                                                        onClick={handleRenew}
                                                    >
                                                        Renew
                                                    </button>
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.time_expire}
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.status}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <ModalDomainAssignUser
                    show={show}
                    handleClose={handleClose}
                    selectId={selectId}
                    setSelectId={setSelectId}
                />
                {showNew ? (
                    <ModalDomainContract
                        show={showNew}
                        handleClose={handleClose}
                        selectDomain={domainAr?.filter((row) => row.sl === selectId)[0]}
                        selectId={selectId}
                    />
                ) : null}
            </div>
            {selectId ? <DomainDetail selectId={selectId} setSelectId={setSelectId} /> : null}
        </>
    );
}

export default ManagesDomainPage;
