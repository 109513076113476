import React from 'react';

function CustomOverviewInfo({ detailAr }) {
    console.log(detailAr);
    return (
        <div className="tab-detail">
            <div className="content-section">
                <div className="content-body-scroll user-profile-overview-area">
                    <div className="product-info mb-6 profile-info-area">
                        <div>
                            <div className="line-align line-no-wrap mb-2">
                                <div className="cell title fw-400">Info & Setting</div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Title:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {detailAr?.title ? detailAr?.title : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Created Date:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <div className="line-row-between line-no-wrap">
                                                <span className="value common-value fw-400 cell-fill overflow-dotted ">
                                                    {detailAr?.time_issue
                                                        ? detailAr?.time_issue
                                                        : '--'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Description:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {detailAr?.contract_description
                                                    ? detailAr?.contract_description
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Expired Date:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {detailAr?.expireDate?.expireDate
                                                    ? detailAr?.expireDate?.expireDate
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-4">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">Note:</span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {detailAr?.note ? detailAr?.note : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomOverviewInfo;
