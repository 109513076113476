export const api =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEMO_URL
        : process.env.REACT_APP_API_URL;

const ConfigApi = {
    API_APP_INIT: `${api}/api/init`,
    // Auth
    LOGIN: `${api}/login`,
    REGISTER: `${api}/register`,
    LOGOUT: `${api}/logout`,

    // Contract Domain
    CONTRACT_PACKAGE_SEARCH: `${api}/manages/contract/package/search`,
    CONTRACT_DOMAIN_SEARCH: `${api}/manages/contract/domain/search`,
    CONTRACT_DOMAIN_LIST: `${api}/manages/contract/domain`,
    CONTRACT_DOMAIN_USER_SEARCH: `${api}/manages/contract/domain/user/search`,
    CONTRACT_DOMAIN_USER_SET: `${api}/manages/contract/domain/user/set`,
    CONTRACT_DOMAIN_CREATE: `${api}/manages/contract/domain/create`,
    CONTRACT_DOMAIN_UPDATE: `${api}/manages/contract/domain/update`,
    CONTRACT_DOMAIN_SINGLE_INFO: `${api}/manages/contract/domain/:domainSl/json/info`,
    CONTRACT_DOMAIN_SETTING_UPDATE: `${api}/manages/contract/domain/setting/update`,
    CONTRACT_DOMAIN_SETTING_UPDATE_PRICE: `${api}/manages/contract/domain/setting/update/price`,
    CONTRACT_DOMAIN_STARRED: `${api}/manages/contract/domain/:domainSl/starred`,

    // Contract Hosting
    CONTRACT_HOSTING_SEARCH: `${api}/manages/contract/hosting/search`,
    CONTRACT_HOSTING_LIST: `${api}/manages/contract/hosting`,
    CONTRACT_HOSTING_CREATE: `${api}/manages/contract/hosting/create`,
    CONTRACT_HOSTING_UPDATE: `${api}/manages/contract/hosting/update`,
    CONTRACT_HOSTING_USER_SET: `${api}/manages/contract/hosting/user/set`,
    CONTRACT_HOSTING_SINGLE_INFO: `${api}/manages/contract/hosting/:hostingSl/json/info`,
    CONTRACT_HOSTING_SETTING_UPDATE: `${api}/manages/contract/hosting/setting/update`,
    CONTRACT_HOSTING_SETTING_UPDATE_PRICE: `${api}/manages/contract/hosting/setting/update/price`,
    CONTRACT_HOSTING_STARRED: `${api}/manages/contract/hosting/:hostingSl/starred`,
    CONTRACT_HOSTING_SETTING_UPDATE_PROPERTY: `${api}/manages/contract/hosting/setting/update/property`,
    CONTRACT_HOSTING_SETTING_UPDATE_PROPERTY_PACKAGE: `${api}/manages/contract/hosting/setting/update/property/package`,

    // Contract Custom
    CONTRACT_CUSTOM_LIST: `${api}/manages/contract/custom`,
    CONTRACT_CUSTOM_CREATE: `${api}/manages/contract/custom/create`,
    CONTRACT_CUSTOM_UPDATE: `${api}/manages/contract/custom/update`,
    CONTRACT_CUSTOM_USER_SET: `${api}/manages/contract/custom/user/set`,
    CONTRACT_CUSTOM_SINGLE_INFO: `${api}/manages/contract/custom/:customSl/json/info`,
    CONTRACT_CUSTOM_SETTING_UPDATE: `${api}/manages/contract/custom/setting/update`,
    CONTRACT_CUSTOM_SETTING_UPDATE_PRICE: `${api}/manages/contract/custom/setting/update/price`,
    CONTRACT_CUSTOM_SETTING_UPDATE_RESOURCE: `${api}/manages/contract/custom/setting/update/resource`,

    CONTRACT_CUSTOM_SUBCONTRACT_LIST: `${api}/manages/contract/custom/:customSl/subcontract/list`,
    CONTRACT_CUSTOM_SUBCONTRACT_HOSTING_CREATE: `${api}/manages/contract/custom/subcontract/hosting/create`,
    CONTRACT_CUSTOM_SUBCONTRACT_DOMAIN_CREATE: `${api}/manages/contract/custom/subcontract/domain/create`,
    CONTRACT_CUSTOM_SUBCONTRACT_DOMAIN_REMOVE: `${api}/manages/contract/custom/subcontract/domain/remove`,
    CONTRACT_CUSTOM_SUBCONTRACT_HOSTING_REMOVE: `${api}/manages/contract/custom/subcontract/hosting/remove`,
    CONTRACT_CUSTOM_PROPERTY: `${api}/manages/contract/custom/:userSl/property`,

    // Contract Server
    CONTRACT_SERVER_LIST: `${api}/manages/contract/server`,
    CONTRACT_SERVER_CREATE: `${api}/manages/contract/server/create`,

    // Contract Cpanel Vendor
    CPANEL_VENDOR_LIST: `${api}/manages/contract/cpanel-vendor`,
    CPANEL_VENDOR_CREATE: `${api}/manages/contract/cpanel-vendor/create`,

    // Users
    USER_LIST: `${api}/manages/users/list`,
    USER_SINGLE_INFO: `${api}/manages/users/:userSl/single`,
    USER_CREATE: `${api}/manages/users/create`,
    USER_PASS_SET_EMAIL: `${api}/manages/users/set-pass/email`,
    USER_BASIC_UPDATE: `${api}/manages/users/basic/update`,
    USER_ADD_PROVIDER: `${api}/manages/users/create/provider`,
    USER_ADDRESS_LIST: `${api}/manages/users/list/:userSl/address`,
    USER_ADDRESS_CREATE: `${api}/manages/users/create/address`,
    USER_ADDRESS_UPDATE: `${api}/manages/users/update/address`,
    USER_PROVIDER_PRIMARY_SET: `${api}/manages/users/provider/primary/set`,
    CONTRACT_USER_CONTRACT_LIST: `${api}/manages/users/contract/:userSl/list`,
    CONTRACT_USER_STARRED: `${api}/manages/users/:userSl/starred`,
    // Finance
    FINANCE_CURRENCIES_LIST: `${api}/manages/finance/currencies`,
    FINANCE_CURRENCY_UPDATE: `${api}/manages/finance/currency/update`,
    FINANCE_GATEWAY_LIST: `${api}/manages/finance/gateway`,
    FINANCE_TRAN_LIST: `${api}/manages/finance/transaction`,

    // Setting
    DOMAIN_PRICE_LIST: `${api}/manages/setting/domain-price`,
    DOMAIN_PRICE_UPDATE: `${api}/manages/setting/domain-price/update`,
    DOMAIN_PRICE_TLD_UPDATE: `${api}/manages/setting/domain-price/update/tld`,
    // Website
    ACTIVITY_LOG_LIST: `${api}/manages/website/active-log`,
    CLIENT_LIST: `${api}/manages/website/client-list`,
    CLIENT_LIST_CREATE: `${api}/manages/website/client-list/create`,
    CLIENT_LIST_UPDATE: `${api}/manages/website/client-list/update`,
    NOTIFY_EMAIL_OTP_LIST: `${api}/manages/website/otp-notify-config`,
    NOTIFY_EMAIL_OTP_CREATE: `${api}/manages/website/otp-notify-config/create`,
    NOTIFY_EMAIL_OTP_UPDATE: `${api}/manages/website/otp-notify-config/update`,

    // Invoice
    CONTRACT_INVOICE_LIST: `${api}/manages/invoice/list`,
    CONTRACT_INVOICE_DETAILS: `${api}/manages/invoice/:invoiceId/details`,
    // Invoice
    CART_LIST: `${api}/manages/cart/list`,
    CART_DETAILS: `${api}/manages/cart/:invoiceId/details`,

    // File Upload
    FILE_UPLOAD: `${api}/file/upload`,

    // Services
    SERVICE_LIST: `${api}/manages/setting/services`,
    SERVICE_LIST_CREATE: `${api}/manages/setting/services/create`,
    SERVICE_LIST_UPDATE: `${api}/manages/setting/services/update`,
    // Packages
    PACKAGE_LIST: `${api}/manages/setting/:serviceSl/packages`,
    PACKAGE_LIST_CREATE: `${api}/manages/setting/packages/create`,
    PACKAGE_LIST_UPDATE: `${api}/manages/setting/packages/update`,
    // Packages
    FAQ_LIST: `${api}/manages/website/faq-list`,
    FAQ_LIST_CREATE: `${api}/manages/website/faq/create`,
    FAQ_LIST_UPDATE: `${api}/manages/website/faq/update`,
};

export default ConfigApi;
