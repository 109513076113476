import React, { useEffect, useState } from 'react';
import EditIcon from '../../../../../assets/images/editicon.svg';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ModalOtpEmailNotify from '../modals/ModalOtpEmailNotify';

function OtpListAr({ configAr, handleUpdate }) {
    return (
        <table className="main-panel-body-table mb-3">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Key Name</th>
                    <th>Template Path</th>
                    <th>Template HTML</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {Object.values(configAr)?.map((row) => (
                    <tr key={row?.sl}>
                        <td>{row?.sl}</td>
                        <td>{row?.key_name}</td>
                        <td>{row?.template_path}</td>
                        <td>{row?.template_html}</td>
                        <td>{row?.status}</td>
                        <td className="action-area">
                            <button type="button" onClick={() => handleUpdate(row?.sl)}>
                                <img src={EditIcon} alt="Edit" />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function ManagesWebsiteNotifyOtpConfig() {
    const [configAr, setconfigAr] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');

    const configArSeach =
        searchText?.length > 0
            ? configAr?.filter((data) => data?.key_name?.includes(searchText))
            : configAr;

    const handleClose = () => {
        setShowNew(false);
        setSelectId('');
    };
    const handleUpdate = (e) => {
        setShowNew(true);
        setSelectId(e);
    };

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.NOTIFY_EMAIL_OTP_LIST}`).then((response) => {
            setconfigAr(response.data.dataAr);
        });
    }, [showNew]);

    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                onChange={(e) => setSearchText(e.target.value)}
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search Key Name..."
                                aria-label=""
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>
                    </form>
                    <button type="button" onClick={() => setShowNew(true)}>
                        Add New Config
                    </button>
                </div>
                <div className="main-panel-body">
                    <OtpListAr configAr={configArSeach} handleUpdate={handleUpdate} />
                </div>
            </div>
            {showNew ? (
                <ModalOtpEmailNotify
                    show={showNew}
                    handleClose={handleClose}
                    selectConfig={configAr?.filter((row) => row.sl === selectId)[0]}
                    selectId={selectId}
                    setSelectId={setSelectId}
                />
            ) : null}
        </>
    );
}

export default ManagesWebsiteNotifyOtpConfig;
