import React, { useState } from 'react';

function TagsInput({ data, setData }) {
    const [inpData, setInpData] = useState('');
    const handleData = (e) => {
        if (e.keyCode === 13) {
            setData([...data, e.target.value]);
            setInpData('');
        }
    };
    const handleDataCancel = (e) => {
        const updatetag = data.filter((tag) => tag !== e);
        setData(updatetag);
    };
    return (
        <div className="tag-input">
            {data?.map((row) => (
                <span>
                    {row}{' '}
                    <button type="button" onClick={() => handleDataCancel(row)}>
                        x
                    </button>
                </span>
            ))}

            <input
                type="text"
                value={inpData}
                onChange={(e) => setInpData(e.target.value)}
                placeholder="Type Tags"
                onKeyDown={handleData}
            />
        </div>
    );
}

export default TagsInput;
