/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import Autocomplete from '../../../../utils/Autocomplete';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ModalDomainContract({ show, handleClose, selectDomain, selectId }) {
    const [inputValueSl, setInputValueSl] = useState(selectDomain?.userSl || '');
    const [domainName, setDomainName] = useState(selectDomain?.domain_name || '');
    const [vendorName, setVendorName] = useState(selectDomain?.vendor || '');
    const [price, setPrice] = useState(selectDomain?.priceR || '');
    const [offerPrice, setOfferPrice] = useState(selectDomain?.priceO || '');
    const [createdDate, setCreatedDate] = useState(selectDomain?.time_issue || '');
    const [duration, setDuration] = useState(selectDomain?.duration || 12);

    const handleInput = (e) => {
        if (e.target.name === 'domainName') {
            setDomainName(e.target.value);
        }
        if (e.target.name === 'vendorName') {
            setVendorName(e.target.value);
        }
        if (e.target.name === 'price') {
            setPrice(e.target.value);
            setOfferPrice(e.target.value);
        }
        if (e.target.name === 'offerPrice') {
            setOfferPrice(e.target.value);
        }
        if (e.target.name === 'createdDate') {
            setCreatedDate(e.target.value);
        }
        if (e.target.name === 'duration') {
            setDuration(e.target.value);
        }
    };

    const handleContractDomain = () => {
        AxiosAuth.post(ConfigApi.CONTRACT_DOMAIN_CREATE, {
            userSl: inputValueSl,
            domainName,
            vendorName,
            price,
            offerPrice,
            createdDate,
            duration,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                    setInputValueSl('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleUpdateDomain = () => {
        AxiosAuth.post(ConfigApi.CONTRACT_DOMAIN_UPDATE, {
            selectId,
            price,
            offerPrice,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                    setInputValueSl('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    console.log(createdDate);
    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">
                    {' '}
                    {selectId ? 'Update' : 'Create New'} Domain Contract:
                </div>
                <div className="contract-create-input">
                    <label htmlFor="target_link">Select User:</label>
                    <div className="contract-create-input-custom">
                        {!selectId ? (
                            <Autocomplete setInputValueSl={setInputValueSl} />
                        ) : (
                            <div className="modal-input-show">
                                <div className="modal-input-show-text">
                                    {selectDomain?.user_name}
                                    <br />
                                    {selectDomain?.user_email}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="contract-create-input">
                    <label htmlFor="domainName">Domain Name:</label>
                    <input
                        type="text"
                        name="domainName"
                        value={domainName}
                        placeholder="Type Domain Name"
                        onChange={handleInput}
                        readOnly={selectId ? 'readOnly' : null}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Vendor Name:</label>

                    <select
                        className="form-control"
                        name="vendorName"
                        value={vendorName}
                        onChange={handleInput}
                        readOnly={selectId ? 'readOnly' : null}
                    >
                        <option value="">Select Vendor</option>
                        <option value="resellbiz">Resellbiz</option>
                    </select>
                </div>
                <div className="contract-create-input2">
                    <label htmlFor="n_title">Price (USD):</label>
                    <input
                        type="text"
                        name="price"
                        value={price}
                        placeholder="Type Regular Price"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input2">
                    <label htmlFor="n_title">Offer Price (USD):</label>
                    <input
                        type="text"
                        name="offerPrice"
                        value={offerPrice}
                        placeholder="Type Offer Price"
                        onChange={handleInput}
                    />
                </div>

                <div className="contract-create-input">
                    <label htmlFor="schedule">Created Date:</label>
                    <input
                        name="createdDate"
                        value={createdDate}
                        type="date"
                        className="form-control"
                        onChange={handleInput}
                        readOnly={selectId ? 'readOnly' : null}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Duration:</label>
                    <select
                        className="form-control"
                        name="duration"
                        value={duration}
                        onChange={handleInput}
                        readOnly={selectId ? 'readOnly' : null}
                    >
                        <option value={12}>1 Year</option>
                        <option value={24}>2 Year</option>
                        <option value={36}>3 Year</option>
                        <option value={48}>4 Year</option>
                        <option value={60}>5 Year</option>
                        <option value={72}>6 Year</option>
                        <option value={84}>7 Year</option>
                        <option value={96}>8 Year</option>
                        <option value={108}>9 Year</option>
                        <option value={120}>10 Year</option>
                    </select>
                </div>
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={selectId ? handleUpdateDomain : handleContractDomain}
                    >
                        {selectId ? 'Update' : 'Create'}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalDomainContract;
