import React from 'react';

function HostingOverviewInfo({ hostDetailAr }) {
    console.log(hostDetailAr);
    return (
        <div className="tab-detail">
            <div className="content-section">
                <div className="content-body-scroll user-profile-overview-area">
                    <div className="product-info mb-6 profile-info-area">
                        <div>
                            <div className="line-align line-no-wrap mb-2">
                                <div className="cell title fw-400">Info & Setting</div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                User Name:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.cp_username
                                                    ? hostDetailAr?.cp_username
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Primary Domain:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <div className="line-row-between line-no-wrap">
                                                <span className="value common-value fw-400 cell-fill overflow-dotted ">
                                                    {hostDetailAr?.cp_domain
                                                        ? hostDetailAr?.cp_domain
                                                        : '--'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">Host:</span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.cp_host
                                                    ? hostDetailAr?.cp_host
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Password:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">---</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-4">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                IP Address:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.cp_user_ip
                                                    ? hostDetailAr?.cp_user_ip
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Shell Access:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.cp_shell === '0' ? 'Off' : 'On'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Email:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.cp_email
                                                    ? hostDetailAr?.cp_email
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">Disk:</span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.disk ? hostDetailAr?.disk : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Disk Type:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.disk_type
                                                    ? hostDetailAr?.disk_type
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Bandwidth:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.bandwidth
                                                    ? hostDetailAr?.bandwidth
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body-scroll user-profile-overview-area">
                    <div className="product-info mb-6 profile-info-area">
                        <div>
                            <div className="line-align line-no-wrap mb-2">
                                <div className="cell title fw-400">Billing</div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Domain Name:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.cp_domain
                                                    ? hostDetailAr?.cp_domain
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Created Date:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <div className="line-row-between line-no-wrap">
                                                <span className="value common-value fw-400 cell-fill overflow-dotted ">
                                                    {hostDetailAr?.time_issue
                                                        ? hostDetailAr?.time_issue
                                                        : '--'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Title:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.title ? hostDetailAr?.title : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Renewal Date:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {' '}
                                                {hostDetailAr?.expireDate
                                                    ? `${hostDetailAr?.expireDate?.expireDate} or ${hostDetailAr?.expireDate?.dueDate} Days`
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-4">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Contract ID:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {' '}
                                                {hostDetailAr?.sl}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Billing Status:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">---</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Package Price:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {hostDetailAr?.price ? hostDetailAr?.price : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body-scroll user-profile-overview-area">
                    <div className="product-info mb-6 profile-info-area">
                        <div>
                            <div className="line-align line-no-wrap mb-2">
                                <div className="cell title fw-400">Hosting Access</div>
                            </div>
                            <div className="scroll-table-container" style={{ height: 'auto' }}>
                                <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                                    <thead>
                                        <tr>
                                            <th width="5%">ID</th>
                                            <th width="15%">Name</th>
                                            <th width="15%">Assign Date</th>
                                            <th width="15%">Role</th>
                                            <th width="15%">Acction</th>
                                        </tr>
                                    </thead>
                                    <tbody />
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HostingOverviewInfo;
