import React, { useState } from 'react';

import Searchicon from '../../../../../assets/images/input-search-icon.svg';

function ManagesReportPage() {
    const [searchText, setSearchText] = useState('');

    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search..."
                                aria-label=""
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>

                        <button type="submit">Search</button>
                    </form>
                </div>
                <div className="main-panel-body">
                    <table className="main-panel-body-table mb-3">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Name</th>
                                <th>Username</th>
                                <th width="15%">Provider</th>
                                <th>Join date</th>
                                <th width="15%">Address</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody />
                    </table>
                </div>
            </div>
        </>
    );
}

export default ManagesReportPage;
