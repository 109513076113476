/* eslint-disable jsx-a11y/label-has-associated-control */

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import StatusData from '../../../../../configs/data/StatusData';
import SubTypeData from '../../../../../configs/data/SubTypeData';
import TypeData from '../../../../../configs/data/TypeData';
import AxiosAuth from '../../../../utils/AxiosAuth';
import DropdownMenu from '../../../../utils/DropdownMenu';
import TagsInput from '../../../../utils/TagsInput';

function ModalFAQ({ show, handleClose, selectfaq, setSelectId }) {
    const [type, setType] = useState(selectfaq?.type || '');
    const [subType, setSubType] = useState(selectfaq?.sub_type || '');
    const [question, setQuestion] = useState(selectfaq?.question || '');
    const [answer, setAnswer] = useState(selectfaq?.answer || '');
    const [tags, setTags] = useState(selectfaq?.tags || []);
    const [url, setUrl] = useState(selectfaq?.url || '');
    const [status, setStatus] = useState(selectfaq?.status || '');

    const handleInput = (e) => {
        if (e.target.name === 'question') {
            setQuestion(e.target.value);
        }

        if (e.target.name === 'url') {
            setUrl(e.target.value);
        }
    };

    const handleCreateClient = () => {
        AxiosAuth.post(ConfigApi.FAQ_LIST_CREATE, {
            type,
            subType,
            question,
            answer,
            url,
            tags,
            status,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                }
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleUpdateClient = () => {
        AxiosAuth.post(ConfigApi.FAQ_LIST_UPDATE, {
            type,
            subType,
            question,
            answer,
            url,
            tags,
            faqSl: selectfaq?.sl,
            status,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                    setSelectId('');
                }
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    if (!show) {
        return null;
    }
    return (
        <div style={{ width: '79%' }}>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">
                    {selectfaq?.sl ? 'Update FAQ:' : 'Create New FAQ:'}
                    <button
                        type="button"
                        onClick={handleClose}
                        style={{ float: 'right' }}
                        className="cancel-btn"
                    >
                        Close
                    </button>
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Type:</label>
                    <div
                        className="sidebar-panel-search"
                        style={{ width: '100%', margin: '0', height: ' 45px' }}
                    >
                        <DropdownMenu
                            selectedOption={type}
                            setSelectedOption={setType}
                            options=""
                            optionsM={TypeData}
                            width="100%"
                            defaultOption="Select Type"
                            valueKey="value"
                            optionKey="title"
                        />
                    </div>
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Sub Type:</label>
                    <div
                        className="sidebar-panel-search"
                        style={{ width: '100%', margin: '0', height: ' 45px' }}
                    >
                        <DropdownMenu
                            selectedOption={subType}
                            setSelectedOption={setSubType}
                            options=""
                            optionsM={SubTypeData}
                            width="100%"
                            defaultOption="Select Sub Type"
                            valueKey="value"
                            optionKey="title"
                        />
                    </div>
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <label htmlFor="n_title">Question</label>
                    <input
                        type="text"
                        name="question"
                        value={question}
                        placeholder="Type Question"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <label htmlFor="n_title">Answer</label>

                    <CKEditor
                        editor={ClassicEditor}
                        data={answer}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setAnswer(data);
                        }}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Url</label>
                    <input
                        type="text"
                        value={url}
                        name="url"
                        placeholder="Type Services"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Status</label>
                    <div
                        className="sidebar-panel-search"
                        style={{ width: '100%', margin: '0', height: ' 45px' }}
                    >
                        <DropdownMenu
                            selectedOption={status}
                            setSelectedOption={setStatus}
                            options=""
                            optionsM={StatusData}
                            width="100%"
                            defaultOption="Select Status"
                            valueKey="value"
                            optionKey="title"
                        />
                    </div>
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <label htmlFor="n_title">Tags</label>
                    <TagsInput data={tags} setData={setTags} />
                </div>
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={selectfaq?.sl ? handleUpdateClient : handleCreateClient}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}
function FaqListAr({ faqAr, handleUpdate, showNew }) {
    return (
        <table className="main-panel-body-table mb-3">
            <thead>
                <tr>
                    <th style={{ width: '5%' }}>Type</th>
                    <th style={{ width: '8%' }}>Sub Type</th>
                    {!showNew ? (
                        <>
                            <th style={{ width: '24%' }}>Question</th>
                            <th style={{ width: '48%' }}>Answer</th>
                            <th style={{ width: '7%' }}>Total Like</th>
                            <th style={{ width: '7%' }}>Total Dislike</th>
                            <th style={{ width: '10%' }}>Tags</th>
                            <th style={{ width: '4%' }}>Status</th>
                        </>
                    ) : null}
                </tr>
            </thead>
            <tbody>
                {Object.values(faqAr)?.map((row) => (
                    <tr
                        key={row?.sl}
                        onClick={() => handleUpdate(row?.sl)}
                        className={showNew ? 'active-tr' : null}
                    >
                        <td>{row?.type?.replace('_', ' ').toUpperCase()}</td>
                        <td>{row?.sub_type?.replace('_', ' ').toUpperCase()}</td>
                        {!showNew ? (
                            <>
                                <td>{row?.question}</td>
                                <td>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: row?.answer,
                                        }}
                                    />
                                </td>
                                <td>{row?.total_like}</td>
                                <td>{row?.total_dislike}</td>
                                <td>
                                    {row?.tags?.map((rowT) => (
                                        <span>{rowT}, </span>
                                    ))}
                                </td>
                                <td>{row?.status?.toUpperCase()}</td>
                            </>
                        ) : null}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function ManagesWebsiteFAQ() {
    const [faqAr, setfaqAr] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');

    const faqArSeach =
        searchText?.length > 0
            ? faqAr?.filter((data) => data?.question?.includes(searchText))
            : faqAr;

    const handleClose = () => {
        setShowNew(false);
        setSelectId('');
    };
    const handleUpdate = (e) => {
        setShowNew(true);
        setSelectId(e);
    };

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.FAQ_LIST}`).then((response) => {
            setfaqAr(response.data.dataAr);
        });
    }, [showNew]);

    return (
        <>
            <div className="main-panel" style={{ width: showNew ? '18%' : '100%' }}>
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div
                            className="sidebar-panel-search"
                            style={{ width: showNew ? '86%' : '30%' }}
                        >
                            <input
                                onChange={(e) => setSearchText(e.target.value)}
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search faq"
                                aria-label=""
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>
                    </form>
                    {!showNew ? (
                        <button type="button" onClick={() => setShowNew(true)}>
                            Add New faq
                        </button>
                    ) : null}
                </div>
                <div className="main-panel-body">
                    <FaqListAr faqAr={faqArSeach} handleUpdate={handleUpdate} showNew={showNew} />
                </div>
            </div>
            {showNew ? (
                <ModalFAQ
                    show={showNew}
                    handleClose={handleClose}
                    selectfaq={faqAr?.filter((row) => row.sl === selectId)[0]}
                    selectId={selectId}
                    setSelectId={setSelectId}
                />
            ) : null}
        </>
    );
}

export default ManagesWebsiteFAQ;
