/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

function UserDetailTabs({ activeTab, setActiveTab }) {
    return (
        <div className="user-detail-tab">
            <ul>
                <li
                    className={activeTab === 1 ? 'active-btn' : null}
                    onClick={() => setActiveTab(1)}
                >
                    <button type="button">Overview</button>
                </li>
                <li
                    className={activeTab === 2 ? 'active-btn' : null}
                    onClick={() => setActiveTab(2)}
                >
                    <button type="button">Basic</button>
                </li>
                <li
                    className={activeTab === 3 ? 'active-btn' : null}
                    onClick={() => setActiveTab(3)}
                >
                    <button type="button">Contact</button>
                </li>
                <li
                    className={activeTab === 4 ? 'active-btn' : null}
                    onClick={() => setActiveTab(4)}
                >
                    <button type="button">Address</button>
                </li>
                <li
                    className={activeTab === 5 ? 'active-btn' : null}
                    onClick={() => setActiveTab(5)}
                >
                    <button type="button">Contract</button>
                </li>
                <li
                    className={activeTab === 6 ? 'active-btn' : null}
                    onClick={() => setActiveTab(6)}
                >
                    <button type="button">Property</button>
                </li>
            </ul>
        </div>
    );
}

export default UserDetailTabs;
