const SubTypeData = [
    {
        value: 'web_hosting',
        title: 'Web Hosting',
    },
    {
        value: 'app_hosting',
        title: 'App Hosting',
    },
    {
        value: 'email_hosting',
        title: 'Email Hosting',
    },
];
export default SubTypeData;
