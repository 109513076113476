/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import Autocomplete from '../../../../utils/Autocomplete';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ModalCustomContract({ show, handleClose }) {
    const { setMessage } = useTemplate();
    const [inputValueSl, setInputValueSl] = useState('');
    const [planName, setPlanName] = useState('');
    const [price, setPrice] = useState('');
    const [offerPrice, setOfferPrice] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [duration, setDuration] = useState(12);
    const [description, setDescription] = useState('');
    const [note, setNote] = useState('');
    const [durationTy, setDurationTy] = useState('month');
    const [resDomain, setResDomain] = useState(0);
    const [resDomainQty, setResDomainQty] = useState(1);

    const [resHosting, setResHosting] = useState(0);
    const [resService, setResService] = useState(0);

    const [resHostingQty, setResHostingQty] = useState(1);
    const [resServiceNote, setResServiceNote] = useState('');
    const [resHostingNote, setResHostingNote] = useState('');
    const [resDomainNote, setResDomainNote] = useState('');
    const [resPushNote, setResPushNote] = useState('');
    const [resPush, setResPush] = useState(0);

    const handleInput = (e) => {
        if (e.target.name === 'planName') {
            setPlanName(e.target.value);
        }
        if (e.target.name === 'price') {
            setPrice(e.target.value);
            setOfferPrice(e.target.value);
        }
        if (e.target.name === 'offerPrice') {
            setOfferPrice(e.target.value);
        }

        if (e.target.name === 'createdDate') {
            setCreatedDate(e.target.value);
        }
        if (e.target.name === 'duration') {
            setDuration(e.target.value);
        }
        if (e.target.name === 'description') {
            setDescription(e.target.value);
        }
        if (e.target.name === 'note') {
            setNote(e.target.value);
        }
        if (e.target.name === 'durationTy') {
            setDurationTy(e.target.value);
        }

        if (e.target.name === 'resDomain') {
            if (e.target.checked) {
                setResDomain(1);
            } else {
                setResDomain(0);
            }
        }
        if (e.target.name === 'resDomainQty') {
            setResDomainQty(e.target.value);
        }
        if (e.target.name === 'resHostingQty') {
            setResHostingQty(e.target.value);
        }

        if (e.target.name === 'resHosting') {
            if (e.target.checked) {
                setResHosting(1);
            } else {
                setResHosting(0);
            }
        }
        if (e.target.name === 'resService') {
            if (e.target.checked) {
                setResService(1);
            } else {
                setResService(0);
            }
        }

        if (e.target.name === 'resPush') {
            if (e.target.checked) {
                setResPush(1);
            } else {
                setResPush(0);
            }
        }
        if (e.target.name === 'resServiceNote') {
            setResServiceNote(e.target.value);
        }
        if (e.target.name === 'resHostingNote') {
            setResHostingNote(e.target.value);
        }
        if (e.target.name === 'resDomainNote') {
            setResDomainNote(e.target.value);
        }
        if (e.target.name === 'resPushNote') {
            setResPushNote(e.target.value);
        }
    };

    const handleCreate = () => {
        setMessage('Custom Contract Creating...');

        AxiosAuth.post(ConfigApi.CONTRACT_CUSTOM_CREATE, {
            userSl: inputValueSl,
            planName,
            price,
            offerPrice,
            createdDate,
            duration,
            description,
            note,
            durationTy,
            resDomain,
            resDomainQty,
            resHosting,
            resService,
            resHostingQty,
            resPush,
            resServiceNote,
            resHostingNote,
            resDomainNote,
            resPushNote,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                    setInputValueSl('');
                    setMessage(response.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">Create New Custom Contract:</div>
                <div className="contract-create-input">
                    <label htmlFor="target_link">Select User:</label>
                    <div className="contract-create-input-custom">
                        <Autocomplete setInputValueSl={setInputValueSl} />
                    </div>
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Plan Name:</label>
                    <input
                        type="text"
                        name="planName"
                        value={planName}
                        placeholder="Type Plan Name"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <label htmlFor="n_title">Description:</label>
                    <textarea
                        name="description"
                        value={description}
                        placeholder="Type Description"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <div
                        className={`contract-create-input-chkbox ${resDomain > 0 ? 'ccicn' : ''}`}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            alignItems: 'center',
                        }}
                    >
                        <span>Domain</span>
                        <input
                            type="checkbox"
                            name="resDomain"
                            value={resDomain}
                            onChange={handleInput}
                        />
                        {resDomain > 0 ? (
                            <>
                                <input
                                    type="text"
                                    className="ccin"
                                    value={resDomainQty}
                                    name="resDomainQty"
                                    onChange={handleInput}
                                    placeholder="D Qty"
                                />
                                <input
                                    type="text"
                                    className="ccin"
                                    value={resDomainNote}
                                    name="resDomainNote"
                                    onChange={handleInput}
                                    placeholder="Note"
                                    style={{ width: '280px' }}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <div
                        className={`contract-create-input-chkbox ${resDomain > 0 ? 'ccicn' : ''}`}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            alignItems: 'center',
                        }}
                    >
                        <span>Hosting</span>
                        <input
                            type="checkbox"
                            name="resHosting"
                            value={resHosting}
                            onChange={handleInput}
                        />
                        {resHosting > 0 ? (
                            <>
                                <input
                                    type="text"
                                    className="ccin"
                                    value={resHostingQty}
                                    name="resHostingQty"
                                    onChange={handleInput}
                                    placeholder="H Qty"
                                />
                                <input
                                    type="text"
                                    className="ccin"
                                    value={resHostingNote}
                                    name="resHostingNote"
                                    onChange={handleInput}
                                    placeholder="Note"
                                    style={{ width: '280px' }}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <div
                        className={`contract-create-input-chkbox ${resDomain > 0 ? 'ccicn' : ''}`}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            alignItems: 'center',
                        }}
                    >
                        <span>Service & Support</span>
                        <input
                            type="checkbox"
                            name="resService"
                            value={resService}
                            onChange={handleInput}
                        />
                        {resService > 0 ? (
                            <input
                                type="text"
                                className="ccin"
                                value={resServiceNote}
                                name="resServiceNote"
                                onChange={handleInput}
                                placeholder="Note"
                                style={{ width: '365px' }}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <div
                        className={`contract-create-input-chkbox ${resDomain > 0 ? 'ccicn' : ''}`}
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '40px',
                            alignItems: 'center',
                        }}
                    >
                        <span>Push</span>
                        <input
                            type="checkbox"
                            name="resPush"
                            value={resPush}
                            onChange={handleInput}
                        />
                        {resPush > 0 ? (
                            <input
                                type="text"
                                className="ccin"
                                value={resPushNote}
                                name="resPushNote"
                                onChange={handleInput}
                                placeholder="Note"
                                style={{ width: '365px' }}
                            />
                        ) : null}
                    </div>
                </div>

                <div className="contract-create-input">
                    <label htmlFor="schedule">Created Date:</label>
                    <input
                        name="createdDate"
                        value={createdDate}
                        type="date"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule"> Note:</label>
                    <input
                        name="note"
                        value={note}
                        type="text"
                        className="form-control"
                        onChange={handleInput}
                        placeholder="Type Note"
                    />
                </div>
                <div
                    className="contract-create-input"
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '4%' }}
                >
                    <label style={{ width: '100%' }} htmlFor="schedule">
                        Duration:
                    </label>
                    <input
                        type="number"
                        className="form-input"
                        onChange={handleInput}
                        name="duration"
                        value={duration}
                        style={{ width: '48%' }}
                    />
                    <select
                        className="form-control"
                        onChange={handleInput}
                        value={durationTy}
                        name={durationTy}
                        style={{ width: '48%' }}
                    >
                        <option value="month">Month</option>
                        <option value="Year">Year</option>
                    </select>
                </div>
                <div className="contract-create-input2">
                    <label htmlFor="n_title">Price (T.Du):</label>
                    <input
                        type="text"
                        name="price"
                        value={price}
                        placeholder="In USD Price "
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input2">
                    <label htmlFor="n_title">Offer Price (T.Du):</label>
                    <input
                        type="text"
                        name="offerPrice"
                        value={offerPrice}
                        placeholder="In USD Offer Price"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={handleCreate}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalCustomContract;
