import React, { useEffect, useState } from 'react';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function UserContractDomain({ dataAr }) {
    return (
        <>
            <span>Domain</span>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="10%">Title</th>
                        <th>Start Date</th>
                        <th>Renew Date</th>
                        <th>Duration</th>
                        <th width="15%">Status</th>
                        <th width="15%" style={{ textAlign: 'right', paddingRight: 6 }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataAr?.map((row) => (
                        <tr>
                            <td>{row?.domain_name}</td>
                            <td>{row?.time_issue}</td>
                            <td>{row?.expireDate?.expireDate}</td>
                            <td>{row?.duration} Months</td>
                            <td>{row?.status}</td>
                            <td />
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
function UserContractHosting({ dataAr }) {
    return (
        <>
            <span>Hosting</span>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="10%">Title</th>
                        <th>Start Date</th>
                        <th>Renew Date</th>
                        <th>Duration</th>
                        <th width="15%">Status</th>
                        <th width="15%" style={{ textAlign: 'right', paddingRight: 6 }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataAr?.map((row) => (
                        <tr>
                            <td>{row?.domain_name}</td>
                            <td>{row?.time_issue}</td>
                            <td>{row?.expireDate?.expireDate}</td>
                            <td>{row?.duration} Months</td>
                            <td>{row?.status}</td>
                            <td />
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

function UserContractServer() {
    return (
        <>
            <span>Server</span>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="10%">Title</th>
                        <th>Start Date</th>
                        <th>Renew Date</th>
                        <th>Duration</th>
                        <th width="15%">Status</th>
                        <th width="15%" style={{ textAlign: 'right', paddingRight: 6 }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody />
            </table>
        </>
    );
}

function UserContractSoftware() {
    return (
        <>
            <span>Software</span>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="10%">Title</th>
                        <th>Start Date</th>
                        <th>Renew Date</th>
                        <th>Duration</th>
                        <th width="15%">Status</th>
                        <th width="15%" style={{ textAlign: 'right', paddingRight: 6 }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody />
            </table>
        </>
    );
}

function UserContractEmail() {
    return (
        <>
            <span>Email</span>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="10%">Title</th>
                        <th>Start Date</th>
                        <th>Renew Date</th>
                        <th>Duration</th>
                        <th width="15%">Status</th>
                        <th width="15%" style={{ textAlign: 'right', paddingRight: 6 }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody />
            </table>
        </>
    );
}

function UserContractSms() {
    return (
        <>
            <span>SMS</span>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="10%">Title</th>
                        <th>Start Date</th>
                        <th>Renew Date</th>
                        <th>Duration</th>
                        <th width="15%">Status</th>
                        <th width="15%" style={{ textAlign: 'right', paddingRight: 6 }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody />
            </table>
        </>
    );
}
function UserContractInfo({ userAr }) {
    const [contractAr, setContractAr] = useState([]);

    useEffect(() => {
        AxiosAuth.get(
            `${ConfigApi.CONTRACT_USER_CONTRACT_LIST.replace(':userSl', userAr?.sl)}`
        ).then((response) => {
            setContractAr(response.data.dataAr);
        });
    }, [userAr.sl]);

    return (
        <>
            {contractAr?.domainInfo_ar?.length > 0 ||
            contractAr?.hostingInfo_ar?.length > 0 ||
            contractAr?.serverInfo_ar?.length > 0 ||
            contractAr?.softwareInfo_ar?.length > 0 ||
            contractAr?.emailInfo_ar?.length > 0 ||
            contractAr?.smsInfo_ar?.length > 0 ? (
                <div className="tab-detail">
                    {contractAr?.domainInfo_ar?.length > 0 ? (
                        <UserContractDomain dataAr={contractAr?.domainInfo_ar} />
                    ) : null}
                    {contractAr?.hostingInfo_ar?.length > 0 ? (
                        <UserContractHosting dataAr={contractAr?.hostingInfo_ar} />
                    ) : null}
                    {contractAr?.serverInfo_ar?.length > 0 ? (
                        <UserContractServer dataAr={contractAr?.serverInfo_ar} />
                    ) : null}
                    {contractAr?.softwareInfo_ar?.length > 0 ? (
                        <UserContractSoftware dataAr={contractAr?.softwareInfo_ar} />
                    ) : null}
                    {contractAr?.emailInfo_ar?.length > 0 ? (
                        <UserContractEmail dataAr={contractAr?.emailInfo_ar} />
                    ) : null}
                    {contractAr?.smsInfo_ar?.length > 0 ? (
                        <UserContractSms dataAr={contractAr?.smsInfo_ar} />
                    ) : null}
                </div>
            ) : (
                <div className="tab-detail" style={{ textAlign: 'center' }}>
                    No Contract Found
                </div>
            )}
        </>
    );
}

export default UserContractInfo;
