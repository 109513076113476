import React from 'react';
import IconDomainBg from '../../../../../assets/icon/domain-bg.svg';
import IconEmailBg from '../../../../../assets/icon/email-bg.svg';
import IconHostingBg from '../../../../../assets/icon/hosting-bg.svg';
import IconSmsBg from '../../../../../assets/icon/sms-bg.svg';
import IconUser from '../../../../../assets/images/IconUser.svg';
import ProfileStatus from '../components/ProfileStatus';

function UserOverviewInfo({ userAr }) {
    return (
        <div className="tab-detail">
            <div className="content-section">
                <div className="content-body-scroll user-profile-overview-area">
                    <div className="profile-header line-margin line-align">
                        <div className="cell cell-fill">
                            <div className="line-align">
                                <div className="profile-dp line-row-center mr-3">
                                    <img src={IconUser} alt="" style={{ width: 58 }} />
                                    <span className="completion-value">51%</span>
                                </div>
                                <div>
                                    <div className="font-20">{userAr?.name}</div>
                                    <div className="font-14 opacity-medium">
                                        Joined: {userAr?.joinDate}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cell" style={{ display: 'flex', alignItems: 'center' }}>
                            <ProfileStatus userAr={userAr} />
                        </div>
                    </div>
                    <div className="total-activity-area">
                        <div className="line-align line-g1">
                            <div className="cell cell-4 cell-xl-6 cell-lg-4 cell-md-6 cell-sm-12 mb-2">
                                <div className="list-item count-box">
                                    <div className="line-align">
                                        <div className="cell-fill">
                                            <span className="count-title">Total Invoice</span>
                                        </div>
                                        <div className="cell ">
                                            <span className="count-value invoice invoice">0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cell cell-4 cell-xl-6 cell-lg-4 cell-md-6 cell-sm-12 mb-2">
                                <div className="list-item count-box">
                                    <div className="line-align">
                                        <div className="cell-fill">
                                            <span className="count-title">Total Billed</span>
                                        </div>
                                        <div className="cell ">
                                            <span className="count-value invoice billed">0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cell cell-4 cell-xl-6 cell-lg-4 cell-md-6 cell-sm-12 mb-2">
                                <div className="list-item count-box">
                                    <div className="line-align">
                                        <div className="cell-fill">
                                            <span className="count-title">Total Paid</span>
                                        </div>
                                        <div className="cell ">
                                            <span className="count-value invoice paid">0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cell cell-4 cell-xl-6 cell-lg-4 cell-md-6 cell-sm-12 mb-2">
                                <div className="list-item count-box">
                                    <div className="line-align">
                                        <div className="cell-fill">
                                            <span className="count-title">Total Due</span>
                                        </div>
                                        <div className="cell ">
                                            <span className="count-value invoice due">0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cell cell-4 cell-xl-6 cell-lg-4 cell-md-6 cell-sm-12 mb-2">
                                <div className="list-item count-box">
                                    <div className="line-align">
                                        <div className="cell-fill">
                                            <span className="count-title">Support Ticket</span>
                                        </div>
                                        <div className="cell ">
                                            <span className="count-value invoice s-ticket">0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cell cell-4 cell-xl-6 cell-lg-4 cell-md-6 cell-sm-12 mb-2">
                                <div className="list-item count-box">
                                    <div className="line-align">
                                        <div className="cell-fill">
                                            <span className="count-title">Reward Point</span>
                                        </div>
                                        <div className="cell ">
                                            <span className="count-value invoice r-point">0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="product-info mb-6 profile-info-area">
                        <div>
                            <div className="line-align line-no-wrap mb-2">
                                <div className="cell title fw-400">Personal Info</div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Full Name:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {userAr?.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Email Address:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <div className="line-row-between line-no-wrap">
                                                <span className="value common-value fw-400 cell-fill overflow-dotted ">
                                                    {userAr?.defaultEmail}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                User Name:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {userAr?.username}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Phone Number:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {userAr?.defaultPhone}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-4">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Gender:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {userAr?.gender ? userAr.gender : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Date of birth:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {userAr?.dob ? userAr?.dob : '---'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="line-align line-no-wrap mb-2">
                            <div className="cell title fw-400">Location</div>
                            <hr className="cell-fill cell-self-end ml-1" style={{ opacity: 0.5 }} />
                        </div>
                        <div className="line-align line-no-wrap mb-4">
                            <div className="cell-2 cell-md-5">
                                <span className="title common-value fw-500">Address:</span>
                            </div>
                            <div className="cell-10 cell-md-7">
                                <span className="value common-value fw-400 address-text">--</span>
                            </div>
                        </div>
                        <div className="line-align line-no-wrap mb-2">
                            <div className="cell title fw-400">Social Media</div>
                            <hr className="cell-fill cell-self-end ml-1" style={{ opacity: 0.5 }} />
                        </div>
                        <div className="line-align">
                            <div className="common-social-btn google">
                                {' '}
                                <span className="cell-2" />{' '}
                                <span className="cell-fill">Google</span>{' '}
                            </div>
                            <div className="common-social-btn facebook">
                                {' '}
                                <span className="cell-2" />{' '}
                                <span className="cell-fill">Facebook</span>{' '}
                            </div>
                            <div className="common-social-btn whatsapp">
                                {' '}
                                <span className="cell-2" />{' '}
                                <span className="cell-fill">Whatsapp</span>{' '}
                            </div>
                        </div>
                    </div>
                    <div className="product-info mb-6 service-list-area">
                        <div className="line-align line-no-wrap mb-4">
                            <div className="cell title fw-400">Services List</div>
                            <hr className="cell-fill cell-self-end ml-1" style={{ opacity: 0.5 }} />
                        </div>
                        <div className="service-tab-button">
                            <div className="line-align line-no-wrap justify-start">
                                <button type="button" className="active">
                                    All Service
                                </button>
                            </div>
                        </div>
                        <div className="line-align line-g1">
                            <div className="cell cell-4 cell-xl-6 cell-md-12 mb-2">
                                <div className="list-item line-align">
                                    <div
                                        className="line cell-self-middle"
                                        style={{ maxWidth: '68px' }}
                                    >
                                        <img className="w-100" src={IconDomainBg} alt="" />
                                    </div>
                                    <div className="ml-3 line-col">
                                        <span className="status line-row-center align-unset running">
                                            Running
                                        </span>
                                        <span>Domain Service</span>
                                    </div>
                                </div>
                            </div>
                            <div className="cell cell-4 cell-xl-6 cell-md-12 mb-2">
                                <div className="list-item line-align">
                                    <div
                                        className="line cell-self-middle"
                                        style={{ maxWidth: '68px' }}
                                    >
                                        <img className="w-100" src={IconHostingBg} alt="" />
                                    </div>
                                    <div className="ml-3 line-col">
                                        <span className="status line-row-center align-unset running">
                                            Running
                                        </span>
                                        <span>Hosting Service</span>
                                    </div>
                                </div>
                            </div>
                            <div className="cell cell-4 cell-xl-6 cell-md-12 mb-2">
                                <div className="list-item line-align">
                                    <div
                                        className="line cell-self-middle"
                                        style={{ maxWidth: '68px' }}
                                    >
                                        <img className="w-100" src={IconEmailBg} alt=" " />
                                    </div>
                                    <div className="ml-3 line-col">
                                        <span className="status line-row-center align-unset disable">
                                            Disable
                                        </span>
                                        <span>Email Service</span>
                                    </div>
                                </div>
                            </div>
                            <div className="cell cell-4 cell-xl-6 cell-md-12 mb-2">
                                <div className="list-item line-align">
                                    <div
                                        className="line cell-self-middle"
                                        style={{ maxWidth: '68px' }}
                                    >
                                        <img className="w-100" src={IconSmsBg} alt="" />
                                    </div>
                                    <div className="ml-3 line-col">
                                        <span className="status line-row-center align-unset running">
                                            Running
                                        </span>
                                        <span>Sms Service</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserOverviewInfo;
