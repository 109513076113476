import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Provider } from 'react-redux';
import '../assets/css/bikiran.css';
import '../assets/css/style.css';
import '../assets/css/style.profile.css';
import '../assets/css/style.profile.responsive.css';
import '../assets/css/style.responsive.css';
import TemplateProvider from './contexts/TemplateProvider';

import RouteHandler from './RouteHandler';
import store from './store';
import ComposeProviders from './utils/ComposeProviders';

function App() {
    return (
        <Provider store={store}>
            <ComposeProviders components={[TemplateProvider]}>
                <RouteHandler />
            </ComposeProviders>
        </Provider>
    );
}

export default App;
