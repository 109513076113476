import React, { useEffect, useState } from 'react';

import IconEdit from '../../../../../assets/icon/user-profile-edit-icon-gray.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import Autocomplete from '../../../../utils/Autocomplete';
import AxiosAuth from '../../../../utils/AxiosAuth';

const EditView = ({
    domainDetailAr,
    formData,
    setFormData,
    handelExitEdit,
    handelSubmitForm,
    mode,
}) => (
    <div className={`edit-view-area ${mode === domainDetailAr?.sl ? 'show' : ''}`}>
        <div>
            <div>
                <div className="mb-2">
                    <span className="profile-subject">UserName:</span>
                </div>
                <div className="mb-1">
                    <Autocomplete setInputValueSl={setFormData} renderKey={domainDetailAr?.sl} />
                </div>
                <div className="line line-sm-no-wrap text-start note-text">
                    <span className="value fw-500">Note:</span>
                    &nbsp;
                    <span className="value fw-400">Change user</span>
                </div>
            </div>
            <div className="line justify-end ">
                <button className="cncl-btn" type="button" onClick={handelExitEdit}>
                    Cancel
                </button>
                <button
                    onClick={handelSubmitForm}
                    type="button"
                    className="default-none btn-gr btn-gr-web ml-2"
                    disabled={domainDetailAr?.user_sl === formData.trim()}
                >
                    Save
                </button>
            </div>
        </div>
    </div>
);

function DomainUser({ domainDetailAr, setdomainDetailAr }) {
    const { setMessage } = useTemplate();
    const [formData, setFormData] = useState(domainDetailAr?.user_sl || '');
    const [mode, setMode] = useState('');
    const [userDetailAr, setUserDetailAr] = useState([]);

    const handelClickEdit = (e) => {
        setMode(e);
    };

    const handelExitEdit = () => {
        setMode('');
    };

    const handelSubmitForm = () => {
        setMessage('Updating User ...');
        AxiosAuth.post(ConfigApi.CONTRACT_DOMAIN_SETTING_UPDATE, {
            domainSl: domainDetailAr?.sl,
            type: 'user_sl',
            value: formData,
        }).then((response) => {
            console.log(response);
            setMessage(response?.data.message);
            setMode('');
            setdomainDetailAr(response?.data.dataAr);
        });
    };

    useEffect(() => {
        setUserDetailAr([]);
        AxiosAuth.get(
            `${ConfigApi.USER_SINGLE_INFO.replace(':userSl', domainDetailAr?.user_sl)}`
        ).then((response) => {
            setUserDetailAr(response.data.dataAr?.userInfo);
        });
    }, [formData, domainDetailAr.user_sl]);

    return (
        <>
            <div className="line default-view-area ">
                <div className="cell  cell-3 cell-md-4 od-opacity">
                    <span className="profile-subject">User:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value">{userDetailAr?.name || '--'}</span>
                </div>
                <div className="cell  od-opacity">
                    <button
                        type="button"
                        className="btn-profile-edit"
                        onClick={() => handelClickEdit(domainDetailAr?.sl)}
                    >
                        <img className="w-100 " src={IconEdit} alt="Edit Icon" />
                    </button>
                </div>
            </div>
            <EditView
                domainDetailAr={domainDetailAr}
                userAr={userDetailAr}
                formData={formData}
                setFormData={setFormData}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
            />
        </>
    );
}

export default DomainUser;
