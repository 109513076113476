import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ManagesWebsiteActiveLog() {
    const [activityAr, setActivityAr] = useState([]);

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.ACTIVITY_LOG_LIST}`).then((response) => {
            setActivityAr(response.data.dataAr);
        });
    }, []);

    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search User"
                                aria-label=""
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>

                        <button type="submit">Search</button>
                    </form>
                </div>
                <div className="main-panel-body">
                    <table className="main-panel-body-table mb-3">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Execute Time</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activityAr?.map((row) => (
                                <tr key={row?.sl}>
                                    <td>{row?.sl}</td>
                                    <td>{row?.title}</td>
                                    <td>{row?.description}</td>
                                    <td>{row?.time_executed}</td>
                                    <td>{row?.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default ManagesWebsiteActiveLog;
