/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

function HostingList({ hostingAr }) {
    return (
        <div>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="5%">SL</th>
                        <th width="30%">Title</th>
                        <th width="60%">Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {hostingAr?.custom?.length > 0 ? (
                        <tr>
                            <td colSpan={2} style={{ color: 'green' }}>
                                Custom Hosting
                            </td>
                        </tr>
                    ) : null}
                    {hostingAr?.custom?.map((row) => (
                        <tr key={row?.sl}>
                            <td>{row?.sl}</td>
                            <td>{row?.title}</td>
                            <td>
                                Type : <b>{row?.sub_type}</b>, Domain : <b>{row?.cp_domain},</b>
                                Email : <b>{row?.cp_email},</b> <br />
                                Host : <b>{row?.cp_host},</b>
                                Disk : <b>{row?.disk},</b> Disk Type : <b>{row?.disk_type}</b>
                            </td>
                        </tr>
                    ))}

                    {hostingAr?.notCustom?.length > 0 ? (
                        <tr>
                            <td colSpan={2} style={{ color: 'green' }}>
                                Regular Hosting
                            </td>
                        </tr>
                    ) : null}
                    {hostingAr?.notCustom?.map((row) => (
                        <tr key={row?.sl}>
                            <td>{row?.sl}</td>
                            <td>{row?.title}</td>
                            <td>
                                Type : <b>{row?.sub_type}</b>, Domain : <b>{row?.cp_domain},</b>
                                Email : <b>{row?.cp_email},</b> <br />
                                Host : <b>{row?.cp_host},</b>
                                Disk : <b>{row?.disk},</b> Disk Type : <b>{row?.disk_type}</b>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
function DomainList({ domianAr }) {
    return (
        <div>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="5%">SL</th>
                        <th width="30%">Domain Name</th>
                        <th width="60%">Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {domianAr?.custom?.length > 0 ? (
                        <tr>
                            <td colSpan={2} style={{ color: 'green' }}>
                                Custom Domain
                            </td>
                        </tr>
                    ) : null}
                    {domianAr?.custom?.map((row) => (
                        <tr key={row?.sl}>
                            <td>{row?.sl}</td>
                            <td>{row?.domain_name}</td>
                            <td>Vendor : {row?.vendor}</td>
                        </tr>
                    ))}
                    {domianAr?.notCustom?.length > 0 ? (
                        <tr>
                            <td colSpan={2} style={{ color: 'green' }}>
                                Regular Domain
                            </td>
                        </tr>
                    ) : null}

                    {domianAr?.notCustom?.map((row) => (
                        <tr key={row?.sl}>
                            <td>{row?.sl}</td>
                            <td>{row?.domain_name}</td>
                            <td>Vendor : {row?.vendor}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
function CustomPropertyInfo({ selectId, customAr }) {
    const [showHost, setShowHost] = useState(false);
    const [showDomain, setShowDomain] = useState(false);
    const [hostingAr, setHostingAr] = useState([]);
    const [domianAr, setDomainAr] = useState([]);
    const { message, setMessage } = useTemplate();

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CONTRACT_CUSTOM_PROPERTY.replace(':userSl', customAr?.user_sl)}`)
            .then((response) => {
                setHostingAr(response?.data?.dataAr?.hostingAr);
                setDomainAr(response?.data?.dataAr?.domainAr);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [selectId, showHost, showDomain, message]);

    return (
        <div>
            <div className="tab-detail">
                {hostingAr ? (
                    <HostingList
                        hostingAr={hostingAr}
                        setShowHost={setShowHost}
                        setMessage={setMessage}
                        customAr={customAr}
                    />
                ) : null}
                {domianAr ? (
                    <DomainList
                        domianAr={domianAr}
                        setShowDomain={setShowDomain}
                        setMessage={setMessage}
                        customAr={customAr}
                    />
                ) : null}
            </div>
        </div>
    );
}

export default CustomPropertyInfo;
