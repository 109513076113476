/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ModalUser({ show, handleClose }) {
    const [emailAddress, setEmailAddress] = useState('');
    const [emailAddress2, setEmailAddress2] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [userName, setUserName] = useState('');
    const [fullname, setFullname] = useState('');
    const [note, setNote] = useState('');
    const [errorCode, setErrorCode] = useState(0);
    const focusInput = useRef();
    const [isMoreEmail, setIsMoreEmail] = useState(false);
    const [isMorePhone, setIsMorePhone] = useState(false);

    const handleInput = (e) => {
        if (e.target.name === 'emailAddress') {
            setEmailAddress(e.target.value);
        }
        if (e.target.name === 'emailAddress2') {
            setEmailAddress2(e.target.value);
        }
        if (e.target.name === 'phone') {
            setPhone(e.target.value);
        }

        if (e.target.name === 'organisation') {
            setOrganisation(e.target.value);
        }
        if (e.target.name === 'phone2') {
            setPhone2(e.target.value);
        }
        if (e.target.name === 'userName') {
            setUserName(e.target.value);
        }
        if (e.target.name === 'fullname') {
            setFullname(e.target.value);
        }
        if (e.target.name === 'note') {
            setNote(e.target.value);
        }
    };

    const handleCreateUser = () => {
        AxiosAuth.post(ConfigApi.USER_CREATE, {
            emailAddress,
            phone,
            userName,
            fullname,
            emailAddress2,
            phone2,
            organisation,
            note,
        })
            .then((response) => {
                setErrorCode(response.data.error);

                if (response.data.error === 0) {
                    handleClose();
                } else {
                    focusInput.current.focus();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal size="md" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">Create User:</div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Full Name:</label>
                    <input
                        type="text"
                        name="fullname"
                        value={fullname}
                        placeholder="Type Full Name"
                        onChange={handleInput}
                        onKeyDown={(e) => (e.key === 'Enter' ? handleCreateUser() : null)}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Organisation Name:</label>
                    <input
                        type="text"
                        name="organisation"
                        value={organisation}
                        placeholder="Type Organisation Name"
                        onChange={handleInput}
                        ref={errorCode === 13 ? focusInput : null}
                        onKeyDown={(e) => (e.key === 'Enter' ? handleCreateUser() : null)}
                    />
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">UserName:</label>
                    <input
                        type="text"
                        name="userName"
                        value={userName}
                        placeholder="Type User Name"
                        onChange={handleInput}
                        ref={errorCode === 13 || errorCode === 'userName' ? focusInput : null}
                        onKeyDown={(e) => (e.key === 'Enter' ? handleCreateUser() : null)}
                    />
                    {errorCode === 13 ? <small>Username Already Exists</small> : null}
                    {errorCode === 'userName' ? <small>Email Required</small> : null}
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Note:</label>
                    <input
                        type="text"
                        name="note"
                        value={note}
                        placeholder="Type Note"
                        onChange={handleInput}
                        onKeyDown={(e) => (e.key === 'Enter' ? handleCreateUser() : null)}
                    />
                    {errorCode === 13 ? <small>Username Already Exists</small> : null}
                    {errorCode === 'userName' ? <small>Email Required</small> : null}
                </div>
                <div className="contract-create-input" style={{ position: 'relative' }}>
                    <label htmlFor="target_link">Email Address:</label>
                    <input
                        type="email"
                        name="emailAddress"
                        value={emailAddress}
                        placeholder="Type Email Address"
                        onChange={handleInput}
                        ref={errorCode === 12 || errorCode === 'emailAddress' ? focusInput : null}
                        style={{ paddingRight: '60px' }}
                        onKeyDown={(e) => (e.key === 'Enter' ? handleCreateUser() : null)}
                    />
                    <button type="button" className="more-btn" onClick={() => setIsMoreEmail(true)}>
                        More
                    </button>
                    {errorCode === 12 ? <small>Email Already Exists</small> : null}
                    {errorCode === 'emailAddress' ? <small>Email Required</small> : null}
                </div>
                {isMoreEmail ? (
                    <div className="contract-create-input">
                        <label htmlFor="target_link">Email Address 2:</label>
                        <input
                            type="email"
                            name="emailAddress2"
                            value={emailAddress2}
                            placeholder="Type Email Address"
                            onChange={handleInput}
                            ref={errorCode === 14 ? focusInput : null}
                            onKeyDown={(e) => (e.key === 'Enter' ? handleCreateUser() : null)}
                        />
                        {errorCode === 14 ? <small>Email Already Exists</small> : null}
                    </div>
                ) : null}
                <div className="contract-create-input">
                    <label htmlFor="n_title">Phone Number:</label>
                    <input
                        type="text"
                        name="phone"
                        value={phone}
                        placeholder="Type Phone Number"
                        onChange={handleInput}
                        ref={errorCode === 11 ? focusInput : null}
                        style={{ paddingRight: '60px' }}
                        onKeyDown={(e) => (e.key === 'Enter' ? handleCreateUser() : null)}
                    />
                    <button type="button" className="more-btn" onClick={() => setIsMorePhone(true)}>
                        More
                    </button>
                    {errorCode === 11 ? <small>Phone Already Exists</small> : null}
                </div>
                {isMorePhone ? (
                    <div className="contract-create-input">
                        <label htmlFor="n_title">Phone Number 2:</label>
                        <input
                            type="text"
                            name="phone2"
                            value={phone2}
                            placeholder="Type Phone Number 2"
                            onChange={handleInput}
                            ref={errorCode === 15 ? focusInput : null}
                            onKeyDown={(e) => (e.key === 'Enter' ? handleCreateUser() : null)}
                        />
                        {errorCode === 15 ? <small>Phone Already Exists</small> : null}
                    </div>
                ) : null}
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={handleCreateUser}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalUser;
