import { Modal } from 'react-bootstrap';
import SidebarSection from './SidebarSection';

export default function SidebarMenuDesktop({ show, setShow }) {
    return (
        <Modal
            keyboard={false}
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w sidebar-custom-width modal-dialog-slideout"
            aria-labelledby="example-custom-modal-styling-title"
            size="xl"
            className="modal left sidebar-desktop-modal"
        >
            <div className="modal-content">
                {/* <Modal.Header className="sidebar-desktop-cross">
                    <div className="sidebar-desktop-cross">
                        <button type="button" onClick={() => setShow(false)}>
                            X
                        </button>
                    </div>
                </Modal.Header> */}
                <Modal.Body>
                    <SidebarSection />
                </Modal.Body>
            </div>
        </Modal>
    );
}
