/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ModalCustomDomainSubContract from '../modals/ModalCustomDomainSubContract';
import ModalCustomHostingSubContract from '../modals/ModalCustomHostingSubContract';

function ButtonActionCustomSubHosting({ hostMenu, setHostMenu, provider, setMessage }) {
    const ref = useRef(null);

    const handleClickOption = () => {
        setHostMenu(provider?.sl);
    };
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setHostMenu('');
        }
    };
    const handleRemove = (e) => {
        AxiosAuth.post(ConfigApi.CONTRACT_CUSTOM_SUBCONTRACT_HOSTING_REMOVE, { hostingSl: e })
            .then((response) => {
                setMessage(response?.data?.message);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return (
        <div className="position-relative">
            <div className="line-align justify-end ">
                <span className="common-table-btn">
                    <button
                        className="icon-action-dot-menu default-none purple-variant"
                        type="button"
                        onClick={handleClickOption}
                    />
                </span>
            </div>

            {hostMenu === provider?.sl ? (
                <div className="option-popup" ref={ref}>
                    <ul>
                        <li>
                            <button onClick={() => handleRemove(provider?.sl)} type="button">
                                Delete
                            </button>
                        </li>
                    </ul>
                </div>
            ) : null}
        </div>
    );
}

function ButtonActionCustomSubDomain({ domainMenu, setDomainMenu, provider, setMessage }) {
    const ref = useRef(null);

    const handleClickOption = () => {
        setDomainMenu(provider?.sl);
    };
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setDomainMenu('');
        }
    };
    const handleRemove = (e) => {
        AxiosAuth.post(ConfigApi.CONTRACT_CUSTOM_SUBCONTRACT_DOMAIN_REMOVE, { domainSl: e })
            .then((response) => {
                setMessage(response?.data?.message);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return (
        <div className="position-relative">
            <div className="line-align justify-end ">
                <span className="common-table-btn">
                    <button
                        className="icon-action-dot-menu default-none purple-variant"
                        type="button"
                        onClick={handleClickOption}
                    />
                </span>
            </div>

            {domainMenu === provider?.sl ? (
                <div className="option-popup" ref={ref}>
                    <ul>
                        <li>
                            <button onClick={() => handleRemove(provider?.sl)} type="button">
                                Delete
                            </button>
                        </li>
                    </ul>
                </div>
            ) : null}
        </div>
    );
}

function HostingList({ hostingAr, setShowHost, setMessage, customAr }) {
    const [hostMenu, setHostMenu] = useState(false);

    return (
        <div>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="5%">SL</th>
                        <th width="30%">Title</th>
                        <th width="60%">Detail</th>
                        <th width="5%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {hostingAr?.map((row) => (
                        <tr key={row?.sl}>
                            <td>{row?.sl}</td>
                            <td>{row?.title}</td>
                            <td>
                                Type : <b>{row?.sub_type}</b>, Domain : <b>{row?.cp_domain},</b>
                                Email : <b>{row?.cp_email},</b> <br />
                                Host : <b>{row?.cp_host},</b>
                                Disk : <b>{row?.disk},</b> Disk Type : <b>{row?.disk_type}</b>
                            </td>
                            <td>
                                <ButtonActionCustomSubHosting
                                    hostMenu={hostMenu}
                                    setHostMenu={setHostMenu}
                                    provider={row}
                                    setMessage={setMessage}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="line-align justify-end mt-4 mb-6">
                <div className="cell-sm-fill" style={{ width: '83%' }}>
                    {customAr?.res_hosting_qty > 0
                        ? ` Total Hosting: ${customAr?.res_hosting_qty}`
                        : null}
                    <br />
                    Hosting Note: {customAr?.res_hosting_note}
                </div>
                <div className="cell-sm-fill">
                    <div
                        role="button"
                        tabIndex="-1"
                        className="line-align add-btn-common mb-2 add-btn"
                        onClick={() => setShowHost(true)}
                    >
                        <span className="ml-4">Add Hosting</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
function DomainList({ domianAr, setShowDomain, setMessage, customAr }) {
    const [domainMenu, setDomainMenu] = useState(false);

    return (
        <div>
            <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                <thead>
                    <tr>
                        <th width="5%">SL</th>
                        <th width="30%">Domain Name</th>
                        <th width="60%">Detail</th>
                        <th width="5%" style={{ textAlign: 'right', paddingRight: 6 }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {domianAr?.map((row) => (
                        <tr key={row?.sl}>
                            <td>{row?.sl}</td>
                            <td>{row?.domain_name}</td>
                            <td>Vendor : {row?.vendor}</td>
                            <td>
                                <ButtonActionCustomSubDomain
                                    domainMenu={domainMenu}
                                    setDomainMenu={setDomainMenu}
                                    provider={row}
                                    setMessage={setMessage}
                                />
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td />
                    </tr>
                </tbody>
            </table>
            <div className="line-align justify-end mt-4 mb-6">
                <div className="cell-sm-fill" style={{ width: '83%' }}>
                    {customAr?.res_hosting_qty > 0
                        ? ` Total Domain: ${customAr?.res_domain_qty}`
                        : null}
                    <br />
                    Domain Note: {customAr?.res_domain_note}
                </div>
                <div className="cell-sm-fill">
                    <div
                        role="button"
                        tabIndex="-1"
                        className="line-align add-btn-common mb-2 add-btn"
                        onClick={() => setShowDomain(true)}
                    >
                        <span className="ml-4">Add Domain</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
function CustomSubContractInfo({ selectId, customAr }) {
    const [showHost, setShowHost] = useState(false);
    const [showDomain, setShowDomain] = useState(false);
    const [hostingAr, setHostingAr] = useState([]);
    const [domianAr, setDomainAr] = useState([]);
    const { message, setMessage } = useTemplate();

    const handleClose = () => {
        setShowHost(false);
        setShowDomain(false);
    };

    useEffect(() => {
        AxiosAuth.get(
            `${ConfigApi.CONTRACT_CUSTOM_SUBCONTRACT_LIST.replace(':customSl', selectId)}`
        )
            .then((response) => {
                setHostingAr(response?.data?.hostingAr);
                setDomainAr(response?.data?.domainAr);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [selectId, showHost, showDomain, message]);

    return (
        <div>
            <div className="tab-detail">
                <HostingList
                    hostingAr={hostingAr}
                    setShowHost={setShowHost}
                    setMessage={setMessage}
                    customAr={customAr}
                />
                <DomainList
                    domianAr={domianAr}
                    setShowDomain={setShowDomain}
                    setMessage={setMessage}
                    customAr={customAr}
                />
            </div>
            {showHost ? (
                <ModalCustomHostingSubContract
                    show={showHost}
                    handleClose={handleClose}
                    selectId={selectId}
                />
            ) : null}
            {showDomain ? (
                <ModalCustomDomainSubContract
                    show={showDomain}
                    handleClose={handleClose}
                    selectId={selectId}
                />
            ) : null}
        </div>
    );
}

export default CustomSubContractInfo;
