import React, { useEffect, useState } from 'react';

import IconEdit from '../../../../../assets/icon/user-profile-edit-icon-gray.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

const EditView = ({
    domainDetailAr,
    formData,
    setFormData,
    handelExitEdit,
    handelSubmitForm,
    mode,
    formData2,
    setFormData2,
    formData3,
    setFormData3,
    formData4,
    setFormData4,
}) => {
    const handleUpdate = (ev) => {
        setFormData(ev.target.value);
    };
    const handleUpdate2 = (ev) => {
        setFormData2(ev.target.value);
    };
    const handleUpdate3 = (ev) => {
        setFormData3(ev.target.value);
    };
    const handleUpdate4 = (ev) => {
        setFormData4(ev.target.value);
    };
    return (
        <div className={`edit-view-area ${mode === domainDetailAr?.sl ? 'show' : ''}`}>
            <div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1%' }}>
                    <div style={{ width: '35%' }}>
                        <div className="mb-2">
                            <span className="profile-subject">Duration:</span>
                        </div>
                        <div className="mb-1" style={{ display: 'flex', gap: '2px' }}>
                            <input
                                type="text"
                                className="form-input"
                                onChange={handleUpdate3}
                                value={formData3}
                            />
                            <select
                                className="form-control"
                                name="server"
                                onChange={handleUpdate4}
                                value={formData4}
                            >
                                <option value="month">Month</option>
                                <option value="Year">Year</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ width: '31%' }}>
                        <div className="mb-2">
                            <span className="profile-subject">Price (Total Duration):</span>
                        </div>

                        <div className="mb-1">
                            <input
                                type="text"
                                className="form-input w-100"
                                onChange={handleUpdate}
                                value={formData}
                            />
                        </div>
                    </div>
                    <div style={{ width: '32%' }}>
                        <div className="mb-2">
                            <span className="profile-subject">Offer Price (Total Duration):</span>
                        </div>
                        <div className="mb-1">
                            <input
                                type="text"
                                className="form-input w-100"
                                onChange={handleUpdate2}
                                value={formData2}
                            />
                        </div>
                    </div>

                    <div
                        className="line line-sm-no-wrap text-start note-text"
                        style={{ width: '100%' }}
                    >
                        <span className="value fw-500">Note:</span>
                        &nbsp;
                        <span className="value fw-400">Change Price ,Offer Price & Duration</span>
                    </div>
                </div>
                <div className="line ">
                    <button className="cncl-btn" type="button" onClick={handelExitEdit}>
                        Cancel
                    </button>
                    <button
                        onClick={handelSubmitForm}
                        type="button"
                        className="default-none btn-gr btn-gr-web ml-2"
                        disabled={domainDetailAr?.name === formData.trim()}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

function DomainPrice({ domainDetailAr, setdomainDetailAr }) {
    const { setMessage } = useTemplate();
    const [formData, setFormData] = useState(
        domainDetailAr?.price ? (domainDetailAr?.price * domainDetailAr?.duration).toFixed(2) : ''
    );
    const [formData2, setFormData2] = useState(
        domainDetailAr?.price_offer
            ? (domainDetailAr?.price_offer * domainDetailAr?.duration).toFixed(2)
            : ''
    );
    const [formData3, setFormData3] = useState(domainDetailAr?.duration || '');
    const [formData4, setFormData4] = useState('month');

    const [mode, setMode] = useState('');

    const handelClickEdit = (e) => {
        setMode(e);
    };

    const handelExitEdit = () => {
        setMode('');
    };

    const handelSubmitForm = () => {
        setMessage('Updating Price ...');
        AxiosAuth.post(ConfigApi.CONTRACT_DOMAIN_SETTING_UPDATE_PRICE, {
            domainSl: domainDetailAr?.sl,
            duration: formData3,
            price: formData,
            price_offer: formData2,
            duration_ty: formData4,
        }).then((response) => {
            setMessage(response?.data.message);
            setMode('');
            setdomainDetailAr(response?.data.dataAr);
        });
    };
    useEffect(() => {
        if (domainDetailAr?.price) {
            setFormData((domainDetailAr?.price * domainDetailAr?.duration).toFixed(2));
            setFormData2((domainDetailAr?.price_offer * domainDetailAr?.duration).toFixed(2));
            setFormData3(domainDetailAr?.duration);
        }
    }, [domainDetailAr.duration, domainDetailAr.price, domainDetailAr.price_offer]);

    return (
        <>
            <div className="line default-view-area ">
                <div className="cell  cell-3 cell-md-4 od-opacity">
                    <span className="profile-subject">Price:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value">
                        {domainDetailAr?.price > 0 ? ' $' : ''}
                        {(domainDetailAr?.price * domainDetailAr?.duration).toFixed(2) || '--'}
                    </span>
                </div>
                <div className="cell  cell-3 cell-md-4 od-opacity">
                    <span className="profile-subject">Price Offer:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value">
                        {domainDetailAr?.price_offer > 0 ? ' $' : ''}
                        {(domainDetailAr?.price_offer * domainDetailAr?.duration).toFixed(2) ||
                            '--'}
                    </span>
                </div>
                <div className="cell  cell-3 cell-md-4 od-opacity">
                    <span className="profile-subject">Duration:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value">
                        <span className="value">
                            {(domainDetailAr?.duration > 11
                                ? domainDetailAr?.duration / 12
                                : domainDetailAr?.duration) || '--'}{' '}
                            {domainDetailAr?.duration > 11 ? 'Yr' : 'Month'}
                        </span>{' '}
                    </span>
                </div>
                <div className="cell  od-opacity">
                    <button
                        type="button"
                        className="btn-profile-edit"
                        onClick={() => handelClickEdit(domainDetailAr?.sl)}
                    >
                        <img className="w-100 " src={IconEdit} alt="Edit Icon" />
                    </button>
                </div>
            </div>
            <EditView
                domainDetailAr={domainDetailAr}
                formData={formData}
                setFormData={setFormData}
                formData2={formData2}
                setFormData2={setFormData2}
                formData3={formData3}
                setFormData3={setFormData3}
                formData4={formData4}
                setFormData4={setFormData4}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
            />
        </>
    );
}

export default DomainPrice;
