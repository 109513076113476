import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import IconEmailService from '../../../../../assets/icon/email-service.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

const ModalHeader = ({ handelCloseClick }) => (
    <div className="modal-box-header">
        <div>
            <div className="modal-box-title">Add Email</div>
            <div className="modal-box-close">
                <button className="button squire" type="button" onClick={handelCloseClick} />
            </div>
        </div>
    </div>
);

function ModalBody({ formData, handelEmailInput, handleSubmit }) {
    return (
        <div className="modal-box-body">
            <div>
                <div className="login-modal-area">
                    <div className="line line-g2">
                        <div className="cell cell-12 input-grp mb-3">
                            <div className="input-grp">
                                <div className="label">Email Address</div>
                                <div className="inp-with-icon">
                                    <img src={IconEmailService} alt="Tick" />
                                    <input
                                        type="email"
                                        placeholder="ex: username@email.com"
                                        className="form-input"
                                        value={formData}
                                        onChange={handelEmailInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <button
                            className="button button-gr-pink w-100"
                            type="button"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function AddEmailModal({ userAr, show, handleClose, setProviderAr }) {
    const [formData, setFormData] = useState('');
    const { setMessage } = useTemplate();

    const handelEmailInput = (ev) => {
        setFormData(ev.target.value);
    };
    const handleSubmit = () => {
        setMessage('Creating New Email ...');
        AxiosAuth.post(ConfigApi.USER_ADD_PROVIDER, {
            userSl: userAr?.sl,
            email: formData,
            phone: '',
        }).then((response) => {
            setMessage(response?.data.message);
            handleClose();
            if (response?.data.dataAr) {
                setProviderAr(response?.data.dataAr);
            }
        });
    };
    return (
        <Modal size="sm" show={show} onHide={handleClose} centered>
            <ModalHeader handelCloseClick={handleClose} />

            <ModalBody
                formData={formData}
                setFormData={setFormData}
                handelEmailInput={handelEmailInput}
                handleSubmit={handleSubmit}
            />
        </Modal>
    );
}

export default AddEmailModal;
