import React, { useEffect, useState } from 'react';

import IconEdit from '../../../../../assets/icon/user-profile-edit-icon-gray.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import PackageAutocomplete from '../../../../utils/PackageAutocomplete';

const EditView = ({
    hostDetailAr,
    formData,
    setFormData,
    handelExitEdit,
    handelSubmitForm,
    mode,
    handleIsPrice,
    isChangePrice,
}) => (
    <div className={`edit-view-area ${mode === hostDetailAr?.sl ? 'show' : ''}`}>
        <div>
            <div>
                <div className="mb-2">
                    <span className="profile-subject">
                        Package: (Disk:{hostDetailAr?.disk}, Bandwidth:{hostDetailAr?.bandwidth})
                    </span>
                </div>
                <div className="mb-1">
                    <PackageAutocomplete
                        setInputValueSl={setFormData}
                        renderKey={hostDetailAr?.package_id}
                    />
                    <p style={{ display: 'flex', gap: '10px', color: 'green' }}>
                        Do You Want to Change Price{' '}
                        <input
                            type="checkBox"
                            checked={isChangePrice}
                            onChange={handleIsPrice}
                            style={{ width: '20px', height: '20px', color: 'green' }}
                        />
                    </p>
                </div>
                <div className="line line-sm-no-wrap text-start note-text">
                    <span className="value fw-500">Note:</span>
                    &nbsp;
                    <span className="value fw-400">Change Package</span>
                </div>
            </div>
            <div className="line justify-end ">
                <button className="cncl-btn" type="button" onClick={handelExitEdit}>
                    Cancel
                </button>
                <button
                    onClick={handelSubmitForm}
                    type="button"
                    className="default-none btn-gr btn-gr-web ml-2"
                    disabled={hostDetailAr?.name === formData.trim()}
                >
                    Save
                </button>
            </div>
        </div>
    </div>
);

function HostingPackage({ hostDetailAr, setHostDetailAr }) {
    const { setMessage } = useTemplate();
    const [formData, setFormData] = useState(hostDetailAr?.package_id || '');
    const [mode, setMode] = useState('');
    const [isChangePrice, setIsChangePrice] = useState(0);

    const handelClickEdit = (e) => {
        setMode(e);
    };

    const handelExitEdit = () => {
        setMode('');
    };
    const handleIsPrice = (e) => {
        if (e.target.checked) {
            setIsChangePrice(1);
        } else {
            setIsChangePrice(0);
        }
    };
    const handelSubmitForm = () => {
        setMessage('Updating Package ...');
        AxiosAuth.post(ConfigApi.CONTRACT_HOSTING_SETTING_UPDATE, {
            hostingSl: hostDetailAr?.sl,
            type: 'package_id',
            value: formData,
            isChangePrice,
        }).then((response) => {
            console.log(response);
            setMessage(response?.data.message);
            setMode('');
            setHostDetailAr(response?.data.dataAr);
        });
    };
    useEffect(() => {
        if (hostDetailAr?.package_id) {
            setFormData(hostDetailAr?.package_id);
        }
    }, [hostDetailAr.package_id]);

    return (
        <>
            <div className="line default-view-area ">
                <div className="cell  cell-3 cell-md-4 od-opacity">
                    <span className="profile-subject">Package:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value">{hostDetailAr?.title || '--'}</span>
                </div>
                <div className="cell  od-opacity">
                    <button
                        type="button"
                        className="btn-profile-edit"
                        onClick={() => handelClickEdit(hostDetailAr?.sl)}
                    >
                        <img className="w-100 " src={IconEdit} alt="Edit Icon" />
                    </button>
                </div>
            </div>
            <EditView
                hostDetailAr={hostDetailAr}
                formData={formData}
                setFormData={setFormData}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
                handleIsPrice={handleIsPrice}
                isChangePrice={isChangePrice}
            />
        </>
    );
}

export default HostingPackage;
