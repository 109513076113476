/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import Autocomplete from '../../../../utils/Autocomplete';
import AxiosAuth from '../../../../utils/AxiosAuth';
import PackageAutocomplete from '../../../../utils/PackageAutocomplete';

function ModalHostingContract({ show, handleClose }) {
    const { setMessage } = useTemplate();
    const [inputValueSl, setInputValueSl] = useState('');
    const [domainName, setDomainName] = useState('');
    const [price, setPrice] = useState('');
    const [offerPrice, setOfferPrice] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [duration, setDuration] = useState(12);
    const [durationTy, setDurationTy] = useState('month');

    const [server, setServer] = useState('hostgator');
    const [packageId, setPackageId] = useState('');

    const handleInput = (e) => {
        if (e.target.name === 'domainName') {
            setDomainName(e.target.value);
        }

        if (e.target.name === 'price') {
            setPrice(e.target.value);
            setOfferPrice(e.target.value);
        }
        if (e.target.name === 'offerPrice') {
            setOfferPrice(e.target.value);
        }
        if (e.target.name === 'server') {
            setServer(e.target.value);
        }
        if (e.target.name === 'createdDate') {
            setCreatedDate(e.target.value);
        }
        if (e.target.name === 'duration') {
            setDuration(e.target.value);
        }
        if (e.target.name === 'durationTy') {
            setDurationTy(e.target.value);
        }
    };

    const handleCreate = () => {
        setMessage('Hosting Creating...');

        AxiosAuth.post(ConfigApi.CONTRACT_HOSTING_CREATE, {
            userSl: inputValueSl,
            domainName,
            price,
            offerPrice,
            createdDate,
            duration,
            server,
            packageId,
            durationTy,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                    setInputValueSl('');
                    setMessage(response.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">Create New Hosting Contract:</div>
                <div className="contract-create-input">
                    <label htmlFor="target_link">Select User:</label>
                    <div className="contract-create-input-custom">
                        <Autocomplete setInputValueSl={setInputValueSl} />
                    </div>
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Domain Name:</label>
                    <input
                        type="text"
                        name="domainName"
                        value={domainName}
                        placeholder="Type Domain Name"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input package" style={{ width: '100%' }}>
                    <label htmlFor="n_title">Package:</label>
                    <PackageAutocomplete setInputValueSl={setPackageId} />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Server:</label>
                    <select
                        className="form-control"
                        name="server"
                        value={server}
                        onChange={handleInput}
                    >
                        <option value="hostgator">hostgator</option>
                        <option value="server-3.bikiran.net">server-3.bikiran.net</option>
                        <option value="server6.bikiran.com">server6.bikiran.com</option>
                        <option value="server2.bikiran.com">server2.bikiran.com</option>
                        <option value="server-1.bikiran.net">server-1.bikiran.net</option>
                        <option value="server-2.bikiran.net">server-2.bikiran.net</option>
                        <option value="server-8.bikiran.net">server-8.bikiran.net</option>
                        <option value="server-9.bikiran.net">server-9.bikiran.net</option>
                        <option value="acura.websitewelcome.com">acura.websitewelcome.com</option>
                    </select>
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Created Date:</label>
                    <input
                        name="createdDate"
                        value={createdDate}
                        type="date"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div
                    className="contract-create-input"
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '4%' }}
                >
                    <label style={{ width: '100%' }} htmlFor="schedule">
                        Duration:
                    </label>
                    <input
                        type="number"
                        className="form-input"
                        onChange={handleInput}
                        name="duration"
                        value={duration}
                        style={{ width: '48%' }}
                    />
                    <select
                        className="form-control"
                        onChange={handleInput}
                        value={durationTy}
                        name={durationTy}
                        style={{ width: '48%' }}
                    >
                        <option value="month">Month</option>
                        <option value="Year">Year</option>
                    </select>
                </div>
                <div className="contract-create-input2">
                    <label htmlFor="n_title">Price (T.Du):</label>
                    <input
                        type="text"
                        name="price"
                        value={price}
                        placeholder="In USD Price "
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input2">
                    <label htmlFor="n_title">Offer Price (T.Du):</label>
                    <input
                        type="text"
                        name="offerPrice"
                        value={offerPrice}
                        placeholder="In USD Offer Price"
                        onChange={handleInput}
                    />
                </div>

                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={handleCreate}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalHostingContract;
