/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import '../../../assets/css/print-style.css';
import ConfigApi from '../../../configs/ConfigApi';
import AxiosAuth from '../../utils/AxiosAuth';

function HostingReport({ hostingAr2, totalRowOfCustom }) {
    return (
        <>
            <div>
                <span>Hosting</span>
            </div>
            <table>
                <thead>
                    <th>Domain</th>
                    <th>Username</th>
                    <th>Quota</th>
                    <th>Package</th>
                    <th>CPU & RAM</th>
                    <th>Date Expire</th>
                    <th>Price</th>
                    <th>Price Offer</th>
                    <th>Paid</th>
                    <th>Due</th>
                </thead>
                <tbody>
                    {hostingAr2?.map((row, key) => (
                        <tr key={`h${row?.sl}`}>
                            <td style={{ textAlign: 'left' }}>{row?.cp_domain}</td>
                            <td style={{ textAlign: 'left' }}>{row?.cp_username}</td>
                            <td>{row?.disk}</td>

                            <td style={{ textAlign: 'left' }}>{row?.title}</td>
                            <td>
                                {row?.cpu} Core {row?.ram} MB
                            </td>
                            {row?.parent_sl > 0 ? (
                                totalRowOfCustom - key === totalRowOfCustom ? (
                                    <>
                                        <td
                                            rowSpan={totalRowOfCustom}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {row?.time_expire}
                                        </td>

                                        <td
                                            style={{ textAlign: 'right' }}
                                            rowSpan={totalRowOfCustom}
                                        >
                                            {row?.price}
                                        </td>
                                        <td
                                            style={{ textAlign: 'right' }}
                                            rowSpan={totalRowOfCustom}
                                        >
                                            {row?.price_offer}
                                        </td>
                                        <td rowSpan={totalRowOfCustom}>--</td>
                                        <td rowSpan={totalRowOfCustom}>--</td>
                                    </>
                                ) : null
                            ) : (
                                <>
                                    <td style={{ textAlign: 'center' }}>{row?.time_expire}</td>
                                    <td style={{ textAlign: 'right' }}>{row?.price}</td>
                                    <td style={{ textAlign: 'right' }}>{row?.price_offer}</td>
                                    <td>--</td>
                                    <td>--</td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
function DomainReport({ domainAr, totalRowOfCustom }) {
    return (
        <>
            <div>
                <span>Domain</span>
            </div>
            <table>
                <thead>
                    <th>Domain</th>
                    <th>Vendor</th>
                    <th>Package</th>
                    <th>Date Expire</th>
                    <th>Price</th>
                    <th>Price Offer</th>
                    <th>Paid</th>
                    <th>Due</th>
                </thead>
                <tbody>
                    {domainAr?.map((row, key) => (
                        <tr key={`h${row?.sl}`}>
                            <td style={{ textAlign: 'left' }}>{row?.domain_name}</td>
                            <td>{row?.vendor}</td>
                            <td style={{ textAlign: 'left' }}>{row?.title}</td>

                            {row?.parent_sl > 0 ? (
                                totalRowOfCustom - key === totalRowOfCustom ? (
                                    <>
                                        <td
                                            rowSpan={totalRowOfCustom}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {row?.time_expire}
                                        </td>

                                        <td
                                            style={{ textAlign: 'right' }}
                                            rowSpan={totalRowOfCustom}
                                        >
                                            {row?.price}
                                        </td>
                                        <td
                                            style={{ textAlign: 'right' }}
                                            rowSpan={totalRowOfCustom}
                                        >
                                            {row?.price_offer}
                                        </td>
                                        <td rowSpan={totalRowOfCustom}>--</td>
                                        <td rowSpan={totalRowOfCustom}>--</td>
                                    </>
                                ) : null
                            ) : (
                                <>
                                    <td style={{ textAlign: 'center' }}>{row?.time_expire}</td>
                                    <td style={{ textAlign: 'right' }}>{row?.price}</td>
                                    <td style={{ textAlign: 'right' }}>{row?.price_offer}</td>
                                    <td>--</td>
                                    <td>--</td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
function ManagesUserPropertyPrint() {
    const location = useLocation();
    const userSl = new URLSearchParams(location.search).get('userSl');
    const currencyType = new URLSearchParams(location.search).get('currency');

    const [hostingAr, setHostingAr] = useState([]);
    const [domainAr, setDomainAr] = useState([]);

    const [totalRowOfCustom, setTotalRowOfCustom] = useState(0);
    const [customUserInfo, setCustomUserInfo] = useState('');

    const hostingAr2 = Object.values(hostingAr).sort((item1, item2) => {
        if (item1.parent_sl < item2.parent_sl) {
            return 1;
        }
        if (item1.parent_sl > item2.parent_sl) {
            return -1;
        }
        return 0;
    });
    const domainAr2 = Object.values(domainAr).sort((item1, item2) => {
        if (item1.parent_sl < item2.parent_sl) {
            return 1;
        }
        if (item1.parent_sl > item2.parent_sl) {
            return -1;
        }
        return 0;
    });
    useEffect(() => {
        AxiosAuth.get(
            `${ConfigApi.CONTRACT_CUSTOM_PROPERTY.replace(':userSl', userSl)}?currency=${
                currencyType || ''
            }`
        )
            .then((response) => {
                setHostingAr(response?.data?.reportAr);
                setTotalRowOfCustom(response?.data?.totalRowOfCustom);
                setCustomUserInfo(response?.data?.customUserInfo);
                setDomainAr(response?.data?.reportDomainAr);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currencyType, userSl]);

    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <h2>BIKIRAN.COM</h2>
                <h6>{customUserInfo?.name}</h6>
            </div>
            <HostingReport
                customUserInfo={customUserInfo}
                hostingAr2={hostingAr2}
                totalRowOfCustom={totalRowOfCustom}
            />
            <DomainReport
                customUserInfo={customUserInfo}
                domainAr={domainAr2}
                totalRowOfCustom={totalRowOfCustom}
            />
        </>
    );
}

export default ManagesUserPropertyPrint;
