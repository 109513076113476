import React, { useEffect, useState } from 'react';

import IconReloadGif from '../../assets/images/iconsRefresh.gif';
import IconReloadStatic from '../../assets/images/iconsRefreshStatic.png';

function BtnReload({ setIsReload }) {
    const [img, setImg] = useState(IconReloadStatic);
    const handleReload = () => {
        setIsReload(Math.random());
        setImg(IconReloadGif);
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            setImg(IconReloadStatic);
        }, 7000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <button
            type="button"
            onClick={handleReload}
            style={{ backgroundColor: 'unset', paddingRight: 0 }}
        >
            <img src={img} alt="Reload" />
        </button>
    );
}

export default BtnReload;
