import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function TranListAr({ tranAr }) {
    return (
        <table className="main-panel-body-table mb-3">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Invoice ID</th>
                    <th>Type</th>
                    <th>Local Currency</th>
                    <th>Local Amount</th>
                    <th>USD Rate</th>
                    <th>Notes</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {Object.values(tranAr)?.map((row) => (
                    <tr key={row?.sl}>
                        <td>{row?.sl}</td>
                        <td>{row?.invoiceSl}</td>
                        <td>{row?.type}</td>
                        <td>{row?.local_currency}</td>
                        <td>{row?.local_amount}</td>
                        <td>{row?.usd_rate}</td>
                        <td>{row?.notes}</td>
                        <td>{row?.status}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
function ManagesFinanceTransactionPage() {
    const [tranAr, setTranAr] = useState([]);
    const [searchText, setSearchText] = useState('');

    const tranArSeach =
        searchText?.length > 0 ? tranAr?.filter((data) => data?.sl?.includes(searchText)) : tranAr;

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.FINANCE_TRAN_LIST}`).then((response) => {
            setTranAr(response.data.dataAr);
        });
    }, []);

    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                onChange={(e) => setSearchText(e.target.value)}
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search..."
                                aria-label=""
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>

                        <button type="submit">Search</button>
                    </form>
                </div>
                <div className="main-panel-body">
                    <TranListAr tranAr={tranArSeach} />
                </div>
            </div>
        </>
    );
}

export default ManagesFinanceTransactionPage;
