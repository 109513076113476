import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ContractDomainAutocomplete from '../../../../utils/ContractDomainAutocomplete';

function ModalCustomDomainSubContract({ show, handleClose, selectId }) {
    const { setMessage } = useTemplate();
    const [inputValueSl, setInputValueSl] = useState('');

    const handleSet = () => {
        setMessage('Creating Domain Subcontract..');
        AxiosAuth.post(ConfigApi.CONTRACT_CUSTOM_SUBCONTRACT_DOMAIN_CREATE, {
            domainSl: inputValueSl,
            customSl: selectId,
        })
            .then((response) => {
                handleClose();
                setInputValueSl('');
                setMessage(response?.data.message);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal size="md" show={show} onHide={handleClose}>
            <Modal.Body>
                <h5>Attached a Domian</h5>
                <ContractDomainAutocomplete setInputValueSl={setInputValueSl} />

                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={handleClose}
                        style={{ color: 'red', fontWeight: 'bold' }}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={handleSet}
                        style={{ color: '#cc00ff', fontWeight: 'bold' }}
                    >
                        Set As SubContract
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalCustomDomainSubContract;
