import React from 'react';
import HostingCreatedDate from '../components/HostingCreatedDate';
import HostingDomain from '../components/HostingDomain';
import HostingExpiredDate from '../components/HostingExpiredDate';
import HostingPackage from '../components/HostingPackage';
import HostingPrice from '../components/HostingPrice';
import HostingProperty from '../components/HostingProperty';
import HostingServer from '../components/HostingServer';
import HostingUser from '../components/HostingUser';

function HostingBasicSettingInfo({ selectId, hostDetailAr, setHostDetailAr }) {
    return (
        <div className="tab-detail">
            <div className="content-body-scroll user-profile-area">
                <div className="product-info mb-6 user-profile-info-area">
                    <div>
                        <div className="line mb-5 user-personal-info-area">
                            <div className="cell cell-12 cell-md-12 cell-sm-12">
                                <HostingUser
                                    hostDetailAr={hostDetailAr}
                                    setHostDetailAr={setHostDetailAr}
                                    key="user"
                                    selectId={selectId}
                                />
                                <HostingDomain
                                    hostDetailAr={hostDetailAr}
                                    setHostDetailAr={setHostDetailAr}
                                    key="domain"
                                />
                                <HostingPackage
                                    hostDetailAr={hostDetailAr}
                                    setHostDetailAr={setHostDetailAr}
                                    key="package"
                                />
                                <HostingServer
                                    hostDetailAr={hostDetailAr}
                                    setHostDetailAr={setHostDetailAr}
                                    key="server"
                                />
                                <HostingPrice
                                    hostDetailAr={hostDetailAr}
                                    setHostDetailAr={setHostDetailAr}
                                    key="price"
                                />

                                <HostingCreatedDate
                                    hostDetailAr={hostDetailAr}
                                    setHostDetailAr={setHostDetailAr}
                                    key="date"
                                />
                                <HostingExpiredDate
                                    hostDetailAr={hostDetailAr}
                                    setHostDetailAr={setHostDetailAr}
                                    key="es_date"
                                />
                                <HostingProperty
                                    hostDetailAr={hostDetailAr}
                                    setHostDetailAr={setHostDetailAr}
                                    key="property"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HostingBasicSettingInfo;
