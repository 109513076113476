import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function InvoiceItemList({ invoiceItemAr }) {
    return (
        <table className="main-panel-body-table mb-3">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Vendor</th>
                    <th>Title</th>
                    <th>Duration</th>
                    <th>Price</th>
                    <th>Info</th>
                    <td>Date</td>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {Object.values(invoiceItemAr)?.map((row) => (
                    <tr key={row?.sl}>
                        <td>{row?.sl}</td>
                        <td>
                            {row?.service_mode}
                            <br />
                            {row?.type}
                        </td>
                        <td>{row?.vendor}</td>
                        <td>{row?.title}</td>
                        <td>{row?.duration} Months</td>
                        <td>
                            {(parseFloat(row?.price, 10) * parseFloat(row?.duration, 10))?.toFixed(
                                2
                            )}
                            <br />
                            {(
                                parseFloat(row?.price_offer, 10) * parseFloat(row?.duration, 10)
                            )?.toFixed(2)}
                        </td>
                        <td>{row?.cart_property?.domain}</td>
                        <td>
                            {row?.subscription_start} <br />
                            {row?.subscription_end}
                        </td>
                        <td>{row?.status}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function InvoiceListAr({ invoiceAr, detailId, setDetailId }) {
    return (
        <table className="main-panel-body-table mb-3">
            <thead>
                <tr>
                    <th>INVOICE ID</th>
                    <th>User</th>
                    {!detailId ? (
                        <>
                            <th>Currency</th>
                            <th>Payment Status</th>
                            <th>Payment Time</th>
                            <th>Time Issue</th>
                            <td>Time Due</td>
                            <th>Status</th>
                        </>
                    ) : null}
                </tr>
            </thead>
            <tbody>
                {Object.values(invoiceAr)?.map((row) => (
                    <tr
                        key={row?.sl}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: row?.sl === detailId ? '#f2d6f4' : null,
                        }}
                        onClick={() => setDetailId(row.sl)}
                    >
                        <td>INV{row?.sl}</td>
                        <td>{row?.user_name}</td>
                        {!detailId ? (
                            <>
                                <td>{row?.local_currency}</td>
                                <td>{row?.payment_status}</td>
                                <td>{row?.payment_time}</td>
                                <td>{row?.time_issue}</td>
                                <td>{row?.time_due}</td>
                                <td>{row?.status}</td>
                            </>
                        ) : null}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function InvoiceDetails({ detailId, setDetailId }) {
    const [invoiceItemAr, setInvoiceItemAr] = useState([]);
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CONTRACT_INVOICE_DETAILS.replace(':invoiceId', detailId)}`).then(
            (response) => {
                setInvoiceItemAr(response.data.dataAr);
            }
        );
    }, [detailId]);

    return (
        <div className="main-panel" style={{ width: '67%' }}>
            <div className="main-panel-header">
                <div className="main-panel-title" style={{ width: '100%' }}>
                    <span style={{ flex: 1 }}>Invoice Details</span>
                    <button
                        type="button"
                        onClick={() => setDetailId('')}
                        style={{ color: 'white', backgroundColor: 'red' }}
                    >
                        Close
                    </button>
                    <Link
                        to={`/invoice/${detailId}/Print/`}
                        style={{ color: 'white', backgroundColor: 'red' }}
                    >
                        Print
                    </Link>
                </div>
            </div>
            <div className="main-panel-body">
                <InvoiceItemList invoiceItemAr={invoiceItemAr} />
            </div>
        </div>
    );
}

function ManagesInvoicePage() {
    const [invoiceAr, setInvoiceAr] = useState([]);
    const [detailId, setDetailId] = useState('');
    const [searchText, setSearchText] = useState('');

    const invoiceArSeach =
        searchText.length > 0
            ? invoiceAr?.filter((data) => data?.sl?.includes(searchText))
            : invoiceAr;

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CONTRACT_INVOICE_LIST}`).then((response) => {
            setInvoiceAr(response.data.dataAr);
        });
    }, []);

    return (
        <>
            <div className="main-panel" style={{ width: detailId > 0 ? '29%' : '100%' }}>
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div
                            className="sidebar-panel-search"
                            style={{ width: detailId ? '50%' : '' }}
                        >
                            <input
                                onChange={(e) => setSearchText(e.target.value)}
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search Invoice"
                                aria-label=""
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>

                        <button type="submit">Search</button>
                    </form>
                </div>
                <div className="main-panel-body">
                    <InvoiceListAr
                        invoiceAr={invoiceArSeach}
                        detailId={detailId}
                        setDetailId={setDetailId}
                    />
                </div>
            </div>
            {detailId ? <InvoiceDetails detailId={detailId} setDetailId={setDetailId} /> : null}
        </>
    );
}

export default ManagesInvoicePage;
