/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import BtnReload from '../../../../utils/BtnReload';
import ModalhostingAssignUser from '../modals/ModalhostingAssignUser';
import ModalHostingContract from '../modals/ModalHostingContract';
import HostingBasicSettingInfo from '../section/HostingBasicSettingInfo';
import HostingDetailTabs from '../section/HostingDetailTabs';
import HostingOverviewInfo from '../section/HostingOverviewInfo';

function HostingDetail({ selectId, setSelectId }) {
    const [activeTab, setActiveTab] = useState(1);
    const [hostDetailAr, setHostDetailAr] = useState([]);
    const { message, setMessage } = useTemplate();

    useEffect(() => {
        AxiosAuth.get(
            `${ConfigApi.CONTRACT_HOSTING_SINGLE_INFO.replace(':hostingSl', selectId)}`
        ).then((response) => {
            setHostDetailAr(response.data.dataAr);
        });
    }, [message, selectId, setMessage]);

    return (
        <div className="main-panel" style={{ width: '68%' }}>
            <div className="main-panel-header">
                <div className="main-panel-title" style={{ width: '100%' }}>
                    <span style={{ flex: '1 1 0%' }}>
                        Hosting Details <br />
                        <small style={{ fontSize: 13, color: '#AE00B9' }}>
                            {hostDetailAr?.cp_domain}, {hostDetailAr?.title}
                        </small>
                    </span>
                </div>
                <button
                    type="button"
                    onClick={() => setSelectId('')}
                    style={{ color: 'white', backgroundColor: 'red' }}
                >
                    Close
                </button>
            </div>
            <div className="main-panel-body">
                <HostingDetailTabs activeTab={activeTab} setActiveTab={setActiveTab} />
                {activeTab === 1 ? <HostingOverviewInfo hostDetailAr={hostDetailAr} /> : null}
                {activeTab === 2 ? (
                    <HostingBasicSettingInfo
                        selectId={selectId}
                        hostDetailAr={hostDetailAr}
                        setHostDetailAr={setHostDetailAr}
                    />
                ) : null}
            </div>
        </div>
    );
}
function ManagesHostingPage() {
    const { message, setMessage } = useTemplate();
    const [hostingAr, setHostingAr] = useState([]);
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');
    const [searchText, setSearchText] = useState('');
    const [isStar, setIsStar] = useState('');
    const [isCustom, setIsCustom] = useState('');
    const [isReload, setIsReload] = useState('');

    const hostingSearchAr2 = hostingAr?.filter(
        (item) =>
            item.user_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.domain_name?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.cp_host?.toLowerCase().includes(searchText?.toLowerCase())
    );
    const hostingSearchAr3 = hostingSearchAr2?.filter((item) =>
        item.is_starred?.toLowerCase().includes(isStar?.toLowerCase())
    );
    const hostingSearchAr = hostingSearchAr3?.filter((item) =>
        item.is_sub_contract?.toLowerCase().includes(isCustom?.toLowerCase())
    );
    const handleClose = () => {
        setShow(false);
        setShowNew(false);
        setSelectId('');
    };
    const handleClick = (e) => {
        setSelectId(e);
        setShow(true);
    };
    const handleStarred = (e) => {
        AxiosAuth.post(`${ConfigApi.CONTRACT_HOSTING_STARRED.replace(':hostingSl', e)}`).then(
            (response) => {
                setMessage(response.data.message);
            }
        );
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CONTRACT_HOSTING_LIST}`).then((response) => {
            setHostingAr(response.data.dataAr);
        });
    }, [show, showNew, message, isReload, setMessage]);

    return (
        <>
            <div className="main-panel" style={{ width: selectId ? '29%' : '' }}>
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div
                            className="sidebar-panel-search"
                            style={{ width: selectId ? '50%' : '' }}
                        >
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search Hosting"
                                aria-label=""
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>
                        <select
                            className="sidebar-panel-search-select"
                            style={{ width: selectId ? '25%' : '10%' }}
                            onChange={(e) => setIsCustom(e.target.value)}
                            value={isCustom}
                        >
                            <option value="">All</option>
                            <option value="1">Is Custom</option>
                            <option value="0">Not Custom</option>
                        </select>
                        <select
                            className="sidebar-panel-search-select"
                            style={{ width: selectId ? '25%' : '10%' }}
                            onChange={(e) => setIsStar(e.target.value)}
                            value={isStar}
                        >
                            <option value="">All</option>
                            <option value="1">Star</option>
                            <option value="0">Not Star</option>
                        </select>
                    </form>
                    <BtnReload setIsReload={setIsReload} isReload={isReload} />
                    {!selectId ? (
                        <button type="button" onClick={() => setShowNew(true)}>
                            Add New Contact
                        </button>
                    ) : null}
                </div>
                <div className="main-panel-body">
                    <div className="scroll-table-container">
                        <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                            <thead>
                                <tr>
                                    {!selectId ? <th /> : null}
                                    <th width="5%">SL</th>
                                    {!selectId ? (
                                        <>
                                            <th width="15%">User</th>
                                            <th width="15%">Title</th>
                                            <th width="15%">Server</th>
                                            <th width="15%">Domain</th>
                                            <th width="15%">Email</th>
                                            <th width="5%">Price</th>
                                            <th width="7%">Expired</th>
                                            <th width="5%">Status</th>
                                        </>
                                    ) : (
                                        <th>Info</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {hostingSearchAr?.map((row) => (
                                    <tr
                                        key={row?.sl}
                                        className={selectId === row?.sl ? 'active-tr' : null}
                                    >
                                        {!selectId ? (
                                            <td>
                                                <button
                                                    type="button"
                                                    className={`item-starred ${
                                                        row?.is_starred === '1'
                                                            ? 'item-starred-active'
                                                            : null
                                                    }`}
                                                    onClick={() => handleStarred(row?.sl)}
                                                />
                                            </td>
                                        ) : null}

                                        <td onClick={() => setSelectId(row?.sl)}>{row?.sl}</td>
                                        {!selectId ? (
                                            <>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.parent_sl > 0 ? (
                                                        <>
                                                            <span
                                                                style={{
                                                                    color: 'green',
                                                                    paddingLeft: 5,
                                                                }}
                                                            >
                                                                {row?.parentUser}
                                                            </span>
                                                            <br />
                                                            <span
                                                                style={{
                                                                    color: 'green',
                                                                    paddingLeft: 5,
                                                                }}
                                                            >
                                                                <small> {row?.parentEmail}</small>
                                                            </span>
                                                        </>
                                                    ) : row?.user_sl > 0 ? (
                                                        <>
                                                            <span style={{ paddingLeft: 5 }}>
                                                                {row?.user_name}
                                                            </span>
                                                            <br />
                                                            <span style={{ paddingLeft: 5 }}>
                                                                <small> {row?.user_email}</small>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            style={{
                                                                background: 'unset',
                                                                border: 'unset',
                                                            }}
                                                            onClick={() => handleClick(row?.sl)}
                                                        >
                                                            Not Set
                                                        </button>
                                                    )}
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.parentUser ? `${row?.customTitle} ` : ''}

                                                    {row?.parentUser ? <br /> : ''}
                                                    {row?.title}
                                                </td>

                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.cp_host}
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.domain_name}
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.cp_email}
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    ${row?.price?.toFixed(2)}
                                                </td>
                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.expireDate}
                                                </td>

                                                <td onClick={() => setSelectId(row?.sl)}>
                                                    {row?.status}
                                                </td>
                                            </>
                                        ) : (
                                            <td onClick={() => setSelectId(row?.sl)}>
                                                {row?.parent_sl > 0 ? (
                                                    <>
                                                        <span
                                                            style={{
                                                                color: 'green',
                                                            }}
                                                        >
                                                            {row?.parentUser}
                                                        </span>
                                                        <br />
                                                        <span
                                                            style={{
                                                                color: 'green',
                                                            }}
                                                        >
                                                            <small> {row?.parentEmail}</small>
                                                        </span>
                                                    </>
                                                ) : row?.user_sl > 0 ? (
                                                    <>
                                                        <span>{row?.user_name}</span>
                                                        <br />
                                                        <span>
                                                            <small> {row?.user_email}</small>
                                                        </span>
                                                    </>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        style={{
                                                            background: 'unset',
                                                            border: 'unset',
                                                        }}
                                                        onClick={() => handleClick(row?.sl)}
                                                    >
                                                        Not Set
                                                    </button>
                                                )}
                                                <br />

                                                <span> {row?.title}</span>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {show ? (
                    <ModalhostingAssignUser
                        show={show}
                        handleClose={handleClose}
                        selectId={selectId}
                        setSelectId={setSelectId}
                    />
                ) : null}

                {showNew ? (
                    <ModalHostingContract
                        show={showNew}
                        handleClose={handleClose}
                        hostingAr={hostingAr}
                    />
                ) : null}
            </div>
            {selectId ? (
                <HostingDetail
                    selectId={selectId}
                    setSelectId={setSelectId}
                    hostingAr={hostingAr}
                />
            ) : null}
        </>
    );
}

export default ManagesHostingPage;
