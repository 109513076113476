import React, { useEffect, useState } from 'react';
import EditIcon from '../../../../../assets/images/editicon.svg';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ModalDomainAssignUser from '../modals/ModalDomainAssignUser';
import ModalDomainContract from '../modals/ModalDomainContract';

function ManagesSoftwarePage() {
    const [domainAr, setDomainAr] = useState([]);
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');

    const handleClose = () => {
        setShow(false);
        setShowNew(false);
    };
    const handleClick = (e) => {
        setSelectId(e);
        setShow(true);
    };
    const handleExecute = (e) => {
        console.log(e);
    };
    const handleUpdate = (e) => {
        setShowNew(true);
        setSelectId(e);
    };

    const handleRenew = (e) => {
        console.log(e);
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CONTRACT_DOMAIN_LIST}`).then((response) => {
            setDomainAr(response.data.dataAr);
        });
    }, [show, showNew]);
    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search..."
                                aria-label=""
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>

                        <button type="submit">Search</button>
                    </form>
                    <button type="button" onClick={() => setShowNew(true)}>
                        Add New Contact
                    </button>
                </div>
                <div className="main-panel-body">
                    <div className="scroll-table-container">
                        <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>User</th>
                                    <th>Vendor</th>
                                    <th>Date Created</th>
                                    <th>Expired</th>
                                    <th>Domain</th>
                                    <th>Price</th>
                                    <td>Executed</td>
                                    <td>Renew</td>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {domainAr?.map((row) => (
                                    <tr key={row?.sl}>
                                        <td>D{row?.sl}</td>
                                        <td>
                                            {row?.user_sl > 0 ? (
                                                row?.user_name
                                            ) : (
                                                <button
                                                    type="button"
                                                    style={{ background: 'unset', border: 'unset' }}
                                                    onClick={() => handleClick(row?.sl)}
                                                >
                                                    Not Set
                                                </button>
                                            )}
                                        </td>
                                        <td>{row?.vendor}</td>
                                        <td>{row?.time_issue}</td>

                                        <td>
                                            {row?.expireDate?.dueDate <= 31
                                                ? `${row?.expireDate?.dueDate} Days Left`
                                                : row?.expireDate?.expireDate}
                                            <br />
                                        </td>
                                        <td>
                                            {row?.domain_name}
                                            <br />
                                            <div className="table-external-icon">
                                                <a
                                                    target="_blank"
                                                    href={`https://www.whois.com/whois/${row?.domain_name}`}
                                                    rel="noreferrer"
                                                >
                                                    Whois
                                                </a>{' '}
                                                ||{' '}
                                                <a
                                                    target="_blank"
                                                    href={`https://www.whatsmydns.net/#A/${row?.domain_name}`}
                                                    rel="noreferrer"
                                                >
                                                    DNS Zone
                                                </a>{' '}
                                                ||{' '}
                                                <a
                                                    target="_blank"
                                                    href={`https://dnschecker.org/domain-health-checker.php?query=${row?.domain_name}`}
                                                    rel="noreferrer"
                                                >
                                                    DNS Health
                                                </a>{' '}
                                                ||{' '}
                                                <a
                                                    target="_blank"
                                                    href={`https://dnschecker.org/mx-lookup.php?query=${row?.domain_name}`}
                                                    rel="noreferrer"
                                                >
                                                    MX Lookup
                                                </a>
                                            </div>
                                        </td>
                                        <td>${row?.price?.toFixed(2)}</td>
                                        <td>
                                            {row?.time_executed ? (
                                                row?.time_executed
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="execute-btn"
                                                    onClick={handleExecute}
                                                >
                                                    Execute Now
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                className="renew-btn"
                                                onClick={handleRenew}
                                            >
                                                Renew
                                            </button>
                                        </td>

                                        <td>{row?.status}</td>
                                        <td className="action-area">
                                            <button
                                                type="button"
                                                onClick={() => handleUpdate(row?.sl)}
                                            >
                                                <img src={EditIcon} alt="Edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <ModalDomainAssignUser
                    show={show}
                    handleClose={handleClose}
                    selectId={selectId}
                    setSelectId={setSelectId}
                />
                {showNew ? (
                    <ModalDomainContract
                        show={showNew}
                        handleClose={handleClose}
                        selectDomain={domainAr?.filter((row) => row.sl === selectId)[0]}
                        selectId={selectId}
                    />
                ) : null}
            </div>
        </>
    );
}

export default ManagesSoftwarePage;
