import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import Iconbar from '../../../assets/images/IconBar.svg';
import SidebarMenuDesktop from './SidebarMenuDesktop';

const titleData = {
    '/manage/dashboard/': 'Dashboard',
    '/manage/support/': 'Support',
    '/manage/cart/': 'Cart',
    '/manage/contract/support/': 'Support',
    '/manage/contract/domain/': 'Domain',
    '/manage/contract/hosting/': 'Hosting',
    '/manage/contract/server/': 'Server',
    '/manage/contract/custom-contract/': 'Custom Contract',
    '/manage/contract/software-subscription/': 'Software Subscription',
    '/manage/invoice/': 'Invoice',
    '/manage/finance/': 'Finance',
    '/manage/finance-currencies/': 'Finance Currencies',
    '/manage/finance-gateway/': 'Finance Gateway',
    '/manage/user/': 'User',
    '/manage/logs/': 'Logs',
    '/manage/reports/': 'Reports',
    '/manage/message/': 'Message',
    '/manage/Reports/': 'Reports',
    '/manage/software-subscription/': 'Software Subscription',
    '/manage/website/activity-log/': 'Activity Log',
    '/manage/website/client/': 'Client List',
    '/manage/domian-price/': 'Domian Price',
    '/manage/finance/transaction/': 'Finance Transaction',
    '/manage/setting/services/': 'Services',
    '/manage/setting/package/': 'Packages',
    '/manage/cpanel-vendor/': 'Cpanel Vendor',
};
function HeaderSection() {
    const { pathname } = useLocation();
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(false);
    }, [pathname]);

    return (
        <>
            <div className="main-stricture-header">
                <button type="button" id="sideMenu" onClick={() => setShow(true)}>
                    <img src={Iconbar} alt="" />
                </button>
                <p className="main-stricture-header-p">{titleData[pathname]}</p>
            </div>
            {show ? <SidebarMenuDesktop show={show} setShow={setShow} /> : null}
        </>
    );
}

export default HeaderSection;
