/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

function HostingDetailTabs({ activeTab, setActiveTab }) {
    return (
        <div className="user-detail-tab">
            <ul>
                <li
                    className={activeTab === 1 ? 'active-btn' : null}
                    onClick={() => setActiveTab(1)}
                >
                    <button type="button">Overview Info</button>
                </li>
                <li
                    className={activeTab === 2 ? 'active-btn' : null}
                    onClick={() => setActiveTab(2)}
                >
                    <button type="button">Basic Info</button>
                </li>
                <li
                    className={activeTab === 3 ? 'active-btn' : null}
                    onClick={() => setActiveTab(3)}
                >
                    <button type="button">Billing Info</button>
                </li>
            </ul>
        </div>
    );
}

export default HostingDetailTabs;
