/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ModalOtpEmailNotify({ show, handleClose, selectConfig }) {
    const [keyName, setKeyName] = useState(selectConfig?.key_name || '');
    const [templatePath, setTemplatePath] = useState(selectConfig?.template_path || '');
    const [templateHtml, setTemplateHtml] = useState(selectConfig?.template_html || '');

    const handleInput = (e) => {
        if (e.target.name === 'keyName') {
            setKeyName(e.target.value);
        }
        if (e.target.name === 'templatePath') {
            setTemplatePath(e.target.value);
        }
        if (e.target.name === 'templateHtml') {
            setTemplateHtml(e.target.value);
        }
    };
    const handleCreateClient = () => {
        AxiosAuth.post(ConfigApi.NOTIFY_EMAIL_OTP_CREATE, {
            keyName,
            templatePath,
            templateHtml,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                }
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleUpdateClient = () => {
        AxiosAuth.post(ConfigApi.NOTIFY_EMAIL_OTP_UPDATE, {
            keyName,
            templatePath,
            templateHtml,
            configSl: selectConfig?.sl,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                }
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">
                    {selectConfig?.sl ? 'Update Client:' : 'Create New Email OTP Config:'}
                </div>

                <div className="contract-create-input" style={{ width: '100%' }}>
                    <label htmlFor="n_title">Key Name:</label>
                    <input
                        type="text"
                        name="keyName"
                        value={keyName}
                        placeholder="Type Key Name"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <label htmlFor="n_title">Template Path:</label>
                    <textarea
                        name="templatePath"
                        value={templatePath}
                        placeholder="Type Template Path"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input" style={{ width: '100%' }}>
                    <label htmlFor="n_title">Template Html:</label>
                    <textarea
                        name="templateHtml"
                        value={templateHtml}
                        placeholder="Type Template HTML"
                        onChange={handleInput}
                    />
                </div>

                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={selectConfig?.sl ? handleUpdateClient : handleCreateClient}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalOtpEmailNotify;
