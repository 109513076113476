/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import CrossIcon from '../../assets/images/IconCross.svg';
import ConfigApi from '../../configs/ConfigApi';
import AxiosAuth from './AxiosAuth';

const SuggestionsList = (props) => {
    const { suggestions, inputValue, onSelectSuggestion, displaySuggestions, selectedSuggestion } =
        props;

    if (inputValue && displaySuggestions) {
        if (suggestions?.length > 0) {
            return (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => {
                        const isSelected = selectedSuggestion === index;
                        const classname = `suggestion ${isSelected ? 'selected' : ''}`;
                        return (
                            <li
                                key={suggestion?.sl}
                                className={classname}
                                onClick={() => onSelectSuggestion(suggestion)}
                            >
                                {suggestion?.domain_name}
                            </li>
                        );
                    })}
                </ul>
            );
        }
        return (
            <ul className="suggestions-list">
                <li>No suggestions available...</li>
            </ul>
        );
    }
    return <></>;
};
function ContractDomainAutocomplete({ setInputValueSl = null, renderKey = null }) {
    const [inputValue, setInputValue] = useState(null);

    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState(0);
    const [displaySuggestions, setDisplaySuggestions] = useState(false);
    const handleCancle = () => {
        setInputValueSl('');
        setSelectedSuggestion(0);
        setFilteredSuggestions([]);
        setDisplaySuggestions(false);
        setInputValue(null);
    };
    const onChange = (event) => {
        const { value } = event.target;
        setInputValue(value);

        AxiosAuth.post(ConfigApi.CONTRACT_DOMAIN_SEARCH, {
            domainData: value,
        }).then((response) => {
            setFilteredSuggestions(response.data.dataAr);
        });

        setDisplaySuggestions(true);
    };
    const onSelectSuggestion = (index) => {
        setSelectedSuggestion(index);
        setInputValue(index?.name);
        setInputValueSl(index?.sl);
        setFilteredSuggestions([]);
        setDisplaySuggestions(false);
    };
    useEffect(() => {
        setSelectedSuggestion(0);
        setFilteredSuggestions([]);
        setDisplaySuggestions(false);
        setInputValue(null);
    }, [renderKey]);
    return (
        <>
            <div className="modal-input">
                {!selectedSuggestion?.sl ? (
                    <input
                        className="user-input"
                        type="text"
                        onChange={onChange}
                        value={inputValue}
                        placeholder="Type Domain Title or Type"
                    />
                ) : (
                    <>
                        <div className="modal-input-show">
                            <div className="modal-input-show-text">
                                {selectedSuggestion?.domain_name}
                            </div>

                            <button type="button" onClick={handleCancle}>
                                <img src={CrossIcon} alt="cross" />
                            </button>
                        </div>
                    </>
                )}
            </div>
            <SuggestionsList
                inputValue={inputValue}
                selectedSuggestion={selectedSuggestion}
                onSelectSuggestion={onSelectSuggestion}
                displaySuggestions={displaySuggestions}
                suggestions={filteredSuggestions}
                handleCancle={handleCancle}
            />
        </>
    );
}

export default ContractDomainAutocomplete;
