import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ManagesHandler from './app/manages/common/models/ManagesHandler';
import PrintHandler from './app/manages/common/models/PrintHandler';
import ManagesDashboardPage from './app/manages/common/pages/ManagesDashboardPage';
import ManagesSupportPage from './app/manages/common/pages/ManagesSupportPage';
import ManagesCustomContractPage from './app/manages/contracts/pages/ManagesCustomContractPage';
import ManagesDomainPage from './app/manages/contracts/pages/ManagesDomainPage';
import ManagesHostingPage from './app/manages/contracts/pages/ManagesHostingPage';
import ManagesServerPage from './app/manages/contracts/pages/ManagesServerPage';
import ManagesSoftwarePage from './app/manages/contracts/pages/ManagesSoftwarePage';
import ManagesCartPage from './app/manages/management/pages/ManagesCartPage';
import ManagesCpanelVendorPage from './app/manages/management/pages/ManagesCpanelVendorPage';
import ManagesFinancePage from './app/manages/management/pages/ManagesFinancePage';
import ManagesFinanceTransactionPage from './app/manages/management/pages/ManagesFinanceTransactionPage';
import ManagesInvoicePage from './app/manages/management/pages/ManagesInvoicePage';
import ManagesInvoicePagesPrint from './app/manages/management/pages/ManagesInvoicePagesPrint';
import ManagesLogsPage from './app/manages/management/pages/ManagesLogsPage';
import ManagesMessagePage from './app/manages/management/pages/ManagesMessagePage';
import ManagesReportPage from './app/manages/management/pages/ManagesReportPage';
import ManagesUserPage from './app/manages/management/pages/ManagesUserPage';
import ManagesSettingCurrenciesPage from './app/manages/setting/pages/ManagesSettingCurrenciesPage';
import ManagesSettingDomainPricePage from './app/manages/setting/pages/ManagesSettingDomainPricePage';
import ManagesSettingGatewayPage from './app/manages/setting/pages/ManagesSettingGatewayPage';
import ManagesSettingPackages from './app/manages/setting/pages/ManagesSettingPackages';
import ManagesSettingServices from './app/manages/setting/pages/ManagesSettingServices';
import ManagesWebsiteActiveLog from './app/manages/website/pages/ManagesWebsiteActiveLog';
import ManagesWebsiteClient from './app/manages/website/pages/ManagesWebsiteClient';
import ManagesWebsiteFAQ from './app/manages/website/pages/ManagesWebsiteFAQ';
import ManagesWebsiteNotifyOtpConfig from './app/manages/website/pages/ManagesWebsiteNotifyOtpConfig';
import ManagesUserPropertyPrint from './app/print/ManagesUserPropertyPrint';
import LoginPage from './app/system/pages/LoginPage';
import LogoutPage from './app/system/pages/LogoutPage';
import AppInitDispatch from './dispatches/AppInitDispatch';
import InitInfo from './utils/InitInfo';

export default function RouteHandler() {
    const dispatch = useDispatch();
    const { initData } = InitInfo();
    const [show, setShow] = useState(!!initData?.initId);

    useEffect(() => {
        AppInitDispatch(dispatch)
            .then((initStatus) => {
                setShow(initStatus);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    if (!show) {
        return null;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/logout/" element={<LogoutPage />} />
                <Route path="/print/*" element={<PrintHandler />}>
                    <Route path="user/property" element={<ManagesUserPropertyPrint />} />
                </Route>
                <Route path="/manage/*" element={<ManagesHandler />}>
                    <Route path="dashboard/" element={<ManagesDashboardPage />} />
                    <Route path="support/" element={<ManagesSupportPage />} />
                    <Route path="contract/domain/" element={<ManagesDomainPage />} />
                    <Route path="contract/hosting/" element={<ManagesHostingPage />} />
                    <Route path="contract/server/" element={<ManagesServerPage />} />
                    <Route
                        path="contract/software-subscription/"
                        element={<ManagesSoftwarePage />}
                    />
                    <Route
                        path="contract/custom-contract/"
                        element={<ManagesCustomContractPage />}
                    />
                    <Route path="cart/" element={<ManagesCartPage />} />
                    <Route path="invoice/" element={<ManagesInvoicePage />} />
                    <Route path="finance/" element={<ManagesFinancePage />} />
                    <Route path="finance-currencies/" element={<ManagesSettingCurrenciesPage />} />
                    <Route path="finance-gateway/" element={<ManagesSettingGatewayPage />} />
                    <Route path="user/" element={<ManagesUserPage />} />
                    <Route path="logs/" element={<ManagesLogsPage />} />
                    <Route path="message/" element={<ManagesMessagePage />} />
                    <Route path="Reports/" element={<ManagesReportPage />} />
                    <Route path="cpanel-vendor/" element={<ManagesCpanelVendorPage />} />
                    <Route path="domian-price/" element={<ManagesSettingDomainPricePage />} />
                    <Route path="website/activity-log/" element={<ManagesWebsiteActiveLog />} />
                    <Route path="website/client/" element={<ManagesWebsiteClient />} />
                    <Route path="website/faq/" element={<ManagesWebsiteFAQ />} />

                    <Route
                        path="website/otp-notify-config/"
                        element={<ManagesWebsiteNotifyOtpConfig />}
                    />
                    <Route
                        path="finance/transaction/"
                        element={<ManagesFinanceTransactionPage />}
                    />
                    <Route path="setting/service/" element={<ManagesSettingServices />} />
                    <Route path="setting/package/" element={<ManagesSettingPackages />} />
                </Route>
                <Route path="invoice/:invoiceId/Print/" element={<ManagesInvoicePagesPrint />} />
            </Routes>
        </BrowserRouter>
    );
}
