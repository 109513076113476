import React from 'react';

function DomainOverviewInfo({ domainDetailAr }) {
    return (
        <div className="tab-detail">
            <div className="content-section">
                <div className="content-body-scroll user-profile-overview-area">
                    <div className="product-info mb-6 profile-info-area">
                        <div>
                            <div className="line-align line-no-wrap mb-2">
                                <div className="cell title fw-400">Info & Setting</div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                User Name:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {domainDetailAr?.user_name
                                                    ? domainDetailAr?.user_name
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Primary Domain:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <div className="line-row-between line-no-wrap">
                                                <span className="value common-value fw-400 cell-fill overflow-dotted ">
                                                    {domainDetailAr?.domain_name
                                                        ? domainDetailAr?.domain_name
                                                        : '--'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Vendor:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {domainDetailAr?.vendor
                                                    ? domainDetailAr?.vendor
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Email:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {domainDetailAr?.user_email
                                                    ? domainDetailAr?.user_email
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body-scroll user-profile-overview-area">
                    <div className="product-info mb-6 profile-info-area">
                        <div>
                            <div className="line-align line-no-wrap mb-2">
                                <div className="cell title fw-400">Billing</div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Domain Name:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {domainDetailAr?.domain_name
                                                    ? domainDetailAr?.domain_name
                                                    : '--'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Created Date:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <div className="line-row-between line-no-wrap">
                                                <span className="value common-value fw-400 cell-fill overflow-dotted ">
                                                    {domainDetailAr?.time_issue
                                                        ? domainDetailAr?.time_issue
                                                        : '--'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-2 mb-md-0">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Contract ID:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {' '}
                                                {domainDetailAr?.sl}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Renewal Date:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {' '}
                                                {domainDetailAr?.expireDate}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line mb-4">
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Billing Status:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">---</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell cell-6 cell-md-12 mb-md-2 ">
                                    <div className="line-align">
                                        <div className="cell cell-5">
                                            <span className="title common-value fw-500">
                                                Package Price:
                                            </span>
                                        </div>
                                        <div className="cell-7">
                                            <span className="value common-value fw-400">
                                                {domainDetailAr?.price
                                                    ? domainDetailAr?.price
                                                    : '--'}{' '}
                                                (USD) Per Month
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DomainOverviewInfo;
