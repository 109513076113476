import React from 'react';
import DomainCreatedDate from '../components/DomainCreatedDate';
import DomainExpiredDate from '../components/DomainExpiredDate';
import DomainName from '../components/DomainName';
import DomainPrice from '../components/DomainPrice';
import DomainUser from '../components/DomainUser';
import DomainVendor from '../components/DomainVendor';

function DomainBasicSettingInfo({ selectId, domainDetailAr, setdomainDetailAr }) {
    console.log(domainDetailAr);
    return (
        <div className="tab-detail">
            <div className="content-body-scroll user-profile-area">
                <div className="product-info mb-6 user-profile-info-area">
                    <div>
                        <div className="line mb-5 user-personal-info-area">
                            <div className="cell cell-12 cell-md-12 cell-sm-12">
                                <DomainUser
                                    domainDetailAr={domainDetailAr}
                                    setdomainDetailAr={setdomainDetailAr}
                                    key="user"
                                    selectId={selectId}
                                />
                                <DomainName
                                    domainDetailAr={domainDetailAr}
                                    setdomainDetailAr={setdomainDetailAr}
                                    key="domain"
                                />
                                <DomainVendor
                                    domainDetailAr={domainDetailAr}
                                    setdomainDetailAr={setdomainDetailAr}
                                    key="vendor"
                                />

                                <DomainPrice
                                    domainDetailAr={domainDetailAr}
                                    setdomainDetailAr={setdomainDetailAr}
                                    key="price"
                                />

                                <DomainCreatedDate
                                    domainDetailAr={domainDetailAr}
                                    setdomainDetailAr={setdomainDetailAr}
                                    key="date"
                                />
                                <DomainExpiredDate
                                    domainDetailAr={domainDetailAr}
                                    setdomainDetailAr={setdomainDetailAr}
                                    key="es_date"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DomainBasicSettingInfo;
