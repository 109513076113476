import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import Autocomplete from '../../../../utils/Autocomplete';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ModalDomainAssignUser({ show, handleClose, selectId, setSelectId }) {
    // const [userData, setUserData] = useState();
    const [inputValueSl, setInputValueSl] = useState('');

    const handleSet = () => {
        AxiosAuth.post(ConfigApi.CONTRACT_DOMAIN_USER_SET, {
            userSl: inputValueSl,
            domainSl: selectId,
        })
            .then((response) => {
                console.log(response);
                setSelectId('');
                handleClose();
                setInputValueSl('');
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal size="md" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <h5>Assign a user</h5>
                <Autocomplete setInputValueSl={setInputValueSl} />

                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={handleClose}
                        style={{ color: 'red', fontWeight: 'bold' }}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={handleSet}
                        style={{ color: '#cc00ff', fontWeight: 'bold' }}
                    >
                        Set User
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalDomainAssignUser;
