/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ModalCurrencies({ show, handleClose, selectId, setSelectId, currenciesAr }) {
    const [rate, setRate] = useState(currenciesAr?.rate);
    const [title, setTitle] = useState(currenciesAr?.title);
    const [currency, setCurrency] = useState(currenciesAr?.currency);

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };
    const handleRate = (e) => {
        setRate(e.target.value);
    };
    const handleCurrency = (e) => {
        setCurrency(e.target.value);
    };
    const handleChange = () => {
        AxiosAuth.post(ConfigApi.FINANCE_CURRENCY_UPDATE, {
            currencySl: selectId,
            rate,
            currency,
            title,
        })
            .then((response) => {
                console.log(response);
                setSelectId('');

                handleClose();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal size="md" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="contract-create" id="form" method="post" action="">
                    <div className="contract-create-header">Update Currency</div>
                    <div className="contract-create-input" style={{ width: '100%' }}>
                        <label htmlFor="target_link">Title:</label>
                        <input
                            type="text"
                            placeholder="Type Title"
                            value={title}
                            onChange={handleTitle}
                        />
                    </div>
                    <div className="contract-create-input" style={{ width: '100%' }}>
                        <label htmlFor="target_link">Currency:</label>
                        <input
                            type="text"
                            placeholder="Type Currency"
                            value={currency}
                            onChange={handleCurrency}
                        />
                    </div>
                    <div className="contract-create-input" style={{ width: '100%' }}>
                        <label htmlFor="target_link">Currency Rate:</label>
                        <input
                            type="text"
                            placeholder="Type Rate"
                            value={rate}
                            onChange={handleRate}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={handleClose}
                        style={{ color: 'red', fontWeight: 'bold' }}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={handleChange}
                        style={{ color: '#cc00ff', fontWeight: 'bold' }}
                    >
                        Change
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalCurrencies;
