import React, { useEffect, useState } from 'react';

import IconCorrect from '../../../../../assets/images/IconCorrect.svg';
import IconCross from '../../../../../assets/images/IconCross.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

const EditView = ({ userAr, formData, setFormData, handelExitEdit, handelSubmitForm, mode }) => {
    const handleUpdate = (ev) => {
        setFormData(ev.target.value);
    };

    return (
        <div
            className={`edit-view-area ${mode === userAr?.sl ? 'show' : ''}`}
            style={{ backgroundColor: 'unset' }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <div className="mb-1 mr-2">
                        <select
                            className="form-input w-100 "
                            onChange={handleUpdate}
                            value={formData}
                        >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="suspend">Suspend</option>
                        </select>
                    </div>
                </div>
                <div className="line justify-end ">
                    <button
                        type="button"
                        onClick={handelExitEdit}
                        className="default-none btn-gr btn-gr-web ml-2"
                    >
                        <img src={IconCross} alt="" />
                    </button>
                    <button
                        onClick={handelSubmitForm}
                        type="button"
                        className="default-none btn-gr btn-gr-web ml-2"
                        disabled={userAr?.name === formData.trim()}
                    >
                        <img src={IconCorrect} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
};
function ProfileStatus({ userAr }) {
    const { setMessage } = useTemplate();
    const [formData, setFormData] = useState(userAr?.status || '');
    const [mode, setMode] = useState('');
    const handelClickEdit = (e) => {
        setMode(e);
    };

    const handelExitEdit = () => {
        setMode('');
    };

    const handelSubmitForm = () => {
        setMessage('Updating Gender ...');
        AxiosAuth.post(ConfigApi.USER_BASIC_UPDATE, {
            userSl: userAr?.sl,
            type: 'status',
            value: formData,
        }).then((response) => {
            console.log(response);
            setMessage(response?.data.message);
            setMode('');
        });
    };
    useEffect(() => {
        if (userAr?.status) {
            setFormData(userAr?.status);
        }
    }, [userAr.status]);
    return (
        <>
            <EditView
                userAr={userAr}
                formData={formData}
                setFormData={setFormData}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
            />
            {mode === userAr?.sl ? null : (
                <button
                    type="button"
                    className="user-profile-btn"
                    onClick={() => handelClickEdit(userAr?.sl)}
                    style={{ height: 40, marginLeft: 10 }}
                >
                    Change Status
                </button>
            )}
        </>
    );
}

export default ProfileStatus;
