import React, { useEffect, useState } from 'react';

import IconEdit from '../../../../../assets/icon/user-profile-edit-icon-gray.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

const EditView = ({ detailAr, formData, setFormData, handelExitEdit, handelSubmitForm, mode }) => {
    const handleUpdate = (ev) => {
        setFormData(ev.target.value);
    };

    return (
        <div className={`edit-view-area ${mode === detailAr?.sl ? 'show' : ''}`}>
            <div>
                <div>
                    <div className="mb-2">
                        <span className="profile-subject">Expired Date:</span>
                    </div>
                    <div className="mb-1">
                        <input
                            type="date"
                            className="form-input w-100"
                            onChange={handleUpdate}
                            value={formData}
                        />
                    </div>
                    <div className="line line-sm-no-wrap text-start note-text">
                        <span className="value fw-500">Note:</span>
                        &nbsp;
                        <span className="value fw-400">Change Expired Date</span>
                    </div>
                </div>
                <div className="line">
                    <button className="cncl-btn" type="button" onClick={handelExitEdit}>
                        Cancel
                    </button>
                    <button
                        onClick={handelSubmitForm}
                        type="button"
                        className="default-none btn-gr btn-gr-web ml-2"
                        disabled={detailAr?.name === formData.trim()}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

function CustomExpiredDate({ detailAr, setDetailAr }) {
    const { setMessage } = useTemplate();
    const [formData, setFormData] = useState(detailAr?.time_expire || '');
    const [mode, setMode] = useState('');

    const handelClickEdit = (e) => {
        setMode(e);
    };

    const handelExitEdit = () => {
        setMode('');
    };

    const handelSubmitForm = () => {
        setMessage('Updating Expired Date ...');
        AxiosAuth.post(ConfigApi.CONTRACT_CUSTOM_SETTING_UPDATE, {
            hostingSl: detailAr?.sl,
            type: 'time_expire',
            value: formData,
        }).then((response) => {
            console.log(response);
            setMessage(response?.data.message);
            setMode('');
            setDetailAr(response?.data.dataAr);
        });
    };
    useEffect(() => {
        if (detailAr?.time_expire) {
            setFormData(detailAr?.time_expire);
        }
    }, [detailAr.time_expire]);

    return (
        <>
            <div className="line default-view-area ">
                <div className="cell  cell-3 cell-md-4 od-opacity">
                    <span className="profile-subject">Expired Date:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value">{detailAr?.time_expire || '--'} </span>
                </div>
                <div className="cell  od-opacity">
                    <button
                        type="button"
                        className="btn-profile-edit"
                        onClick={() => handelClickEdit(detailAr?.sl)}
                    >
                        <img className="w-100 " src={IconEdit} alt="Edit Icon" />
                    </button>
                </div>
            </div>
            <EditView
                detailAr={detailAr}
                formData={formData}
                setFormData={setFormData}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
            />
        </>
    );
}

export default CustomExpiredDate;
