import React, { useEffect, useState } from 'react';

import IconReProperty from '../../../../../assets/icon/IconReProperty.svg';
import IconEdit from '../../../../../assets/icon/user-profile-edit-icon-gray.svg';

import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';

const EditView = ({
    hostDetailAr,
    handelExitEdit,
    handelSubmitForm,
    mode,
    handelInput,
    disk,
    diskType,
    bandwidth,
    cpu,
    ram,
    ep,
    io,
}) => (
    <div className={`edit-view-area ${mode === hostDetailAr?.sl ? 'show' : ''}`}>
        <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1%' }}>
                <div style={{ width: '14%' }}>
                    <div className="mb-2">
                        <span className="profile-subject">Disk:</span>
                    </div>
                    <div className="mb-1" style={{ display: 'flex', gap: '2px' }}>
                        <input
                            type="text"
                            className="form-input w-100"
                            onChange={handelInput}
                            value={disk}
                            name="disk"
                        />
                    </div>
                </div>
                <div style={{ width: '13%' }}>
                    <div className="mb-2">
                        <span className="profile-subject">Disk Type:</span>
                    </div>

                    <div className="mb-1">
                        <input
                            type="text"
                            className="form-input w-100"
                            onChange={handelInput}
                            value={diskType}
                            name="diskType"
                        />
                    </div>
                </div>
                <div style={{ width: '13%' }}>
                    <div className="mb-2">
                        <span className="profile-subject">Bandwidth:</span>
                    </div>
                    <div className="mb-1">
                        <input
                            type="text"
                            className="form-input w-100"
                            onChange={handelInput}
                            value={bandwidth}
                            name="bandwidth"
                        />
                    </div>
                </div>
                <div style={{ width: '13%' }}>
                    <div className="mb-2">
                        <span className="profile-subject">CPU:</span>
                    </div>
                    <div className="mb-1" style={{ display: 'flex', gap: '2px' }}>
                        <input
                            type="text"
                            className="form-input w-100"
                            onChange={handelInput}
                            value={cpu}
                            name="cpu"
                        />
                    </div>
                </div>
                <div style={{ width: '13%' }}>
                    <div className="mb-2">
                        <span className="profile-subject">RAM:</span>
                    </div>

                    <div className="mb-1">
                        <input
                            type="text"
                            className="form-input w-100"
                            onChange={handelInput}
                            value={ram}
                            name="ram"
                        />
                    </div>
                </div>
                <div style={{ width: '13%' }}>
                    <div className="mb-2">
                        <span className="profile-subject">EP:</span>
                    </div>
                    <div className="mb-1">
                        <input
                            type="text"
                            className="form-input w-100"
                            onChange={handelInput}
                            value={ep}
                            name="ep"
                        />
                    </div>
                </div>
                <div style={{ width: '13%' }}>
                    <div className="mb-2">
                        <span className="profile-subject">IO:</span>
                    </div>
                    <div className="mb-1">
                        <input
                            type="text"
                            className="form-input w-100"
                            onChange={handelInput}
                            value={io}
                            name="io"
                        />
                    </div>
                </div>

                <div
                    className="line line-sm-no-wrap text-start note-text"
                    style={{ width: '100%' }}
                >
                    <span className="value fw-500">Note:</span>
                    &nbsp;
                    <span className="value fw-400">Change Property</span>
                </div>
            </div>
            <div className="line justify-end ">
                <button className="cncl-btn" type="button" onClick={handelExitEdit}>
                    Cancel
                </button>
                <button
                    onClick={handelSubmitForm}
                    type="button"
                    className="default-none btn-gr btn-gr-web ml-2"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
);

function HostingProperty({ hostDetailAr, setHostDetailAr }) {
    const { setMessage } = useTemplate();
    const [disk, setDisk] = useState();
    const [diskType, setDiskType] = useState();
    const [bandwidth, setBandwidth] = useState();
    const [cpu, setCpu] = useState();
    const [ram, setRam] = useState();
    const [ep, setEp] = useState();
    const [io, setIo] = useState();
    const [mode, setMode] = useState('');

    const handelClickEdit = (e) => {
        setMode(e);
    };

    const handelExitEdit = () => {
        setMode('');
    };
    const handelInput = (e) => {
        if (e.target.name === 'disk') {
            setDisk(e.target.value);
        }

        if (e.target.name === 'diskType') {
            setDiskType(e.target.value);
        }
        if (e.target.name === 'bandwidth') {
            setBandwidth(e.target.value);
        }
        if (e.target.name === 'cpu') {
            setCpu(e.target.value);
        }
        if (e.target.name === 'ram') {
            setRam(e.target.value);
        }
        if (e.target.name === 'ep') {
            setEp(e.target.value);
        }
        if (e.target.name === 'io') {
            setIo(e.target.value);
        }
    };
    const handelSubmitForm = () => {
        setMessage('Updating Property ...');
        AxiosAuth.post(ConfigApi.CONTRACT_HOSTING_SETTING_UPDATE_PROPERTY, {
            hostingSl: hostDetailAr?.sl,
            disk,
            diskType,
            bandwidth,
            cpu,
            ram,
            ep,
            io,
        }).then((response) => {
            setMessage(response?.data.message);
            setMode('');
            setHostDetailAr(response?.data.dataAr);
        });
    };
    const handelClickUpdateFromPackage = () => {
        setMessage('Updating Property ...');
        AxiosAuth.post(ConfigApi.CONTRACT_HOSTING_SETTING_UPDATE_PROPERTY_PACKAGE, {
            hostingSl: hostDetailAr?.sl,
        }).then((response) => {
            setMessage(response?.data.message);
            setMode('');
            setHostDetailAr(response?.data.dataAr);
        });
    };
    useEffect(() => {
        if (hostDetailAr) {
            setDisk(hostDetailAr?.disk);
            setDiskType(hostDetailAr?.disk_type);
            setBandwidth(hostDetailAr?.bandwidth);
            setCpu(hostDetailAr?.cpu);
            setRam(hostDetailAr?.ram);
            setEp(hostDetailAr?.ep);
            setIo(hostDetailAr?.io);
        }
    }, [hostDetailAr]);
    return (
        <>
            <div className="line default-view-area ">
                <div className="cell  cell-1 cell-md-4 od-opacity">
                    <span className="profile-subject">Disk:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value-property">{hostDetailAr?.disk}</span>
                </div>
                <div className="cell  cell-1 cell-md-4 od-opacity">
                    <span className="profile-subject">Disk Type:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value-property">{hostDetailAr?.disk_type}</span>
                </div>
                <div className="cell  cell-1 cell-md-4 od-opacity">
                    <span className="profile-subject">Bandwidth:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value-property">{hostDetailAr?.bandwidth}</span>
                </div>
                <div className="cell  cell-1 cell-md-4 od-opacity">
                    <span className="profile-subject">CPU:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value-property">{hostDetailAr?.cpu}</span>
                </div>
                <div className="cell  cell-1 cell-md-4 od-opacity">
                    <span className="profile-subject">RAM:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value-property">{hostDetailAr?.ram}</span>
                </div>
                <div className="cell  cell-1 cell-md-4 od-opacity">
                    <span className="profile-subject">EP:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value-property">
                        <span className="value-property">{hostDetailAr?.ep}</span>{' '}
                    </span>
                </div>
                <div className="cell  cell-1 cell-md-4 od-opacity">
                    <span className="profile-subject">IO:</span>
                </div>
                <div className="cell-fill  od-opacity">
                    <span className="value-property">
                        <span className="value-property">{hostDetailAr?.io}</span>{' '}
                    </span>
                </div>
                {hostDetailAr?.parent_sl < 1 ? (
                    <div className="cell od-opacity" style={{ marginRight: 10 }}>
                        <button
                            type="button"
                            className="btn-profile-edit"
                            onClick={() => handelClickUpdateFromPackage()}
                        >
                            <img className="w-100 " src={IconReProperty} alt="Edit Icon" />
                        </button>
                    </div>
                ) : null}
                <div className="cell od-opacity">
                    <button
                        type="button"
                        className="btn-profile-edit"
                        onClick={() => handelClickEdit(hostDetailAr?.sl)}
                    >
                        <img className="w-100 " src={IconEdit} alt="Edit Icon" />
                    </button>
                </div>
            </div>
            <EditView
                hostDetailAr={hostDetailAr}
                handelInput={handelInput}
                handelExitEdit={handelExitEdit}
                handelSubmitForm={handelSubmitForm}
                mode={mode}
                disk={disk}
                diskType={diskType}
                bandwidth={bandwidth}
                cpu={cpu}
                ram={ram}
                ep={ep}
                io={io}
            />
        </>
    );
}

export default HostingProperty;
