/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ModalSettingService({ show, handleClose, serviceAr }) {
    const [type, setType] = useState(serviceAr?.type || '');
    const [subType, setSubType] = useState(serviceAr?.sub_type || '');
    const [title, setTitle] = useState(serviceAr?.title || '');
    const [link, setLink] = useState(serviceAr?.link || '');
    const [priceRange, setPriceRange] = useState(serviceAr?.price_range || '');
    const [serviceProperty, setServiceProperty] = useState(serviceAr?.service_property || '');

    const handleInput = (e) => {
        if (e.target.name === 'type') {
            setType(e.target.value);
        }
        if (e.target.name === 'subType') {
            setSubType(e.target.value);
        }
        if (e.target.name === 'title') {
            setTitle(e.target.value);
        }
        if (e.target.name === 'link') {
            setLink(e.target.value);
        }
        if (e.target.name === 'serviceProperty') {
            setServiceProperty(e.target.value);
        }
        if (e.target.name === 'priceRange') {
            setPriceRange(e.target.value);
        }
    };
    const handleCreateService = () => {
        AxiosAuth.post(ConfigApi.SERVICE_LIST_CREATE, {
            type,
            subType,
            title,
            link,
            priceRange,
            serviceProperty,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleupdateService = () => {
        AxiosAuth.post(ConfigApi.SERVICE_LIST_UPDATE, {
            type,
            subType,
            title,
            link,
            priceRange,
            serviceProperty,
            serviceSl: serviceAr?.sl,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">
                    {serviceAr?.sl ? 'Update' : 'Create New'} Services:
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Type:</label>
                    <input
                        type="text"
                        name="type"
                        value={type}
                        placeholder="Enter Type"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Sub Type:</label>

                    <input
                        type="text"
                        name="subType"
                        value={subType}
                        placeholder="Enter Sub Type"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={title}
                        placeholder="Enter Title"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Link:</label>
                    <input
                        type="text"
                        name="link"
                        value={link}
                        placeholder="Enter Link"
                        onChange={handleInput}
                    />
                </div>

                <div className="contract-create-input">
                    <label htmlFor="schedule">Price Range:</label>
                    <input
                        name="priceRange"
                        value={priceRange}
                        type="text"
                        className="form-control"
                        placeholder="Enter Price Range"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Service Property:</label>
                    <input
                        name="serviceProperty"
                        value={serviceProperty}
                        type="text"
                        placeholder="Enter Service Property"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={serviceAr?.sl ? handleupdateService : handleCreateService}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalSettingService;
