import React from 'react';
import ProfileFullName from '../components/ProfileFullName';
import ProfileGender from '../components/ProfileGender';
import ProfileNote from '../components/ProfileNote';
import ProfileOrganisation from '../components/ProfileOrganisation';
import ProfileUserName from '../components/ProfileUserName';
// import UserEditGrayIcon from '../../../../../assets/icon/user-profile-edit-icon-gray.svg';

function UserBasicInfo({ userAr, setUserDetailAr }) {
    return (
        <div className="tab-detail">
            <div className="content-body-scroll user-profile-area">
                <div className="product-info mb-6 user-profile-info-area">
                    <div className="line align-end line-g1 mb-4">
                        <div className="cell">
                            <span className="profile-subject">Personal Information</span>
                        </div>
                        <div className="cell cell-fill">
                            <hr className="hr" />
                        </div>
                    </div>
                    <div>
                        <div className="line mb-5 user-personal-info-area">
                            <div className="cell cell-12 cell-md-12 cell-sm-12">
                                <ProfileFullName
                                    userAr={userAr}
                                    setUserDetailAr={setUserDetailAr}
                                    key="name"
                                />
                                <ProfileUserName
                                    userAr={userAr}
                                    setUserDetailAr={setUserDetailAr}
                                    key="username"
                                />
                                <ProfileGender
                                    userAr={userAr}
                                    setUserDetailAr={setUserDetailAr}
                                    key="gender"
                                />
                                <ProfileOrganisation
                                    userAr={userAr}
                                    setUserDetailAr={setUserDetailAr}
                                    key="organisation"
                                />
                                <ProfileNote
                                    userAr={userAr}
                                    setUserDetailAr={setUserDetailAr}
                                    key="note"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserBasicInfo;
