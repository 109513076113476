import React, { useEffect, useState } from 'react';

import CalendarIcon from '../../../../../assets/images/Calendar2.svg';
import SearchIcon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function CartListAr({ cartSearch }) {
    return (
        <table className="main-panel-body-table mb-3">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Create Date</th>
                    <th>User</th>
                    <th>Title</th>
                    <th>Package</th>
                    <th>Cart Info</th>
                    <th>Duration</th>
                    <th>Price</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {Object.values(cartSearch)?.map((row) => (
                    <tr key={cartSearch?.sl}>
                        <td>{row?.sl}</td>
                        <td>{row?.invoiceCreateDate}</td>
                        <td>{row?.userName}</td>
                        <td>{row?.title}</td>
                        <td>{row?.package_id}</td>
                        <td>{row?.cart_property?.domain}</td>
                        <td>{row?.duration} Months</td>
                        <td>{row?.price}</td>
                        <td>{row?.status}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function ManagesCartPage() {
    const [clientAr, setClientAr] = useState([]);
    const [searchText, setSearchText] = useState('');

    const cartSearch =
        searchText?.length > 0
            ? clientAr?.filter((data) => data?.sl?.includes(searchText))
            : clientAr;

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.CART_LIST}`).then((response) => {
            setClientAr(response.data.dataAr);
        });
    }, []);

    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                onChange={(e) => setSearchText(e.target.value)}
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search By Cart ID"
                                aria-label=""
                                value={searchText}
                            />
                            <img src={SearchIcon} alt="Search" />
                        </div>
                        <div className="sidebar-panel-search-date">
                            <input
                                type="text"
                                className="form-control flatpickr-input"
                                name="date"
                                id="date"
                                aria-label=""
                                autoComplete="off"
                                required=""
                                value="2022-11-16 to 2022-11-26"
                                placeholder="Select Date"
                                readOnly="readonly"
                            />
                            <img src={CalendarIcon} alt="Calendar Icon" />
                        </div>
                        <button type="submit">Search</button>
                    </form>
                </div>
                <div className="main-panel-body">
                    <CartListAr cartSearch={cartSearch} />
                </div>
            </div>
        </>
    );
}

export default ManagesCartPage;
