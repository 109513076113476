/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import countries from '../../../../utils/country.json';

function AddressMoal({ show, handleClose, userSl, setAddressAr, selectAddressAr }) {
    const { setMessage } = useTemplate();

    const [emailAddress, setEmailAddress] = useState(selectAddressAr?.email || '');
    const [phone, setPhone] = useState(selectAddressAr?.mobile || '');
    const [organisation, setOrganisation] = useState(selectAddressAr?.org || '');
    const [fullname, setFullname] = useState(selectAddressAr?.name || '');
    const [address, setAddress] = useState(selectAddressAr?.line1 || '');
    const [country, setCountry] = useState(selectAddressAr?.country || '');
    const [state, setState] = useState(selectAddressAr?.state || '');
    const [city, setCity] = useState(selectAddressAr?.city || '');
    const [zip, setZip] = useState(selectAddressAr?.zip || '');

    const [errorCode, setErrorCode] = useState(0);
    const focusInput = useRef();

    const handleInput = (e) => {
        if (e.target.name === 'emailAddress') {
            setEmailAddress(e.target.value);
        }
        if (e.target.name === 'phone') {
            setPhone(e.target.value);
        }

        if (e.target.name === 'organisation') {
            setOrganisation(e.target.value);
        }

        if (e.target.name === 'fullname') {
            setFullname(e.target.value);
        }
        if (e.target.name === 'address') {
            setAddress(e.target.value);
        }
        if (e.target.name === 'country') {
            setCountry(e.target.value);
        }
        if (e.target.name === 'state') {
            setState(e.target.value);
        }
        if (e.target.name === 'city') {
            setCity(e.target.value);
        }
        if (e.target.name === 'zip') {
            setZip(e.target.value);
        }
    };

    const handleCreateAddress = () => {
        setMessage('New Adress Creating....');
        AxiosAuth.post(ConfigApi.USER_ADDRESS_CREATE, {
            userSl,
            emailAddress,
            phone,
            fullname,
            address,
            organisation,
            country,
            state,
            city,
            zip,
        })
            .then((response) => {
                setErrorCode(response.data.error);

                if (response.data.error === 0) {
                    handleClose();
                    setAddressAr(response.data.dataAr);
                    setMessage(response.data.message);
                } else {
                    focusInput.current.focus();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleUpdateAddress = () => {
        setMessage('Adress Updating....');
        AxiosAuth.post(ConfigApi.USER_ADDRESS_UPDATE, {
            userSl,
            addressSl: selectAddressAr?.sl,
            emailAddress,
            phone,
            fullname,
            address,
            organisation,
            country,
            state,
            city,
            zip,
        })
            .then((response) => {
                setErrorCode(response.data.error);

                if (response.data.error === 0) {
                    handleClose();
                    setAddressAr(response.data.dataAr);
                    setMessage(response.data.message);
                } else {
                    focusInput.current.focus();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal size="md" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">
                    {selectAddressAr?.sl ? 'Update Address ' : 'Create New Address '}:
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Full Name:</label>
                    <input
                        type="text"
                        name="fullname"
                        value={fullname}
                        placeholder="Type Full Name"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Organisation Name:</label>
                    <input
                        type="text"
                        name="organisation"
                        value={organisation}
                        placeholder="Type Organisation Name"
                        onChange={handleInput}
                        ref={errorCode === 13 ? focusInput : null}
                    />
                </div>

                <div className="contract-create-input" style={{ position: 'relative' }}>
                    <label htmlFor="target_link">Email Address:</label>
                    <input
                        type="email"
                        name="emailAddress"
                        value={emailAddress}
                        placeholder="Type Email Address"
                        onChange={handleInput}
                        ref={errorCode === 12 || errorCode === 'emailAddress' ? focusInput : null}
                    />

                    {errorCode === 12 ? <small>Email Already Exists</small> : null}
                    {errorCode === 'emailAddress' ? <small>Email Required</small> : null}
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Phone Number:</label>
                    <input
                        type="text"
                        name="phone"
                        value={phone}
                        placeholder="Type Phone Number"
                        onChange={handleInput}
                        ref={errorCode === 11 ? focusInput : null}
                    />

                    {errorCode === 11 ? <small>Phone Already Exists</small> : null}
                </div>

                <div className="contract-create-input" style={{ width: '100%' }}>
                    <label htmlFor="n_title">Address:</label>
                    <textarea
                        name="address"
                        value={address}
                        onChange={handleInput}
                        placeholder="Type Address"
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Country:</label>
                    <select
                        name="country"
                        className="form-input"
                        onChange={handleInput}
                        value={country}
                    >
                        <option value="">Select</option>
                        {countries.map((item) => (
                            <option key={item?.name} value={item?.name || ''}>
                                {item?.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">State:</label>
                    <input
                        type="text"
                        name="state"
                        value={state}
                        onChange={handleInput}
                        placeholder="Type State"
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">City:</label>
                    <input
                        type="text"
                        name="city"
                        value={city}
                        onChange={handleInput}
                        placeholder="Type City"
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Zip:</label>
                    <input
                        type="text"
                        name="zip"
                        value={zip}
                        onChange={handleInput}
                        placeholder="Type ZIP"
                    />
                </div>
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={selectAddressAr?.sl ? handleUpdateAddress : handleCreateAddress}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default AddressMoal;
