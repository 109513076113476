/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';
import DropdownMenu from '../../../../utils/DropdownMenu';

const typeData = [
    {
        type: 'web_hosting',
        title: 'Web Hosting',
    },
    {
        type: 'app_hosting',
        title: 'App Hosting',
    },
];
const hideData = [
    {
        value: 0,
        title: 'Not Hide',
    },
    {
        value: 1,
        title: 'Hide',
    },
];

function ModalSettingPackage({ show, handleClose, serviceAr, packageAr }) {
    const [service, setService] = useState(packageAr?.service_sl || '');
    const [type, setType] = useState(packageAr?.type || '');
    const [title, setTitle] = useState(packageAr?.title || '');
    const [price, setPrice] = useState(packageAr?.price || '');
    const [isHide, setIsHide] = useState(packageAr?.is_hide || 0);
    const [duration, setDuration] = useState(packageAr?.selected_duration || '');
    const [bandwidth, setBandwidth] = useState(packageAr?.package_property?.bandwidth || '');
    const [disk, setDisk] = useState(packageAr?.package_property?.disk || '');
    const [location, setLocation] = useState(
        packageAr?.package_property?.variation[0]?.location ||
            packageAr?.package_property?.variation?.location ||
            ''
    );
    const [host, setHost] = useState(
        packageAr?.package_property?.variation[0]?.host ||
            packageAr?.package_property?.variation?.host ||
            ''
    );
    const [diskType, setDiskType] = useState(
        packageAr?.package_property?.variation[0]?.diskType ||
            packageAr?.package_property?.variation?.diskType ||
            ''
    );

    const handleInput = (e) => {
        if (e.target.name === 'title') {
            setTitle(e.target.value);
        }

        if (e.target.name === 'duration') {
            setDuration(e.target.value);
        }
        if (e.target.name === 'price') {
            setPrice(e.target.value);
        }

        if (e.target.name === 'bandwidth') {
            setBandwidth(e.target.value);
        }
        if (e.target.name === 'disk') {
            setDisk(e.target.value);
        }
        if (e.target.name === 'location') {
            setLocation(e.target.value);
        }
        if (e.target.name === 'host') {
            setHost(e.target.value);
        }
        if (e.target.name === 'diskType') {
            setDiskType(e.target.value);
        }
    };
    const handleCreateService = () => {
        AxiosAuth.post(ConfigApi.PACKAGE_LIST_CREATE, {
            service,
            type,
            title,
            price,
            duration,
            bandwidth,
            disk,
            location,
            host,
            diskType,
            isHide,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleUpdatService = () => {
        AxiosAuth.post(ConfigApi.PACKAGE_LIST_UPDATE, {
            service,
            type,
            title,
            price,
            duration,
            bandwidth,
            disk,
            location,
            host,
            diskType,
            packageSl: packageAr?.sl,
            isHide,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">
                    {packageAr?.sl ? 'Update' : 'Create New'} Package:
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Select Service:</label>
                    <div
                        className="sidebar-panel-search"
                        style={{ width: '100%', margin: '0', height: ' 45px' }}
                    >
                        <DropdownMenu
                            selectedOption={service}
                            setSelectedOption={setService}
                            options=""
                            optionsM={serviceAr}
                            width="100%"
                            defaultOption="Select Service"
                            valueKey="sl"
                            optionKey="title"
                        />
                    </div>
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Select Type:</label>
                    <div
                        className="sidebar-panel-search"
                        style={{ width: '100%', margin: '0', height: ' 45px' }}
                    >
                        <DropdownMenu
                            selectedOption={type}
                            setSelectedOption={setType}
                            options=""
                            optionsM={typeData}
                            width="100%"
                            defaultOption="Select Type"
                            valueKey="type"
                            optionKey="title"
                        />
                    </div>
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={title}
                        placeholder="Enter Title"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Price (USD):</label>
                    <input
                        name="price"
                        value={price}
                        type="text"
                        className="form-control"
                        placeholder="Enter Price (USD) per Month"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Duration (Months):</label>
                    <input
                        name="duration"
                        value={duration}
                        type="text"
                        placeholder="Enter Duration in Months"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Bandwidth:</label>
                    <input
                        name="bandwidth"
                        value={bandwidth}
                        type="text"
                        placeholder="Enter Bandwidth"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Disk:</label>
                    <input
                        name="disk"
                        value={disk}
                        type="text"
                        placeholder="Enter Disk"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Location:</label>
                    <input
                        name="location"
                        value={location}
                        type="text"
                        placeholder="Enter Location"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Host:</label>
                    <input
                        name="host"
                        value={host}
                        type="text"
                        placeholder="Enter Host"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Disk Type:</label>
                    <input
                        name="diskType"
                        value={diskType}
                        type="text"
                        placeholder="Enter Disk Type"
                        className="form-control"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="schedule">Is Hide:</label>
                    <div
                        className="sidebar-panel-search"
                        style={{ width: '100%', margin: '0', height: ' 45px' }}
                    >
                        <DropdownMenu
                            selectedOption={isHide}
                            setSelectedOption={setIsHide}
                            options=""
                            optionsM={hideData}
                            width="100%"
                            defaultOption="Select Hide"
                            valueKey="value"
                            optionKey="title"
                        />
                    </div>
                </div>
                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={packageAr?.sl ? handleUpdatService : handleCreateService}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalSettingPackage;
