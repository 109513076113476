/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { createRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';

function ModalClient({ show, handleClose, selectClient, setSelectId }) {
    const [title, setTitle] = useState(selectClient?.title || '');
    const [services, setServices] = useState(selectClient?.services || '');
    const [logoUrl, setLogourl] = useState(selectClient?.logo_url || '');
    const uploadRef = createRef();
    const handleInput = (e) => {
        if (e.target.name === 'title') {
            setTitle(e.target.value);
        }
        if (e.target.name === 'services') {
            setServices(e.target.value);
        }
    };
    const handleCreateClient = () => {
        AxiosAuth.post(ConfigApi.CLIENT_LIST_CREATE, {
            logoUrl,
            title,
            services,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                }
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleUpdateClient = () => {
        AxiosAuth.post(ConfigApi.CLIENT_LIST_UPDATE, {
            logoUrl,
            title,
            services,
            clientSl: selectClient?.sl,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handleClose();
                    setSelectId('');
                }
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;

        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('upload_file', files[x]);

            await AxiosAuth.post(ConfigApi.FILE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    console.log(response?.data);
                    if (response?.data?.error === 0) {
                        setLogourl(response?.data?.dataAr);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    return (
        <Modal size="lg" show={show} onHide={handleClose} centered>
            <div className="contract-create" id="form" method="post" action="">
                <div className="contract-create-header">
                    {selectClient?.sl ? 'Update Client:' : 'Create New Client:'}
                </div>

                <div className="contract-create-input">
                    <label htmlFor="n_title">Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={title}
                        placeholder="Type Title"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input">
                    <label htmlFor="n_title">Services:</label>
                    <input
                        type="text"
                        name="services"
                        value={services}
                        placeholder="Type Services"
                        onChange={handleInput}
                    />
                </div>
                <div className="contract-create-input" style={{ position: 'relative' }}>
                    <label htmlFor="n_title">Upload Logo:</label>
                    <input type="text" value={logoUrl} placeholder="Logo Link" />
                    <button
                        className="contract-create-input-up-btn"
                        type="button"
                        onClick={() => {
                            uploadRef.current.click();
                        }}
                    >
                        Upload
                    </button>
                </div>

                <div className="contract-create-btn">
                    <button
                        type="button"
                        className="contract-cancel-btn"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="contract-create-btn-add"
                        onClick={selectClient?.sl ? handleUpdateClient : handleCreateClient}
                    >
                        Submit
                    </button>
                </div>
                <input
                    ref={uploadRef}
                    type="file"
                    name="file"
                    onChange={handleOnFileChange}
                    multiple
                    style={{ display: 'none' }}
                />
            </div>
        </Modal>
    );
}

export default ModalClient;
