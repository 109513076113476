import React, { useEffect, useState } from 'react';
import IconEdit from '../../../../../assets/icon/user-profile-edit-icon-gray.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';
import AddressMoal from '../modals/AddressMoal';

function UserAddressInfo({ userAr }) {
    const [show, setShow] = useState(false);
    const [addressAr, setAddressAr] = useState([]);
    const [selectedSl, setSelectedSl] = useState();

    const handleClose = () => {
        setShow(false);
    };
    const handleUpdateBtm = (e) => {
        setSelectedSl(e);
        setShow(true);
    };
    const handleCreateBtm = () => {
        setSelectedSl('');
        setShow(true);
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.USER_ADDRESS_LIST.replace(':userSl', userAr?.sl)}`).then(
            (response) => {
                setAddressAr(response.data.dataAr);
            }
        );
    }, [userAr.sl]);

    return (
        <>
            <div className="tab-detail">
                <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addressAr?.map((row) => (
                            <tr key={row?.sl}>
                                <td>{row?.name}</td>
                                <td>{row?.line1}</td>
                                <td>{row?.email}</td>
                                <td>{row?.mobile}</td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn-profile-edit"
                                        onClick={() => handleUpdateBtm(row?.sl)}
                                    >
                                        <img className="w-100 " src={IconEdit} alt="Edit Icon" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="line-align justify-end mt-4 mb-6">
                    <div className="cell-sm-fill">
                        <div
                            role="button"
                            tabIndex="-1"
                            className="line-align add-btn-common mb-2 add-address"
                            onClick={handleCreateBtm}
                        >
                            <span className="ml-4">Add Address</span>
                        </div>
                    </div>
                </div>
            </div>
            {show ? (
                <AddressMoal
                    show={show}
                    handleClose={handleClose}
                    userSl={userAr?.sl}
                    setAddressAr={setAddressAr}
                    selectAddressAr={addressAr?.filter((row) => row.sl === selectedSl)[0]}
                />
            ) : null}
        </>
    );
}

export default UserAddressInfo;
