import React, { useEffect, useState } from 'react';
import EditIcon from '../../../../../assets/images/editicon.svg';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ModalSettingPackage from '../modals/ModalSettingPackage';
import ModalSettingService from '../modals/ModalSettingService';

function PlanPackages({ selectId, setSelectId }) {
    const [packageAllAr, setPackageAr] = useState([]);
    const [serviceAr, setServiceAr] = useState([]);
    const [showNew, setShowNew] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectPlanId, setSelectPlanId] = useState('');

    const packageAr = packageAllAr.filter(
        (item) =>
            item.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.type?.toLowerCase().includes(searchText?.toLowerCase())
    );
    const handleClose = () => {
        setShowNew(false);
    };
    const handleUpdate = (e) => {
        setSelectPlanId(e);
        setShowNew(true);
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.PACKAGE_LIST.replace(':serviceSl', selectId)}`).then(
            (response) => {
                setPackageAr(response?.data?.dataAr?.packagesInfo_ar);
                setServiceAr(response?.data?.dataAr?.servicesInfo_ar);
            }
        );
    }, [showNew, selectId]);
    console.log(packageAllAr);
    return (
        <>
            <div className="main-panel" style={{ width: '74%' }}>
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search..."
                                aria-label=""
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>
                    </form>
                    <button type="button" onClick={() => setShowNew(true)}>
                        Add New Package
                    </button>
                    <button
                        type="button"
                        onClick={() => setSelectId('')}
                        style={{ marginLeft: 0, backgroundColor: 'red' }}
                    >
                        Close
                    </button>
                </div>
                <div className="main-panel-body">
                    <div className="scroll-table-container">
                        <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Title</th>
                                    <th>Price (USD)</th>
                                    <th>Type</th>
                                    <th>Duration</th>
                                    <th>Status</th>
                                    <th>Is Hide</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {packageAr?.map((row) => (
                                    <tr key={row?.sl}>
                                        <td>{row?.sl}</td>
                                        <td>{row?.title}</td>
                                        <td>{row?.price}</td>
                                        <td>{row?.type}</td>
                                        <td>{row?.selected_duration} Months</td>
                                        <td>{row?.status}</td>
                                        <td>{row?.is_hide < 1 ? 'Not Hide' : 'Hide'}</td>
                                        <td className="action-area">
                                            <button
                                                type="button"
                                                onClick={() => handleUpdate(row?.sl)}
                                            >
                                                <img src={EditIcon} alt="Edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {showNew ? (
                <ModalSettingPackage
                    show={showNew}
                    handleClose={handleClose}
                    serviceAr={serviceAr}
                    packageAr={packageAllAr?.filter((r) => r.sl === selectPlanId)[0]}
                />
            ) : null}
        </>
    );
}
function ManagesSettingServices() {
    const [serviceAllAr, setServiceAr] = useState([]);
    const [show, setShow] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [selectId, setSelectId] = useState('');
    const [searchText, setSearchText] = useState('');

    const serviceAr = serviceAllAr.filter(
        (item) =>
            item.type?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.sub_type?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.title?.toLowerCase().includes(searchText?.toLowerCase())
    );

    const handleClose = () => {
        setShow(false);
        setShowNew(false);
        setSelectId('');
    };

    const handleUpdate = (e) => {
        setSelectId(e);
        setShowNew(true);
    };

    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.SERVICE_LIST}`).then((response) => {
            setServiceAr(response.data.dataAr);
        });
    }, [show, showNew]);

    return (
        <>
            <div className="main-panel" style={{ width: selectId && !showNew ? '24%' : '' }}>
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div
                            className="sidebar-panel-search"
                            style={{ width: selectId ? '68%' : '' }}
                        >
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search..."
                                aria-label=""
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText}
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>
                    </form>
                    {!selectId ? (
                        <button type="button" onClick={() => setShowNew(true)}>
                            Add Service
                        </button>
                    ) : null}
                </div>
                <div className="main-panel-body">
                    <div className="scroll-table-container">
                        <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    {selectId && !showNew ? null : <th>Type</th>}

                                    <th>Sub-type</th>
                                    <th>Title</th>
                                    {selectId && !showNew ? null : (
                                        <>
                                            <th>Link</th>
                                            <th>Property</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {serviceAr?.map((row) => (
                                    <tr
                                        key={row?.sl}
                                        onClick={() => setSelectId(row?.sl)}
                                        className={selectId === row?.sl ? 'active-tr' : null}
                                    >
                                        <td>{row?.sl}</td>
                                        {selectId && !showNew ? null : <td>{row?.type}</td>}

                                        <td>{row?.sub_type}</td>
                                        <td>{row?.title}</td>
                                        {selectId && !showNew ? null : (
                                            <>
                                                <td>{row?.link}</td>
                                                <td>{row?.service_property}</td>
                                                <td>{row?.status}</td>
                                                <td className="action-area">
                                                    <button
                                                        type="button"
                                                        onClick={() => handleUpdate(row?.sl)}
                                                    >
                                                        <img src={EditIcon} alt="Edit" />
                                                    </button>
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {showNew ? (
                    <ModalSettingService
                        show={showNew}
                        handleClose={handleClose}
                        serviceAr={serviceAr?.filter((row) => row.sl === selectId)[0]}
                        selectId={selectId}
                    />
                ) : null}
            </div>
            {selectId && !showNew ? (
                <PlanPackages selectId={selectId} setSelectId={setSelectId} />
            ) : null}
        </>
    );
}

export default ManagesSettingServices;
