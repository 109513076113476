const StatusData = [
    {
        value: 'active',
        title: 'Active',
    },
    {
        value: 'inactive',
        title: 'Inactive',
    },
];
export default StatusData;
