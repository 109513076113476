import React, { useEffect, useRef, useState } from 'react';

function SelectedOption({ toggling, isOpen, selectedOption, defaultOption }) {
    return (
        <div
            role="button"
            tabIndex={-1}
            style={{ cursor: 'default' }}
            onClick={toggling}
            className={`dropdown-btn ${isOpen ? 'active' : 'inactive'}`}
        >
            {selectedOption || defaultOption}
        </div>
    );
}

function Options({
    handleSelectedOption,
    isOpen,
    options,
    variant,
    isInput,
    valueKey = '',
    optionKey = '',
}) {
    const [searchText, setSearchText] = useState('');
    let arr = options;
    if (valueKey !== '' && optionKey !== '') {
        arr =
            searchText.length > 0
                ? options?.filter((data) =>
                      data?.optionKey?.toLowerCase().includes(searchText.toLowerCase())
                  )
                : options;
    } else {
        arr =
            searchText.length > 0
                ? options?.filter((data) => data?.toLowerCase().includes(searchText.toLowerCase()))
                : options;
    }

    const focusInput = useRef();

    useEffect(() => {
        if (isInput) {
            focusInput.current.focus();
        }
    }, [isInput]);

    return (
        <div
            className={`dropdown-options w-100 ${isOpen ? 'active' : ''}`}
            style={{ top: variant === 'border' ? '50px' : `${isInput ? '0' : '40px'}` }}
        >
            {isInput ? (
                <input
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    className="form-input"
                    placeholder="Search...."
                    ref={focusInput}
                />
            ) : null}
            {arr.map((item) => (
                <div
                    key={Math.random()}
                    role="button"
                    tabIndex={-1}
                    onClick={() =>
                        handleSelectedOption(
                            valueKey !== '' && optionKey !== '' ? item[valueKey] : item
                        )
                    }
                    className="option-item"
                >
                    <span className="no-wrap">
                        {valueKey !== '' && optionKey !== '' ? item[optionKey] : item}
                    </span>{' '}
                </div>
            ))}
        </div>
    );
}

function DropdownMenu({
    defaultOption = 'Select',
    selectedOption,
    setSelectedOption,
    options,
    optionsM = [],
    variant = '',
    width,
    height,
    isInput = true,
    valueKey = '',
    optionKey = '',
}) {
    const [isOpen, setIsOpen] = useState(false);

    const myRef = useRef();

    // option open/close
    const toggling = () => setIsOpen(!isOpen);

    // get value and close options
    const handleSelectedOption = (value) => {
        setSelectedOption(value);
        setIsOpen(false);
    };
    const selectedOption2 = optionsM?.filter((data) => data[valueKey] === selectedOption)[0];

    // close options when click outside

    useEffect(() => {
        function handelClickOutside(event) {
            if (myRef?.current && !myRef?.current.contains(event?.target)) {
                // alert("You clicked outside of me!");
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handelClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handelClickOutside);
        };
    }, []);

    return (
        <div
            className="dropdown-area"
            ref={myRef}
            style={
                variant === 'border'
                    ? {
                          border: '1px solid #130f4033',
                          borderRadius: '8px',
                          width: width || '150px',
                          height: height || '40px',
                      }
                    : { width: width || '150px' }
            }
        >
            <SelectedOption
                isOpen={isOpen}
                toggling={toggling}
                selectedOption={
                    optionsM?.length > 0 ? selectedOption2?.[optionKey] : selectedOption
                }
                defaultOption={defaultOption}
            />
            {isOpen ? (
                <Options
                    handleSelectedOption={handleSelectedOption}
                    isOpen={isOpen}
                    options={optionsM?.length > 0 ? optionsM : options}
                    variant={variant}
                    selectedOption={selectedOption}
                    isInput={isInput}
                    valueKey={valueKey}
                    optionKey={optionKey}
                />
            ) : null}
        </div>
    );
}

export default DropdownMenu;
