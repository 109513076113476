/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import Searchicon from '../../../../../assets/images/IconCross.svg';
import EditIcon from '../../../../../assets/images/IconEmailPass.svg';
import PassIconOk from '../../../../../assets/images/IconPassOk.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import { useTemplate } from '../../../../contexts/TemplateProvider';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ModalUser from '../modals/ModalUser';
import UserAddressInfo from '../section/UserAddressInfo';
import UserBasicInfo from '../section/UserBasicInfo';
import UserContactInfo from '../section/UserContactInfo';
import UserContractInfo from '../section/UserContractInfo';
import UserDetailTabs from '../section/UserDetailTabs';
import UserOverviewInfo from '../section/UserOverviewInfo';
import UserPropertyInfo from '../section/UserPropertyInfo';

function UserDetail({ detailId, setDetailId }) {
    const [providerAr, setProviderAr] = useState([]);
    const [userDetailAr, setUserDetailAr] = useState([]);

    const [activeTab, setActiveTab] = useState(1);
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.USER_SINGLE_INFO.replace(':userSl', detailId)}`).then(
            (response) => {
                setProviderAr(response.data.dataAr?.userProvider);
                setUserDetailAr(response.data.dataAr?.userInfo);
            }
        );
    }, [detailId]);

    return (
        <>
            <div className="main-panel" style={{ width: '68%' }}>
                <div className="main-panel-header">
                    <div className="main-panel-title" style={{ width: '100%' }}>
                        <span style={{ flex: '1 1 0%' }}>User Details</span>
                    </div>
                    <button
                        type="button"
                        onClick={() => window.open(`/print/user/property/?userSl=${detailId}`)}
                        style={{ color: 'white', backgroundColor: '#AE00B9', marginRight: '-10px' }}
                    >
                        Print
                    </button>
                    <button
                        type="button"
                        onClick={() => setDetailId('')}
                        style={{ color: 'white', backgroundColor: 'red' }}
                    >
                        Close
                    </button>
                </div>
                <div className="main-panel-body">
                    <UserDetailTabs activeTab={activeTab} setActiveTab={setActiveTab} />
                    {activeTab === 1 ? (
                        <UserOverviewInfo userAr={userDetailAr} setUserDetailAr={setUserDetailAr} />
                    ) : null}
                    {activeTab === 2 ? (
                        <UserBasicInfo userAr={userDetailAr} setUserDetailAr={setUserDetailAr} />
                    ) : null}
                    {activeTab === 3 ? (
                        <UserContactInfo
                            userAr={userDetailAr}
                            providerAr={providerAr}
                            setProviderAr={setProviderAr}
                        />
                    ) : null}
                    {activeTab === 4 ? <UserAddressInfo userAr={userDetailAr} /> : null}
                    {activeTab === 5 ? <UserContractInfo userAr={userDetailAr} /> : null}
                    {activeTab === 6 ? <UserPropertyInfo userAr={userDetailAr} /> : null}
                </div>
            </div>
        </>
    );
}
function ManagesUserPage() {
    const { message, setMessage } = useTemplate();

    const [userAr, setUserAr] = useState([]);
    const [showNew, setShowNew] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [isStar, setIsStar] = useState('');
    const [detailId, setDetailId] = useState('');

    const userSearch2 = userAr.filter(
        (item) =>
            item.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.username?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.organisation?.toLowerCase().includes(searchText?.toLowerCase()) ||
            item.phoneProvider?.toLowerCase().includes(searchText?.toLowerCase())
    );
    const userSearch = userSearch2.filter((item) =>
        item.is_starred?.toLowerCase().includes(isStar?.toLowerCase())
    );

    const handleClose = () => {
        setShowNew(false);
    };
    const handleSendEmail = (e) => {
        AxiosAuth.post(ConfigApi.USER_PASS_SET_EMAIL, {
            userSl: e,
        })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleStarred = (e) => {
        AxiosAuth.post(`${ConfigApi.CONTRACT_USER_STARRED.replace(':userSl', e)}`).then(
            (response) => {
                setMessage(response.data.message);
            }
        );
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.USER_LIST}`).then((response) => {
            setUserAr(response.data.dataAr);
            setSearchText('');
        });
    }, [message, showNew]);

    return (
        <>
            <div className="main-panel" style={{ width: detailId ? '29%' : '' }}>
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div
                            className="sidebar-panel-search"
                            style={{ width: detailId ? '47%' : '' }}
                        >
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search User"
                                aria-label=""
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText}
                            />
                            {searchText !== '' ? (
                                <img
                                    onClick={() => setSearchText('')}
                                    src={Searchicon}
                                    alt="Search"
                                    style={{ cursor: 'pointer' }}
                                />
                            ) : null}
                        </div>

                        <select
                            className="sidebar-panel-search-select"
                            style={{ width: detailId ? '40%' : '' }}
                            onChange={(e) => setIsStar(e.target.value)}
                            value={isStar}
                        >
                            <option value="">All</option>
                            <option value="1">Star</option>
                            <option value="0">Not Star</option>
                        </select>
                    </form>
                    {!detailId ? (
                        <button type="button" onClick={() => setShowNew(true)}>
                            <span className="add-btn">Add New User</span>
                            <span className="plus-btn">+</span>
                        </button>
                    ) : null}
                </div>
                <div className="main-panel-body">
                    <div className="scroll-table-container">
                        <table className="scroll-table main-panel-body-table main-panel-body-table mb-3">
                            <thead>
                                <tr>
                                    <th />
                                    <th width="5%">SL</th>
                                    <th width="15%">Name</th>
                                    <th width="15%">Username</th>

                                    {!detailId ? (
                                        <>
                                            <th width="10%">Org</th>
                                            <th width="20%">Email</th>
                                            <th width="15%">Mobile</th>
                                            <th width="10%" style={{ whiteSpace: 'nowrap' }}>
                                                Last Transaction
                                            </th>
                                            <th width="5%">Status</th>
                                            <th width="5%">Action</th>
                                        </>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {userSearch?.map((row) => (
                                    <tr
                                        key={row?.sl}
                                        className={detailId === row?.sl ? 'active-tr' : null}
                                    >
                                        <td>
                                            <button
                                                type="button"
                                                className={`item-starred ${
                                                    row?.is_starred === '1'
                                                        ? 'item-starred-active'
                                                        : null
                                                }`}
                                                onClick={() => handleStarred(row?.sl)}
                                            />
                                        </td>
                                        <td onClick={() => setDetailId(row?.sl)}>{row?.sl}</td>
                                        <td
                                            onClick={() => setDetailId(row?.sl)}
                                            style={{ maxWidth: 150 }}
                                        >
                                            <p>{row?.name}</p>
                                        </td>
                                        <td onClick={() => setDetailId(row?.sl)}>
                                            {row?.username}
                                        </td>
                                        {!detailId ? (
                                            <>
                                                <td onClick={() => setDetailId(row?.sl)}>
                                                    {row?.organisation}
                                                </td>
                                                <td onClick={() => setDetailId(row?.sl)}>
                                                    {row?.emailProvider}
                                                </td>
                                                <td onClick={() => setDetailId(row?.sl)}>
                                                    {row?.phoneProvider}
                                                </td>
                                                <td
                                                    onClick={() => setDetailId(row?.sl)}
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    --
                                                </td>
                                                <td onClick={() => setDetailId(row?.sl)}>
                                                    {row?.status}
                                                </td>
                                                <td className="action-area">
                                                    {row?.isPassword === 1 ? (
                                                        <button type="button">
                                                            <img
                                                                src={PassIconOk}
                                                                style={{ width: 15 }}
                                                                alt="Ok Pass"
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            onClick={() => handleSendEmail(row?.sl)}
                                                        >
                                                            <img
                                                                src={EditIcon}
                                                                style={{ width: 15 }}
                                                                alt="Send Email"
                                                            />
                                                        </button>
                                                    )}
                                                </td>
                                            </>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {detailId ? <UserDetail detailId={detailId} setDetailId={setDetailId} /> : null}

            {showNew ? <ModalUser show={showNew} handleClose={handleClose} /> : null}
        </>
    );
}

export default ManagesUserPage;
