import React, { useEffect, useState } from 'react';
import EditIcon from '../../../../../assets/images/editicon.svg';
import Searchicon from '../../../../../assets/images/input-search-icon.svg';
import ConfigApi from '../../../../../configs/ConfigApi';
import AxiosAuth from '../../../../utils/AxiosAuth';
import ModalCurrencies from '../modals/ModalCurrencies';

function ManagesSettingCurrenciesPage() {
    const [currenciesAr, setCurrenciesAr] = useState([]);
    const [selectId, setSelectId] = useState();
    const [showNew, setShowNew] = useState(false);

    const handleUpdate = (e) => {
        setShowNew(true);
        setSelectId(e);
    };
    const handleClose = () => {
        setShowNew(false);
    };
    useEffect(() => {
        AxiosAuth.get(`${ConfigApi.FINANCE_CURRENCIES_LIST}`).then((response) => {
            setCurrenciesAr(response.data.dataAr);
        });
    }, [showNew]);

    return (
        <>
            <div className="main-panel">
                <div className="main-panel-header">
                    <form method="get" action="" className="main-panel-header-search">
                        <div className="sidebar-panel-search">
                            <input
                                type="text"
                                name="content"
                                id="content"
                                placeholder="Search Currency"
                                aria-label=""
                            />
                            <img src={Searchicon} alt="Search" />
                        </div>

                        <button type="submit">Search</button>
                    </form>
                    <button type="button">Fix Service Account</button>
                </div>
                <div className="main-panel-body">
                    <table className="main-panel-body-table mb-3">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>title</th>
                                <th>Currency</th>
                                <th>rate</th>
                                <th>Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currenciesAr?.map((row) => (
                                <tr key={row?.sl}>
                                    <td>{row?.sl}</td>
                                    <td>{row?.title}</td>
                                    <td>{row?.currency}</td>
                                    <td>{row?.rate}</td>
                                    <td>{row?.status}</td>
                                    <td className="action-area">
                                        <button type="button" onClick={() => handleUpdate(row?.sl)}>
                                            <img src={EditIcon} alt="Edit" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {selectId ? (
                    <ModalCurrencies
                        show={showNew}
                        selectId={selectId}
                        setSelectId={setSelectId}
                        currenciesAr={currenciesAr?.filter((r) => r.sl === selectId)[0]}
                        handleClose={handleClose}
                    />
                ) : null}
            </div>
        </>
    );
}

export default ManagesSettingCurrenciesPage;
